import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { omit } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import ChangeLog from '../../components/Review/ChangeLog';
import ProjectMiniContainer from '../../components/Project/ProjectMiniContainer';
// import ProjectGantt from '../../components/Project/Gantt';

import { getId } from '../../../../utils/url';

function clean_previous_process(process = {}) {
    const obj = {
        ...omit(process, 'version'),
        assets: [],
        summary: { 
            content: '<p>Enter you change log here.</p>'
        },
        team: process.team.map(t => omit(t, 'approval')),
        tasks: process.tasks.map(t => { 
            return {
                task: t.task,
                user_reference: null,
                date: null,
            }
        }),
    };
    return obj;
}

class Project extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_create_open: false,
        }
        this.createVersion = this.createVersion.bind(this);
    }

    async UNSAFE_componentWillMount() {
        const id = getId(this.props.history);
        await this.props.swallow_projects_get({
            id,
        });
    }

    async createVersion() {
        const { selected:project = {} } = this.props.swallow_projects;

        // New Version
        await this.props.swallow_versions_post({
            data: {
                project_reference: project.project_reference,
            }
        });
        const { selected:version = {} } = this.props.swallow_versions;

        // New Process
        await this.props.swallow_process_get({
            id: project.version_reference,
        });
        const { selected:process = {} } = this.props.swallow_process; 
        const clean_process = clean_previous_process(process);
        
        await this.props.swallow_process_post_some({
            id: version.version_reference,
            data: clean_process,
        });

        // New Tests
        await this.props.swallow_tests_get({
            id: project.version_reference,
        });
        const { selected:tests = {} } = this.props.swallow_tests; 
        await this.props.swallow_tests_post_some({
            id: version.version_reference,
            data: {
                config: tests.config,
                tests: [],
                saved: []
            },
        });

        this.props.history.push(`/version/review/${version.version_reference}`);
    }

    render() {
        const { selected:project = {}, isFetching } = this.props.swallow_projects;
        const { versions = [] } = project;

        return (
            <div className="swallow_app slide-left">
                <TopNav history={this.props.history} project={project} auth={this.props.auth} />

                {!isFetching &&
                    <section className="main-content">
                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <Link className="button grey big" to={'/home'}>&lt; Back to Projects</Link>
                            </div>
                            <div className="main-content-nav-right">
                                <button onClick={this.createVersion} className="button big"><i className="fa fa-passport"></i>Create New Version</button>
                            </div>
                        </div>

                        <ProjectMiniContainer
                            project={project}
                            is_versions={false}
                        />

                        {/*
                        <div>
                            <div className="project mini">
                                <div className="project-box">
                                    <ProjectGantt versions={versions} />
                                </div>
                            </div>
                        </div>
                        */}

                        <div className="main-content-nav">
                            <div className="main-content-nav-left"></div>
                            <div className="main-content-nav-right">
                                <button className="button"><i className="fa fa-download"></i> Download Change Logs</button>
                            </div>
                        </div>

                        {versions.map(v => {
                            return (<ChangeLog version={v} />);
                        })}

                    </section>
                }

                <Footer history={this.props.history} />
            </div>
        );
    }
}

export default Project;
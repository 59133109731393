import React, { Component } from 'react';
import CalculationTextarea, { evaluateCalculations } from './CalculationTextarea';
import CalculationTest from './CalculationTest';

import {
    stepCalculationSchema,
    evaluateSchema,
} from '@swa_llow/pricing_engine';

import {
    error_response
} from '../../../../../utils/errors';

import { isUndefined } from 'lodash';

class CalculationEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.validate = this.validate.bind(this);
        this.updateCalculation = this.updateCalculation.bind(this);
        this.updateDefaultFromCalculation = this.updateDefaultFromCalculation.bind(this);
    }

    updateCalculation (data) {
        const { step } = this.props;
        const new_data = {
            ...step,
            ...data,
        }
        this.props.updateStep(new_data);
    }

    updateDefaultFromCalculation(result) {
        let { step } = this.props;
        const { def } = step;

        if (isUndefined(def) || def === '') {
            this.props.updateStep({
                ...step,
                def: result,
            });
        }
    }

    validate(data) {
        const { inputs, step } = this.props;

        const { selected } = this.state;
        const { formula } = selected;

        this.props.set_error(null);

        const result = evaluateSchema({ 
            project: {
                ...step,
                ...data,
        }, schema: stepCalculationSchema  });

        if (!result.valid) {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Calculation',
            }));
            this.props.set_error(errors[0]);
            return result.valid;
        }

        let calc_result = evaluateCalculations({
            inputs,
            formula,
        });

        if (calc_result.invalid) {
            this.props.set_error({
                message : `Calculation: ${calc_result.message}`
            });
            return false;
        }

        return true;
    }

    render() {
        const { 
            inputs,
            step,
        } = this.props;

        let {
            def = '',
            formula = '',
        } = step;

        return (
            <div key={`${step.id}-edit`}>
                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={'spacing'}>
                                <label>Formula</label>
                                <CalculationTextarea 
                                    inputs={inputs} 
                                    value={formula}
                                    updateCalculation={(value) => {
                                        this.updateCalculation({
                                            formula: value,
                                        });
                                    }}
                                />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Default Value</label>
                                <input type="number" onChange={(e) => {
                                    this.updateCalculation({
                                        def: e.target.value === '' ? '' : Number(e.target.value),
                                    })
                                }} value={def} />
                            </fieldset>
                        </form>
                    </div>
                </div>
                {formula !== '' &&
                    <CalculationTest 
                        inputs={inputs}
                        formula={formula}
                        updateDefaultFromCalculation={this.updateDefaultFromCalculation}
                    />
                }
            </div> 
        );
    }
}

export default CalculationEdit;
import React, { Component } from 'react';

import VersionsTable from './VersionsTable';
import moment from 'moment';
import ProjectChart from './MiniChart';

import { Link } from 'react-router-dom';

class ProjectMiniContainer extends Component {

    renderLatestStatus ({ status = '', version_reference}) {
        const cn = status === 'draft' ? 'secondary' : 'tertiary';
        return (<Link to={`/process/${version_reference}`} className={`button no-hover ${cn} small`}>{status.toUpperCase()}</Link>);
    }

    render () {
        const { project, is_versions = true } = this.props;
        const { status, versions = [] } = project;
        return (
            <div key={project.project_reference} className="project mini">
                <div className="project-top">
                    <div className="project-top-left">
                        <div className="project-box meta">
                            <div className="project-box-detail">
                                <h2><Link to={`/project/${project.project_reference}`}>{ project.name }</Link></h2>
                                <p>{ project.description }</p>
                                <p className="project-date">
                                    <Link className="button small" to={`/project/${project.project_reference}`}>View Project</Link> | Created on: {moment(project.created_at).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div className="project-box-latest-version">
                                <ul>
                                    <li>
                                        {this.renderLatestStatus({ status, version_reference: project.version_reference })}
                                    </li>
                                </ul>
                                {/*status === 'draft' && 
                                    <div>
                                        <p>
                                            <b>Summary:</b> pharetra. Morbi non hendrerit leo. Suspendisse suscipit consequat.
                                        </p>
                                        <ul>
                                            <li>
                                                <Link to={`/process/review/${project.version_reference}`} className="button small secondary">Version #{project.version}</Link>
                                            </li>
                                            <li>
                                                <Link to={`/process/review/${project.version_reference}`} className="button small secondary">16 Comments</Link>
                                            </li>
                                            <li>
                                                <Link to={`/process/review/${project.version_reference}`} className="button small secondary">3 of 4 Tasks</Link>
                                            </li>
                                        </ul>
                                    </div>
        */}
                            </div>
                            <ProjectChart quotes={[]} dummy={true} />
                            {/*<div className="project-box-controls">
                                <div className="download-box">
                                    {status !== 'draft' &&
                                        <div className="download-item" onClick={() => this.props.createVersion({ project_reference }) }>
                                            <i className="fa fa-code-fork"></i>
                                            <div className="download-name"><b>Create</b> Draft Version</div>
                                        </div>
                                    }
                                    <div className="download-item">
                                        <i className="fa fa-bars-progress"></i>
                                        <Link to={`${process.env.PUBLIC_URL}/assets/swallow/assets/demoProject.xlsx`} className="download-name" target="_blank" download><b>View</b> Project</Link>
                                    </div>

                                    <div className="download-item">
                                        <i className="fa fa-download"></i>
                                        <Link to={`${process.env.PUBLIC_URL}/assets/swallow/assets/demoProject.xlsx`} className="download-name" target="_blank" download><b>Download</b> Change Log</Link>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
                {is_versions &&
                    <div className="project-bottom">
                        <div className="project-bottom-left">
                            <div className="project-box">
                                <VersionsTable items={versions} history={this.props.history} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ProjectMiniContainer;
import React from 'react';
import Table from '../../../../Layout/Table';

class ItemsTable extends Table {
    constructor(props) {
        super(props);
        this.deleteItems = this.deleteItems.bind(this);
    }

    deleteItems (ids = []) {
        let { updateComponent, componentData, items = componentData.items  } = this.props;
        const new_items = items.filter(t => !ids.includes(t.id));
        updateComponent({
            items: new_items,
        });
    }

    renderRow(data) {
        const {
            result,
            name,
            id,
            disabled,
            value,
            key,
        } = data;

        const { def, is_approved } = this.props;

        // Array Items where the default can change on selection
        // Excess, Endorsements, Label
        if (value && def) {
            const is_def = (def.includes(value));
            return (
                <tr key={id} className={`${disabled ? 'disabled' : ''} ${result ? 'highlight' : ''}`}>
                    <td className="table-result">{result && <i className="fa fa-circle-chevron-right"></i>}</td>
                    <td className="table-name">{ name }</td>
                    <td>{ value }</td>
                    <td className="table-default">{ is_def && <i className="fa fa-circle-check"></i> }</td>
                    <td style={{textAlign: 'right'}}>
                        {!is_approved &&
                            <button className="button small grey icon" onClick={() => {
                                this.deleteItems([ id ]);
                            }}>
                                <span className="cross"></span> Delete
                            </button>
                        }

                        <button className="button small secondary" onClick={() => {
                            this.setState({
                                highlightRow: id,
                            })
                            this.props.selectItem(data);
                        }}>
                            {is_approved ? 'View' : 'Edit' }
                        </button>
                    </td>
                </tr>
            );
        }

        // Refer, Exclusions
        return (
            <tr key={id} className={disabled ? 'disabled' : ''}>
                <td className="table-result">{result && <i className="fa fa-circle-chevron-right"></i>}</td>
                <td className="table-name">{ name }<br/><span>{key}</span></td>
                <td style={{textAlign: 'right'}}>
                    {!is_approved &&
                        <button className="button small grey icon" onClick={() => {
                            this.deleteItems([ id ]);
                        }}>
                            <span className="cross"></span> Delete
                        </button>
                    }

                    <button className="button small secondary" onClick={() => {
                        this.setState({
                            highlightRow: id,
                        })
                        this.props.selectItem(data);
                    }}>
                        {is_approved ? 'View' : 'Edit' }
                    </button>
                </td>
            </tr>
        )
    }
}

export default ItemsTable;
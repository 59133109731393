import { keys, values, each, isEmpty } from 'lodash';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';

import { unique_id } from './unique_id';
import slugify from './slugify';
import { dataTypeConvertor } from './data_type_selector';

export function upload_csv({
    json,
    map = {},
    needle = 'expected::result',
}) {
    let obj = {};
    if (keys(json).includes(needle)) {
        each(json, (v, k) => {
            obj[k] = dataTypeConvertor(v);
        });
    } else if (values(map).includes(needle)) {
        each(json, (v, k) => {
            obj[map[k]] = dataTypeConvertor(v);
        });
    } else if (values(json).includes(needle)) {
        each(json, (v, k) => {
            map[k] = v;
        });
        return null;
    } else {}
    return obj;
}

/**
 * We use the :: to delimit the property names and information of the tests 
 */

export function process_tests({
    results = [],
}) {
    let mapped_results = [];
    results.forEach(r => {
        // This is what we store in the model as test schema
        let obj = {
            output: {},
            input: {},
            mocks: {},
            tags: [],
            id: unique_id(),
        };
        
        each(r, (v, k) => {
            // The expected results from the test runner. This has to contain result and valid.
            if (k.includes('expected::')) {
                obj.output[k.split('::')[1]] = v;
            // Name of test
            } else if (k === 'label') {
                obj.name = v;
                obj.key = slugify(v);
            } else if (k === 'tags') {
                obj.tags = v.split(',');
            // mock is for mocking the API results
            } else if (k.includes('mock::')) {

                /*
                * Adds to to mocks property
                * !!! No necessary to make work just looks tidy
                */
                obj.mocks[k.split('::')[1]] = v;

                // This is all we need to run the mock test overwrites
                // Adds mock:: to input property
                obj.input[k] = v;

            // This is the result from test which can be downloaded but we don't want uploaded
            } else if (k.includes('result::')) {
            // This is an optional prefix to inputs
            } else if (k.includes('input::')) {
                obj.input[k.split('::')[1]] = v;
            } else {
                obj.input[k] = v;
            }
        });
        if (isEmpty(obj.output)) return;

        if (!keys(obj.output).includes('result')) throw new Error(`You must include expected::result as a CSV column`);
        if (!keys(obj.output).includes('valid')) throw new Error(`You must include expected::valid as a CSV column`);
        if (!obj.name) throw new Error('You must include label as a CSV column');

        // TODO check defined inputs match test inputs
        // TODO check schema is correct
        // TODO return a report 

        mapped_results.push(obj);
    });

    return mapped_results;
}

export function download_csv({
    rows = [],
    name = '',
}) {
    const mapped_columns = Object.keys(rows[0]);

    const parser = new Parser({ 
        fields: mapped_columns
    });

    const mapped_csv = parser.parse(rows);
    
    const content = new Blob([mapped_csv], {
        type: 'text/csv',
        name,
    });

    saveAs(content, name);
}

export function download_tests({
    rows = [],
    name = ''
}) {
    const mapped_rows = rows.map(test => {
        let obj = {
            label: test.name,
        };

        Object.keys(test.output || {}).forEach(t => {
            const key = 'expected::' + t;
            obj[key] = test.output[t];
        });

        Object.keys(test.result || {}).forEach(t => {
            const key = 'result::' + t;
            obj[key] = test.result[t];
        });

        obj = {
            ...obj,
            ...test.input,
        }

        return obj;
    });

    download_csv({
        rows: mapped_rows,
        name,
    })
}


import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';
import Calculation from '../Shared/Calculation/Calculation';
import BooleanSelect from '../Shared/Forms/BooleanSelect';
import ExpressionTest from '../Shared/Expression/ExpressionTest';
import ExpressionTextarea from '../Shared/Expression/ExpressionTextarea';

import {
    outputSchema,
} from '@swa_llow/pricing_engine';

class OutputEdit extends Component {
    constructor(props) {
        super(props);
        this.updateStep = this.updateStep.bind(this);
    }

    updateStep(data) {
        const { step } = this.props;
        this.props.updateStep({
            ...step,
            ...data,
        });
    }

    render() {
        const { 
            step,
            inputs,
            is_approved,
        } = this.props;

        const { result, valid } = step;

        return (
            <div>

                <div className="exclusions-edit">
                    <h4>Calculated Final Result</h4>
                    <p>This section is for calculating the final result price or premium.</p>
                    <Calculation
                        step={result}
                        inputs={inputs}
                        updateStep={(value) => {
                            this.updateStep({
                                result: {
                                    ...result,
                                    type: 'decimal',
                                    formula: value.formula,
                                    def: value.def,
                                }
                            });
                        }}
                        is_approved={is_approved}
                    />
                </div>

                <div className="exclusions-edit">
                    <h4>Is Quote Valid?</h4>
                    <p>This section is for defining if a quote is valid or not.</p>
                    <div className="edit-columns">
                        <div className="edit-column">
                            <form>
                                <fieldset className={'spacing'}>
                                    <label>Default Value</label>
                                    <BooleanSelect 
                                            value={valid.def}
                                            update={({ value }) => {
                                                this.updateStep({
                                                    valid: {
                                                        ...valid,
                                                        def: value,
                                                    }
                                                });
                                            }}
                                        />
                                </fieldset>
                                <fieldset className={'spacing'}>
                                    <label>Expression</label>
                                    <ExpressionTextarea 
                                        inputs={inputs} 
                                        value={valid.exp}
                                        updateExpression={(value) => {
                                            this.updateStep({
                                                valid: {
                                                    ...valid,
                                                    exp: value,
                                                }
                                            })
                                        }}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    {valid.exp !== '' &&
                        <ExpressionTest 
                            inputs={inputs}
                            expression={valid}
                        />
                    }
                </div>

                <FormatEdit
                    schema={outputSchema}
                    set_error={this.props.set_error}
                    updateStep={this.updateStep}
                    step={step}
                    inputs={inputs}
                    header={'Additional Outputs'}
                    description={'This section enables you to define additional parameters in your returned response.'}
                    tag={'Output'}
                    is_approved={is_approved}
                />
            </div>
        );
    }
}

export default OutputEdit;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                Powered by <Link to={"/"}>Swallow</Link>
            </div>
        );
      
    }
}

export default Footer;
/* eslint eqeqeq:"off" */

import React, { Component } from 'react';
import { find } from 'lodash';
import moment from 'moment';

import StepWrapper from './Step/StepWrapper';
import ActualResult from '../../components/Test/ActualResult';
import DownloadBox from '../Layout/DownloadBox';
import Video from '../Layout/Video';

import { test_runner } from '../../utils/runner';

class DesignArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide_outputs: true,
            is_logs_open: false,
            is_running: false,
            selected_test: null
        }
        this.runTest = this.runTest.bind(this);
        this.updateIsLogOpen = this.updateIsLogOpen.bind(this);
        this.hideOutputs = this.hideOutputs.bind(this);
        this.clearSelectedTest = this.clearSelectedTest.bind(this);
    }

    async runTest (test_id) {
        const { tests } = this.props.project;
        const test = find(tests, t => t.id === test_id);
        this.setState({ is_running: true });
        const result = await test_runner({
            project: this.props.project,
            test,
            debug: true,
        });
        this.setState({ 
            selected_test: result,
            is_running: false, 
        });
    }

    renderLatestStatus ({ status = '', version_number }) {
        const map = {
            draft: 'secondary',
            approved: 'tertiary',
            published: 'tertiary',
        };
        const cn = map[status] || 'grey';
        return (<button className={`button no-hover ${cn} small`}>{status.toUpperCase()} #{version_number}</button>);
    }

    clearSelectedTest () {
        this.setState({
            hide_outputs: true,
            selected_test: null,
        })
    }

    updateIsLogOpen() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        })
    }

    hideOutputs() {
        this.setState({
            hide_outputs: !this.state.hide_outputs,
        })
    }

    render() {
        const { 
            is_logs_open,
            is_running,
            hide_outputs,
            selected_test,
        } = this.state;

        const { 
            steps = [], 
            input, 
            output, 
            version_reference, 
        } = this.props;

        const {
            meta = {},
            tests = [],
        } = this.props.project;

        const {
            status = '',
            version: version_number,
            created_at,
            approved_at,
        } = meta;

        const is_approved = (status === 'published' || status === 'approved');

        return (
            <section className={`main-content slide-left ${is_approved? 'approved' : ''}`}>
                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <button className="button secondary big" onClick={this.hideOutputs}>
                            { hide_outputs ? 'Show Outputs' : 'Hide Outputs' }
                        </button>
                    </div>
                    
                    {!is_approved &&
                    <div className="design-button-area-center">
                        <Video />
                    </div>
                    }

                    {!is_approved &&
                        <div className="design-button-area-right">
                            {selected_test &&
                                <button className="button grey big mr" onClick={this.clearSelectedTest}>
                                    Clear Test Data
                                </button>
                            }
                            <button className="button secondary big" onClick={() => this.props.saveVersion({ to_cloud: true })}>
                                Save Design
                            </button>
                        </div>
                    }

                    {is_approved && 
                        <div className="design-button-area-right">
                            {selected_test &&
                                <button className="button grey big mr" onClick={this.clearSelectedTest}>
                                    Clear Test Data
                                </button>
                            }
                            <button className="button grey big no-hover">
                                Already Approved
                            </button>
                        </div>
                    }
                    
                </div>

                <div className="design-area">

                    <div className="design-area-left" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        
                        <div className="review-side-section">
                            <DownloadBox
                                version_reference={version_reference}
                            />
                        </div>

                        <div className="review-side-section">
                            <h4>Created</h4>
                            <button className="button white small no-hover mr">
                                {moment(created_at).format('lll')}
                            </button>
                            <button className="button white small no-hover">
                                <i className='fa fa-clock'></i>
                                {moment(created_at).fromNow()}
                            </button>
                        </div>

                        <div className="review-side-section">
                            <h4>Version</h4>

                            <div style={{width: '100%'}}>
                                {this.renderLatestStatus({ status, version_number })}
                            </div>

                            {status === 'approved' && 
                                <button className="button small grey no-hover mr" style={{ marginBottom: 20}}>
                                    {moment(approved_at).format('lll')}
                                </button>
                            }

                            {status === 'approved' &&         
                                <button className="button grey small no-hover" style={{ marginBottom: 20}}>
                                    <i className='fa fa-clock'></i>
                                    {moment(created_at).fromNow()}
                                </button>
                            }                     
                        </div>

                        <div className="review-side-section">
                            <h4>Version Reference</h4>
                            <button className="button small secondary no-hover">
                                {status === 'draft' &&<i className="fa fa-lock-open"></i>}
                                {status !== 'draft' &&<i className="fa fa-lock"></i>}
                                {version_reference}
                            </button>
                        </div>

                    </div>

                    <div className="design-area-center" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        <StepWrapper
                            id={'input'}
                            index={0}
                            step={{
                                ...input,
                                id: 'input',
                                key: 'input',
                                step: 'input'
                            }}
                            quote={selected_test}
                            hide_outputs={hide_outputs}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                        />

                        {steps.map((s, i) => {
                            return (<StepWrapper
                                key={s.id} // To remove React key warning
                                id={s.id}
                                index={i}
                                step={s}
                                quote={selected_test}
                                hide_outputs={hide_outputs}
                                addComponent={this.props.addComponent} 
                                updateComponent={this.props.updateComponent} 
                                editComponent={this.props.editComponent}
                                deleteComponent={this.props.deleteComponent}
                                hideOutputs={this.hideOutputs}
                            />);
                        })}

                        <StepWrapper
                            id={'output'}
                            index={0}
                            step={{
                                ...output,
                                id: 'output',
                                key: 'output',
                                step: 'output'
                            }}
                            quote={selected_test}
                            hide_outputs={hide_outputs}
                            addComponent={this.props.addComponent} 
                            updateComponent={this.props.updateComponent} 
                            editComponent={this.props.editComponent}
                        />
                    </div>

                    <div className="design-area-right">
                        <div className="edit-inputs"> 
                            <form>
                                <fieldset>
                                    <label>Run Test Scenario</label>
                                    <select 
                                        value={(selected_test || {}).id || ''} 
                                        onChange={(e) => {
                                            if(e.target.value == '') return;
                                            this.runTest(e.target.value);
                                        }}>
                                        <option value={''}>Pick Test to Run</option>
                                        {tests.map(t => {
                                            return (<option key={t.id} value={t.id}>{t.name}</option>);
                                        })}
                                    </select>
                                </fieldset>
                            </form>
                            {(selected_test || is_running) &&
                                <ActualResult
                                    quote={selected_test}
                                    is_running={is_running}
                                    updateIsLogOpen={this.updateIsLogOpen}
                                /> 
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default DesignArea;
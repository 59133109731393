import React, { Component } from 'react';
import { round } from 'lodash';

const timeout = 0;

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        }
        this.redirect = this.redirect.bind(this);
    }

    redirect() {
        const { success_url } = this.props;
        if (success_url) {
            window.location.reload(success_url);
        } else {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        if (timeout === 0) this.redirect();
        const interval = setInterval(() => {
            const { count } = this.state;
            if (count >= timeout) {
                clearInterval(interval);
                this.redirect();
            } else {
                this.setState({ count: count + 1 });
            }
        }, 1000);
    }

    render() {
        const { count } = this.state;
        
        return (
            <div id="success" className="wf">
                <h1>Success!</h1>
                <h2>Your in...</h2>
                <p>You will be automatically re-directed in <mark>{round(timeout - count, 0)}</mark> seconds or you can click on the button below. </p>
                <button className="primary-button alt" onClick={(e) => {
                    e.preventDefault();
                    this.redirect();
                }}>
                    Take me there!
                </button>
            </div> 
        );
    }
}
export default Success;
import {
    getStep,
    parseFormattedOutput,
} from './steps';

import { 
    engine as defaultEngine, 
    evaluateExternal,
    evaluateExpression,
    QL,
} from '@swa_llow/pricing_engine';

import { omit, each, isEmpty, isString } from 'lodash';

export function test_response ({ test, result, debug }) {
    return {
        ...test,
        result: omit(result, 'debug'),
        debug: debug ? result.debug : {},
    }
}

export async function test_runner({
    test, 
    project,
    debug = false,
    engine = defaultEngine,
}) {

    // Default if no input value set yet
    // This stops the engine failing as we skip the input step on is_test=true
    const defaultInputsFormatted = getStep('input').output({ step: project.input });
    const defaultInputs =  parseFormattedOutput(defaultInputsFormatted);

    const quote = {
        ...defaultInputs,
        ...test.input,
    }

    const result = await engine({
        project,
        quote,
        is_test: true,
    });

    const response = test_response({
        result,
        test,
        debug,
    });

    return response;
}

export async function external_runner({
    test, 
    config,
}) {

    const {
        url = '',
        method = '', 
        headers = {},
        timeout = 3000,
        type: responseType = 'json',
        format = {},
    } = config;

    const { output } = test;
    let result;
    try {
        result = await evaluateExternal({
            url,
            method,
            headers,
            timeout,
            responseType,
            data: {
                result: output.result,
                valid: output.valid,
            },
        }).then((response) => {
            const { data, code, message } = response;

            // On error
            if (code) {
                return {
                    code,
                    message,
                    name: test.name,
                    key: test.key,
                }
            }

            // On result 
            // TODO this will only work with JSON atm
            const result = data.json || data.data;

            if (!isEmpty(format)) {
                let new_result = {};
                const q = QL(result);  

                each(format, (f, k) => {
                    const { exp } = f;
                    const { result, invalid } = evaluateExpression({ expression: exp }, q);

                    //If expression fails then
                    if (invalid) {
                        return {
                            code: 400,
                            message: `Issue with format exp : ${exp}`,
                            name: test.name,
                            key: test.key,
                        }
                    }

                    new_result[k] = isString(result) ? result : result.toString();
                });
                return {
                    ...new_result,
                    name: test.name,
                    key: test.key,
                }
            } else {
                // Just return back response
                return {
                    result:  result.result,
                    valid: isString(result.valid) ? result.valid : result.valid.toString(),
                    name: test.name,
                    key: test.key,
                }
            }
        });
    } catch (e) {
        result = {
            code: 400,
            message: e.message,
            name: test.name,
            key: test.key,
        }
    }

    return result;
}
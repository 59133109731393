import React, { Component } from 'react';
import slugify from '../../../../../utils/slugify';
import Table from './Table';
import ExpressionTextarea, { evaluateExpressions } from '../Expression/ExpressionTextarea';
import ExpressionTest from '../Expression/ExpressionTest';

import {
    evaluateSchema,
} from '@swa_llow/pricing_engine';

import {
    error_response
} from '../../../../../utils/errors';

import { each, omit, isUndefined } from 'lodash';
import BooleanSelect from '../Forms/BooleanSelect';

class FormatEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        }
        this.updateFormats = this.updateFormats.bind(this);
        this.selectFormat = this.selectFormat.bind(this);
        this.validate = this.validate.bind(this);
        this.updateFormat = this.updateFormat.bind(this);
        this.clearExpression = this.clearExpression.bind(this);
        this.updateFormatFromTable = this.updateFormatFromTable.bind(this);
        this.updateDefaultFromExpression = this.updateDefaultFromExpression.bind(this);
    }

    map_formats(format = {}) {
        let arr = [];
        each(format, (v, k) => {
            arr = [{
                key: k,
                ...v,
            }, ...arr];
        });
        return arr;
    }

    updateFormat (data) {
        const { selected = {} } = this.state;
        const { step } = this.props;
        const { key, exp } = selected || {};

        let new_data = {
            ...selected,
            ...data,
        }

        // If input then we can default the expression
        // i.e. no search the payload for it
        if (step.step === 'input') {
            if (isUndefined(exp)) {
                if (key) {
                    new_data = {
                        ...new_data,
                        exp: `{{${key}}}`
                    }
                }
            }
        }

        this.setState({
            selected: new_data
        });
    }

    updateDefaultFromExpression(result) {
        let { selected } = this.state;

        if (isUndefined(selected.def)) {
            this.setState({
                selected: {
                    ...selected,
                    def: result,
                }
            });
        }
    }

    clearExpression () {
        this.setState({
            selected: null,
            test: null,
        });
    }

    selectFormat (selected) {
        this.setState({
            selected,
            test: null,
        })
    }

    validate(data) {
        let { step, inputs, schema } = this.props;

        this.props.set_error(null);

        const result = evaluateSchema({ 
            project: {
                ...step,
                ...data,
        }, schema });

        if (!result.valid) {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Format',
            }));
            this.props.set_error(errors[0]);
            return result.valid;
        } else {
            if(!inputs) {
                return true;
            }
        }

        const items = this.map_formats(data.format);

        let exp_errors = evaluateExpressions({
            inputs,
            items,
        }).filter(exp => exp.invalid);

        if (exp_errors.length > 0) {
            this.props.set_error({
                message : `Expression: ${exp_errors[0].message}`
            });
            return false;
        }

        return true;
    }

    async updateFormats() {
        const { step, updateStep, inputs } = this.props;
        const { format } = step;

        let { selected = {} } = this.state;

        // Auto creates a def from the result of the expression
        if (isUndefined(selected.def)) {
            const [ result ] = evaluateExpressions({
                inputs,
                items: [selected],
            });
            if (!result.invalid) {
                selected = {
                    ...selected,
                    def: result.result,
                }
            }
        }

        let new_format = {
            ...format,
            [selected.key]: {
                ...selected,
            }
        };

        const payload = {
            ...step,
            retain: true,
            format: new_format
        }

        if(!this.validate(payload)) return;
        await updateStep(payload);
        this.clearExpression();
    }

    updateFormatFromTable({ items = [] }) {
        const format = {};
        const { step } = this.props;

        items.forEach(ni => {
            format[ni.key] = omit(ni, ['key', 'id']);
        });

        this.props.updateStep({
            ...step,
            format,
        });
    }

    render() {
        const { 
            step,
            inputs,
            header,
            description,
            tag = 'Format',
            is_approved = false,
        } = this.props;

        const { format } = step;

        const { selected } = this.state;

        let {
            id = '',
            label = '',
            exp = '',
            def = '',
            key = '',
            type = '',
            static: format_static,
        } = selected || {};

        const items = this.map_formats(format);

        return [
            <div key={`${id}-edit`} className="exclusions-edit">
                {header && <h4>{header}</h4>}
                {description && <p>{description}</p>}
                <div className="table-control-panel">
                    <div className="table-control-panel-left">

                        {step.id === 'input' &&
                            <button className={`button ${format_static ? 'secondary' : 'grey'} small`}
                                onClick={() => {
                                    this.updateFormat({
                                        static: !format_static,
                                    })
                                }}
                            >
                                {!format_static && <i className="fa fa-xmark"></i>} 
                                {format_static && <i className="fa fa-check"></i>} 
                                Static Property
                            </button>
                        }

                    </div>
                    {!is_approved &&
                        <div className="table-control-panel-right">
                            <button className="button grey small mr"
                                onClick={this.clearExpression}
                            > Clear {tag}</button>
                            <button className="button secondary small" 
                                onClick={this.updateFormats}
                            > Save {tag}</button>
                        </div>
                    }
                </div>

                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={'spacing'}>
                                <label>{tag} Name</label>
                                <input 
                                    onChange={(e) => {
                                        this.updateFormat({
                                            label: e.target.value,
                                        })
                                    }} 
                                    onBlur={(e) => {
                                        let polyfill = {}
                                        if(key === '') polyfill.key = slugify(e.target.value);
                                        if(!inputs) polyfill.exp = '{{false}}';
                                        this.updateFormat(polyfill);
                                    }}
                                    value={label}
                                />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>{tag} Key</label>
                                <input onChange={(e) => {
                                    this.updateFormat({
                                        key: slugify(e.target.value),
                                    })
                                }} value={key} />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Property Data Type</label>
                                <select 
                                    value={type} 
                                    onChange={(e) => {
                                        if (e.target.value === '') return;
                                        this.updateFormat({
                                            type: e.target.value,
                                        });
                                    }
                                }>
                                    <option value={''}></option>
                                    <option value={'boolean'}>Boolean</option>
                                    <option value={'decimal'}>Decimal</option>
                                    <option value={'integer'}>Integer</option>
                                    <option value={'string'}>String</option>
                                    <option value={'date'}>Date</option>
                                    {/*<option value={'enum'}>Enum</option>*/}
                                </select>
                            </fieldset>

                            {inputs && !format_static &&
                                <fieldset className={'spacing'}>
                                    <label>{tag} Expression</label>
                                    <ExpressionTextarea 
                                        inputs={inputs} 
                                        value={exp}
                                        updateExpression={(value) => {
                                            this.updateFormat({
                                                exp: value,
                                            });
                                        }}
                                    />
                                </fieldset>
                            }

                            {type === 'boolean' &&
                                <fieldset className={'spacing'}>
                                    <label>Default Value</label>
                                    <BooleanSelect 
                                            value={def}
                                            update={({ value }) => {
                                                this.updateFormat({
                                                    def: value,
                                                })
                                            }}
                                        />
                                </fieldset>
                            }

                            {type === 'string' && 
                                <fieldset className={'spacing'}>
                                    <label>Default Value</label>
                                    <input type="text" onChange={(e) => {
                                        this.updateFormat({
                                            def: e.target.value,
                                        })
                                    }} value={def} />
                                </fieldset>
                            }

                            {(type === 'integer' || type=== 'decimal') &&
                                <fieldset className={'spacing'}>
                                    <label>Default Value</label>
                                    <input type="number" onChange={(e) => {
                                        let { value } = e.target;
                                        if (value !== '') value = Number(value);
                                        this.updateFormat({
                                            def: value,
                                        })
                                    }} value={def} />
                                </fieldset>
                            }

                            {(type === 'date') &&
                                <fieldset className={'spacing'}>
                                    <label>Default Value</label>
                                    <input type="date" className={'date'} onChange={(e) => {
                                        let { value } = e.target;
                                        this.updateFormat({
                                            def: value,
                                        })
                                    }} value={def} />
                                </fieldset>
                            }

                        </form>
                    </div>
                </div>
                {exp !== '' && inputs && !format_static &&
                    <ExpressionTest 
                        inputs={inputs}
                        expression={selected}
                        updateDefaultFromExpression={this.updateDefaultFromExpression}
                    />
                }
            </div> 
            ,<div key={`${id}-table`} className="exclusions-table">
                <Table 
                    placeholder={tag}
                    inputs={inputs}
                    headers={[
                        `${tag}`,
                        'Type',
                        'Static',
                        'Default'
                    ]}
                    hide_checkboxes={true}
                    componentData={{
                        ...step,
                        items,
                    }}
                    updateComponent={this.updateFormatFromTable}
                    selectItem={this.selectFormat}
                    is_approved={is_approved}
                />
            </div>
        ];
    }
}

export default FormatEdit;
import { last, get } from 'lodash';

export function getHashId(history) {
    return last(get(history, 'location.hash', '').split('/'))
}

export function getId(history) {
    return last(get(history, 'location.pathname', '').split('/'))
}

export function getQuery(history, query) {
    return last(get(history, 'location.search', '').split(`?${query}=`))
}

export function isVersionPage(history) {
    return get(history, 'location.search', '').includes('/version');
}
import React, { Component } from 'react';
import Wysiwyg from './Wysiwyg';

class Block extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            title: this.props.title, 
        }
    }
    render () {
        const { open, title } = this.state;
        const { html = [], save_process = () => {}, edit_mode, is_summary = false, index, blocks } = this.props;

        if (!open) {
            return (
                <div className="review-block">
                    {edit_mode && <div className="wysiwyg-buttons alt">
                        <button className="button secondary small" onClick={() => 
                            this.setState({ open: true })
                        }>Edit</button>
                    </div>}
                    <div className="review-block-html" dangerouslySetInnerHTML={{
                        __html: `${html}`
                    }} />
                </div>
            )
        }

        return [
            <div className="review-block-editor">
                {!is_summary &&
                    <input value={title} onChange={(e) => {
                        this.setState({
                            title: e.target.value,
                        });
                    }} />
                }
                {!is_summary && 
                    <div className="wysiwyg-buttons" style={{ top: 5, right: 26 }}>
                        <button onClick={() => {
                            save_process({
                                blocks: blocks.filter((b, i) => {
                                    return i !== index
                                }),
                            });
                        }} className="button error small">Delete Block</button> 
                    </div>
                }
            </div>,
            <div className="review-block">
                <div className="review-block-editor">
                    <Wysiwyg 
                        value={html}
                        close={() => this.setState({ open: false })}
                        save={(value) => {
                            if (is_summary) {
                                save_process({
                                    summary: {
                                        content: value,
                                    },
                                });
                            } else {
                                save_process({
                                    blocks: blocks.map((b, i) => {
                                        if (i === index) {
                                            return {
                                                ...b,
                                                title,
                                                content: value,
                                            }
                                        }
                                        return b;
                                    }),
                                });
                            }
                            this.setState({ open: false })
                        }}
                    />
                </div>
            </div>
        ]
    }
}

export default Block;
import React, { Component } from 'react';

import StepEmpty from './StepEmpty';
import StepComplete from './StepComplete';
import StepInput from './StepInput';
import StepOutput from './StepOutput';

import { getStep } from '../../../utils/steps';
import { each } from 'lodash';

function map_outputs (outputs) {
    let arr = [];
    each(outputs, (v, k) => {
        arr.push({
            key: k,
            value: v.value,
        })
    });
    return arr;
}

function step_contents ({
    step,
    quote,
}) {
    const { step:step_type } = step;
    const step_controller = getStep(step_type);
    const is_completed = step_controller.is_completed({ step });
    let outputsFormatted = step_controller.output({ step });
    let errors = [];
    let timer;
    let logs;
    if (quote) {
        timer = step_controller.timer({ step, quote });
        outputsFormatted = step_controller.output({ step, quote });
        errors = step_controller.errors({ step, quote });
        logs = step_controller.logs({ step, quote });
    }
    const outputs = map_outputs(outputsFormatted);
    return {
        outputsFormatted,
        outputs,
        errors,
        timer,
        logs,
        is_completed,
    }
}

class StepWrapper extends Component {
    render() {
        const {
            index,
            quote,
            step,
            addComponent,
            editComponent,
            deleteComponent,
            updateComponent,
            hide_outputs,
            hideOutputs,
        } = this.props;

        const {
            name: step_name,
            step: step_type,
        } = step;

        const is_empty = !(step_name && step_type);

        if (step_type === 'input') {
            return (
                <StepInput 
                    id={step.id}
                    // Input step doesn't exist when running is_test=true
                    contents={step_contents({ step, quote: quote ? {
                        ...quote,
                        debug: {
                            ...quote.debug,
                            steps: {
                                ...quote.debug.steps,
                                input: {
                                    output: quote.input,
                                }
                            }
                        }
                    } : {}})}
                    quote={quote}
                    hide_outputs={hide_outputs}
                    step={step}
                    editComponent={editComponent}
                    addComponent={addComponent}
                    hideOutputs={hideOutputs}
                />
            );
        }

        if (step_type === 'output') {
            return (
                <StepOutput
                    id={step.id}
                    contents={step_contents({ step, quote })}
                    quote={quote}
                    hide_outputs={hide_outputs}
                    step={step}
                    editComponent={editComponent}
                    addComponent={addComponent}
                    hideOutputs={hideOutputs}
                />
            );
        }
        
        return (
            <div key={step.id} className="empty-module-wrapper">

                {is_empty &&
                    <StepEmpty
                        id={step.id}
                        updateComponent={updateComponent}
                        editComponent={editComponent}
                        deleteComponent={deleteComponent}
                    />
                }

                {!is_empty && 
                    <StepComplete
                        id={step.id}
                        contents={step_contents({ step, quote })}
                        quote={quote}
                        hide_outputs={hide_outputs}
                        step={step}
                        editComponent={editComponent}
                        deleteComponent={deleteComponent}
                        hideOutputs={hideOutputs}
                    />
                }

                <div key={`${step.id}-connector`}className="connector">
                    <div className="straight-connector"></div>
                    <div className="node-connector" onClick={() => {
                        addComponent(index + 1)
                    }}></div>
                </div>

            </div>
        )
    }
}

export default StepWrapper;
import React, { Component } from 'react';

import WithoutData from './WithoutData';
import WithData from './WithData';
import { isEmpty } from 'lodash';

class DataLinks extends Component {
    render() {
        const { 
            step = {}, 
            is_approved,
        } = this.props;

        const { links } = step;

        if (isEmpty(links)) {
            return (
                <WithoutData
                    key={'without-data'}
                    step={step}
                    updateStep={this.props.updateStep}
                    is_approved={is_approved}
                />
            )
        } else {
            return (
                <WithData
                    key={'with-data'}
                    step={step}
                    updateStep={this.props.updateStep}
                    is_approved={is_approved}
                />
            )
        }    
    }
}

export default DataLinks;
import { combineReducers } from 'redux';

import lang from './lang';
import swallow_error from './set_error';
import auth from './auth';
import token from './token';

// Global but will change per service
import allow_sessions from './sessions';

//Allow
import allow_users from '../services/allow/reducers/users';
import allow_companys from '../services/allow/reducers/companys';
import allow_pools from '../services/allow/reducers/pools';

//Swallow
import swallow_landing from '../services/swallow/reducers/landing';
import swallow_quotes from '../services/swallow/reducers/quotes';
import swallow_projects from '../services/swallow/reducers/projects';
import swallow_versions from '../services/swallow/reducers/versions';
import swallow_actions from '../services/swallow/reducers/actions';
import swallow_signed from '../services/swallow/reducers/signed';
import swallow_users from '../services/swallow/reducers/users';
import swallow_process from '../services/swallow/reducers/process';
import swallow_tests from '../services/swallow/reducers/tests';

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

let base = {
    swallow_error,
    lang,
    token,
    auth,
}

if (main_component === 'allow') {
    base = {
        ...base,
        allow_users,
        allow_companys,
        allow_pools,
        allow_sessions,
    }
}

if (main_component === 'swallow') {
    base = {
        ...base,
        swallow_landing,
        swallow_quotes,
        swallow_projects,
        swallow_versions,
        swallow_signed,
        swallow_actions,
        swallow_users,
        swallow_process,
        swallow_tests,
    }
}

export default combineReducers(base);
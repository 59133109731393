import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';

class Wysiwyg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
        this.change = this.change.bind(this);
        this.save = this.save.bind(this);
    }

    save() {
        const { value } = this.state;
        this.props.save(value);
        this.setState({
            value: '',
        })
    }

    change(value) {
        this.setState({
            value,
        })
    }

    render () {
        const { value, button_text = 'Save Changes', height = 300 } = this.props;
        return [
            <div key={`wysiwyg-but`} className="wysiwyg-buttons">
                <button className="button secondary small" onClick={this.save}>{button_text}</button>
            </div>,
            <Editor
                key={`wysiwyg-comment`}
                apiKey='0qxjnxfaa694zcx7cp5ui4nxlgm3wmruofvku8gjjbgi30d6'
                initialValue={value}
                onEditorChange={this.change}
                init={{
                    height,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'visualblocks', 'fullscreen', 'insertdatetime', 'media', 'table', 'code'
                    ],
                    toolbar: 'undo redo bold underline italic bullist numlist code',
                    content_style: 'body { font-family:Nunito,Arial,sans-serif; font-size:14px }'
                }}
            />
        ]
    }
}

export default Wysiwyg;
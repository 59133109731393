import React, { Component } from 'react';
import Calculation from '../Shared/Calculation/Calculation';

class CalculationEdit extends Component {
    render() {
        const { 
            inputs,
            step,
            is_approved,
        } = this.props;

        return (
            <div className="exclusions-edit">
                <h4>Calculation</h4>
                <p style={{marginBottom: 0}}>This section calculating a value and passing it on to the next step as a property.</p>
                <Calculation 
                    step={step}
                    inputs={inputs}
                    updateStep={this.props.updateStep}
                    is_approved={is_approved}
                />
            </div>
        );
    }
}

export default CalculationEdit;
import React, { Component } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { each, isObject, isArray, has } from 'lodash';

import {
    evaluateExpression,
    QL,
} from '@swa_llow/pricing_engine';

function map_each ({ 
    inputs,
    prefix = '',
}) {
    let items = [];

    // normalise inputs 
    let new_inputs = {};
    each(inputs, (v, k) => {
        if (has(v, 'value'))  {
            new_inputs[k] = v.value;
        } else {
            new_inputs[k] = v;
        }
    });

    each(new_inputs, (v, k) => {
        const key = (prefix !== '' ? `${prefix}.${k}` : k);
        if (isObject(v) && !isArray(v)) {
            const new_items = map_each({ inputs: v, prefix: key});
            items = [ ...new_items , ...items];
        } else {
            items = [{
                name: key,
                char: `{{${key}}}`,
            }, ...items];
        }
    })
    return items;
}

export function evaluateExpressions({
    inputs,
    items = [],
}) {
    const quote = {};

    each(inputs, (v, k) => {
        if (has(v, 'value')) {
            quote[k] = v.value;
        } else {
            quote[k] = v;
        }
    });

    const result = items.map(i => {
        const { exp, static:format_static, def } = i;
        if (format_static) {
            return {
                ...i,
                result: def,
                elements: [],
            }
        }
        const result = evaluateExpression({ expression: exp }, QL(quote));
        return {
            ...i,
            invalid: result.invalid,
            message: result.message,
            result: result.result,
            elements: result.elements,
        };
    });

    return result;
}

class ExpressionTextarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: this.props.inputs,
            formatter : true,
        }
        this.updateInputValue = this.updateInputValue.bind(this);
        this.showFormatter = this.showFormatter.bind(this);
        this.format = this.format.bind(this);
    }

    showFormatter() {
        this.setState({
            formatter: !this.state.formatter,
        });
    }

    format(str = '') {
        let new_str = str.replace(/{{/g, '<span class="variable">');
        new_str = new_str.replace(/}}/g, '</span>');
        return new_str;
    }

    updateInputValue ({
        key, 
        value,
        type,
    }) {
        this.props.updateInputValue({
            [key] : {
                value,
                type,
            } 
        });
    } 

    render() {
        const inputs = map_each({ inputs: this.props.inputs });
        const { value } = this.props;
        const { formatter } = this.state;

        return (
            <div className="textarea-formatted-container">
                <ReactTextareaAutocomplete
                    value={value}
                    className="expression-textarea"
                    onFocus={this.showFormatter}
                    onBlur={this.showFormatter}
                    onChange={(e) => this.props.updateExpression(e.target.value)}
                    loadingComponent={() => <span>Loading</span>}
                    trigger={{
                        "{{": {
                            dataProvider: token => {
                                return inputs.filter(item => item.char.includes(token));
                            },
                            component: ({ entity: { name, char } }) => (<div>{`${char}`}</div>),
                            output: (item) => item.char,
                        }
                    }}
                />
                {formatter && <div className="textarea-formatted-content" dangerouslySetInnerHTML={{__html: this.format(value)}}></div>}
            </div>
        );
    }
}

export default ExpressionTextarea;
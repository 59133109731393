import axios from 'axios';
import config from '../../../config';
import { get_auth } from '../../../auth';

import { getLocalVersion, putLocalVersion } from './local_db';

export async function putLargeVersion ({ version_reference, version }) {
    const { access_token: token } = await get_auth();

    const { signed_url } = await axios({
        url: `${config.root}/sw/projects/versions/large/${version_reference}`,
        method: 'put',
        headers: {
            authorization: `Bearer ${token}`,
        },
    }).then(({data}) => data);

    const result = await axios({
        url: signed_url,
        method: 'put',
        data: version,
    }).then(({data}) => data);

    return result;
}

export async function getLargeVersion ({ version_reference }) {
    const { access_token: token } = await get_auth();

    const { signed_url } = await axios({
        url: `${config.root}/sw/projects/versions/large/${version_reference}`,
        headers: {
            authorization: `Bearer ${token}`,
        }
    }).then(({data}) => data);

    const result = await axios({
        url: signed_url,
    }).then(({data}) => data);

    // This make sure the status of the version is reflective of the data base and not just what is stored in S3
    const [ project ] = await axios({
        url: `${config.root}/sw/projects/versions?version_reference=${version_reference}`,
        headers: {
            authorization: `Bearer ${token}`,
        }
    }).then(({data}) => data); 

    return { 
        ...result,
        meta: project,
    };
}

export async function fetchVersion({ version_reference, no_cache = false }) {
    let local_version = await getLocalVersion({ version_reference });
    let version;
    if (!local_version || no_cache) {
        version = await getLargeVersion({
            version_reference,
        });
        await putLocalVersion({ version_reference, version });
    } else {
        version = local_version;
    }
    return version;
}
import React, { Component } from 'react';
import { omit } from 'lodash';
import { getId } from '../../../../utils/url';
import config from '../../../../config/index';

import TopNav from '../../components/Layout/TopNav';

import GlobalLoading from '../../../../components/GlobalLoading';
import Avatar from '../../components/Layout/Avatar';
import Footer from '../../components/Layout/Footer';

const base_roles = ['read', 'write', 'approve'];
const base_notifys = ['created', 'started', 'mentioned', 'comments', 'replies', 'approved'];

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_user: {},
            find_user: null,
            add_user: false,
        }
        this.addUser = this.addUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.openAddUser = this.openAddUser.bind(this);
        this.checkUserAccounts = this.checkUserAccounts.bind(this);
    }

    async componentDidMount() {
        const id = getId(this.props.history);
        await this.props.swallow_process_get({
            id,
        });
        const { selected = {} } = this.props.swallow_process;
        const { team = [] } = selected;
        this.setState({
            selected_user: team[0],
        })
    }

    async updateUser(user) {
        this.setState(user);
    }

    openAddUser(){
        this.setState({
            find_user: null,
            add_user: !this.state.add_user,
        });
    }

    async addUser(e) {
        e.preventDefault();

        const { find_user = {} } = this.state;
        const { selected } = this.props.swallow_process;
        const { version_reference, project_reference } = selected.version;

        let user_reference = find_user.user_reference;
        let new_user;

        try {
            if (!user_reference) {
                await this.props.swallow_users_post({
                    data:{
                        email: find_user.email,
                        custom: {
                            name: find_user.name,
                            company: find_user.company,
                        },
                        project: {
                            project_reference,
                        },
                        pool_reference: config.pool_reference,
                    },
                });

                const { selected: user } = this.props.swallow_users;
                user_reference = user.user_reference;

            }  
            
            new_user = {
                user_reference,
                roles: ['read'],
                notifys: ['started', 'approved'],
                email: find_user.email,
                name: find_user.name,
                company: find_user.company,
            };

            const new_team = [...(selected.team || []), new_user];

            const data = {
                ...omit(selected, 'version'),
                ...selected,
            };

            await this.props.swallow_process_post_some({
                id: version_reference,
                data: {
                    ...data,
                    team: new_team,
                },
            });
        } catch(e) {
            console.log(e);
        }

        this.setState({
            find_user: null,
            add_user: false,
            selected_user: new_user,
        })
    }

    async checkUserAccounts () {
        this.setState({ find_user: null });

        const { find_email }  = this.refs;
        const email = find_email.value;

        await this.props.swallow_users_get_all({
            email,
        });
        const { data: users = [] } = this.props.swallow_users;
        const [ user ] = users;
        if (user) {
            this.setState({
                find_user: {
                    ...user,
                    ...user.custom,
                },
            });
        } else {
            this.setState({
                find_user: {
                    email,
                },
            });
        }
    }

    async saveUser() {
        const { selected } = this.props.swallow_process;
        const { version_reference, project_reference } = selected.version;
        const { selected_user } = this.state;

        const new_team = (selected.team || []).map(t => {
            if (t.user_reference === selected_user.user_reference) {
                return selected_user;
            }
            return t;
        })

        const data = {
            ...omit(selected, 'version'),
            ...selected,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data: {
                ...data,
                team: new_team,
            },
        });

        //Updates user in Allow
        let project = {
            project_reference,
        };

        base_roles.forEach(r => {
            if ((selected_user.roles || []).includes(r)) {
                project[`is_${r}`] = true;
            } else {
                project[`is_${r}`] = false;
            }
        });

        base_notifys.forEach(n => {
            if ((selected_user.notifys || []).includes(n)) {
                project[`notify_${n}`] = true;
            } else {
                project[`notify_${n}`] = false;
            }
        });

        await this.props.swallow_users_put({
            id: selected_user.user_reference,
            data: {
                custom: {
                    name: selected_user.name,
                    company: selected_user.company,
                },
                project,
            }
        })
    }

    async deleteUser() {
        const { selected } = this.props.swallow_process;
        const { version_reference, project_reference } = selected.version;
        const { selected_user } = this.state;

        const new_team = (selected.team || []).filter(t => {
            return t.user_reference !== selected_user.user_reference;
        });

        const data = {
            ...omit(selected, 'version'),
            ...selected,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data: {
                ...data,
                team: new_team,
            },
        });

        //Updates user in Allow
        await this.props.swallow_users_put({
            id: selected_user.user_reference,
            data: {
                project: {
                    project_reference, 
                    is_delete: true,
                }
            }
        });
    }

    render() {

        const {
            selected: process = {},
            isFetching,
        } = this.props.swallow_process;

        const {
            team = [],
            version = {},
        } = process;

        const { selected_user = {}, add_user = false, find_user }  = this.state;

        const {
            roles = [],
            notifys = [],
            name,
            email,
            company,
            user_reference,
        } = selected_user;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} version={version} auth={this.props.auth} />

                { isFetching && <GlobalLoading /> }

                {!isFetching &&
                    <section className="main-content slide-left">

                        <div className="main-content-nav">
                            <div className="main-content-nav-left">
                                <button className="button grey big" onClick={() => {
                                    this.props.history.goBack();
                                }}>&lt; Go Back</button>
                            </div>
                            <div className="main-content-nav-right">
                                <button className="button big" onClick={this.openAddUser}><i className="fa fa-user-plus"></i> Add Team Member</button>
                            </div>
                        </div>

                        {add_user &&
                            <div className="team-area" style={{marginBottom: 20}}>
                                <div className="team-area-left"></div>
                                <div className="team-area-right">
                                    <div>
                                        <div className="project-divide">
                                            <div className="project-role-header">
                                                <div className="project-role-header-left">
                                                    <h4>Add Team Member</h4>
                                                </div>
                                                <div className="project-role-header-right">
                                                    <button onClick={this.openAddUser} className="button small error mr">Close</button>
                                                    {!find_user && <button onClick={this.checkUserAccounts} className="button small">Find Member</button>}
                                                </div>
                                            </div>

                                            {!find_user &&
                                                <form>
                                                    <fieldset className="spacing">
                                                        <label>Search Email</label>
                                                        <input 
                                                            ref={'find_email'}
                                                            defaultValue={''}
                                                        />
                                                    </fieldset>
                                                </form>
                                            }

                                            {find_user && (find_user.user_reference) &&
                                                <form>
                                                    <div style={{margin: '20px 0 20px'}}>
                                                        <Avatar name={find_user.name} />
                                                    </div>
                                                    <button onClick={this.addUser} className="button small">Add Found Team Member</button>
                                                </form>
                                            }

                                            {find_user && !(find_user.user_reference) &&
                                                <form>
                                                    <div className="alert"><i className="fa fa-warning"></i> Email not found in any other project</div>
                                                    <fieldset className="spacing">
                                                        <label>Email</label>
                                                        <input 
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                this.updateUser({
                                                                    find_user: {
                                                                        ...selected_user,
                                                                        email: value,
                                                                    }
                                                                });
                                                            }} 
                                                            value={find_user.email}
                                                        />
                                                    </fieldset>
                                                    <fieldset className="spacing">
                                                        <label>Name</label>
                                                        <input 
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                this.updateUser({
                                                                    find_user: {
                                                                        ...find_user,
                                                                        name: value,
                                                                    }
                                                                });
                                                            }} 
                                                            value={find_user.name}
                                                        />
                                                    </fieldset>
                                                    <fieldset className="spacing">
                                                        <label>Company</label>
                                                        <input 
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                this.updateUser({
                                                                    find_user: {
                                                                        ...find_user,
                                                                        company: value,
                                                                    }
                                                                });
                                                            }} 
                                                            value={find_user.company}
                                                        />
                                                    </fieldset>
                                                    <button onClick={this.addUser} className="button small">Add New Team Member</button>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="team-area" style={{opacity: add_user ? '0.25' : '1'}}>
                            <div className="team-area-left">
                                <h4>Team Members</h4>
                                <ul>
                                    {team.map((t, i) => {
                                        const {
                                            user_reference,
                                            name,
                                        } = t;

                                        const active = user_reference === selected_user.user_reference;

                                        return (
                                            <li onClick={async() => {
                                                // await this.saveUser();
                                                await this.updateUser({ selected_user: t })
                                            }} key={user_reference} className={`${active ? 'active' : ''}`}>
                                                <Avatar name={name} />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="team-area-right">
                                <div>
                                    <div className="project-divide">
                                        <div className="project-role-header">
                                            <div className="project-role-header-left">
                                                <h4>Member Details</h4>
                                            </div>
                                            <div className="project-role-header-right">
                                                <button onClick={this.saveUser} className="button small">Save Member</button>
                                            </div>
                                        </div>
                                        <form>
                                            <fieldset className="spacing">
                                                <label>Email</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                email: value,
                                                            }
                                                        });
                                                    }}
                                                    value={email}
                                                />
                                            </fieldset>
                                            <fieldset className="spacing">
                                                <label>Name</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                name: value,
                                                            }
                                                        });
                                                    }} 
                                                    value={name}
                                                />
                                            </fieldset>
                                            <fieldset className="spacing">
                                                <label>Company</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                company: value,
                                                            }
                                                        });
                                                    }} 
                                                    value={company}
                                                />
                                            </fieldset>
                                            <fieldset className="spacing">
                                                <label>Reference</label>
                                                <input 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.updateUser({
                                                            selected_user: {
                                                                ...selected_user,
                                                                user_reference: value,
                                                            }
                                                        });
                                                    }} 
                                                    value={user_reference}
                                                />
                                            </fieldset>
                                        </form>

                                        <div className="table-wrapper" style={{marginTop: 20}}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Roles</th>
                                                        <th style={{ width: '200px' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {base_roles.map((role) => {
                                                        return (
                                                            <tr key={`role-${role}`}>
                                                                <td>
                                                                    <button className="button small grey role">{role}</button>
                                                                </td>
                                                                <td className="table-buttons">
                                                                    <div className="toggle">
                                                                        <input onChange={() => {
                                                                            let new_roles = [];
                                                                            if (roles.includes(role)) {
                                                                                new_roles = roles.filter(r => r !== role);
                                                                            } else {
                                                                                new_roles = [...roles, role];
                                                                            }
                                                                            this.updateUser({
                                                                                selected_user: {
                                                                                    ...selected_user,
                                                                                    roles: new_roles,
                                                                                }
                                                                            });
                                                                        }} type="checkbox" checked={roles.includes(role)  ? true : false} id={`role-${role}`} />
                                                                        <label htmlFor={`role-${role}`}></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="table-wrapper" style={{marginTop: 20}}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Notification</th>
                                                        <th style={{ width: '200px' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {base_notifys.map((notify) => {
                                                        return (
                                                            <tr key={`notifys-${notify}`}>
                                                                <td>
                                                                    on {notify}
                                                                </td>
                                                                <td className="table-buttons">
                                                                    <div className="toggle">
                                                                        <input onChange={() => {
                                                                            let new_notifys = [];
                                                                            if (notifys.includes(notify)) {
                                                                                new_notifys = notifys.filter(r => r !== notify);
                                                                            } else {
                                                                                new_notifys = [...notifys, notify];
                                                                            }
                                                                            this.updateUser({
                                                                                selected_user: {
                                                                                    ...selected_user,
                                                                                    notifys: new_notifys,
                                                                                }
                                                                            });
                                                                        }} type="checkbox" checked={notifys.includes(notify) ? true : false} id={`notifys-${notify}`} />
                                                                        <label htmlFor={`notifys-${notify}`}></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="project-role-header" style={{marginTop: 30}}>
                                            <div className="project-role-header-left">
                                            </div>
                                            <div className="project-role-header-right">
                                                <button onClick={this.deleteUser} className="button error small">Remove from Team</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                }

                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default Team;
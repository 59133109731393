import set_lang from './lang';
import set_error from './set_error';

import auth from './auth';
import set_token from './token';

// Global for all but pool_reference will change per service
import allow_sessions from './sessions';

//allow
import allow_users from '../services/allow/actions/users';
import allow_companys from '../services/allow/actions/companys';
import allow_pools from '../services/allow/actions/pools';

//swallow
import swallow_landing from '../services/swallow/actions/landing';

import swallow_quotes from '../services/swallow/actions/quotes';
import swallow_projects from '../services/swallow/actions/projects';
import swallow_versions from '../services/swallow/actions/versions';
import swallow_actions from '../services/swallow/actions/actions';
import swallow_signed from '../services/swallow/actions/signed';
import swallow_users from '../services/swallow/actions/users';
import swallow_process from '../services/swallow/actions/process';
import swallow_tests from '../services/swallow/actions/tests';

// env switch to pick right actions
const main_component = process.env.REACT_APP_MAIN;

let base = {
    set_lang,
    set_error,
    set_token,
    ...auth.exportMethods(),
    auth_reset: () => ({
        type: 'RESET_AUTH',
    }),
};

if (main_component === 'allow') {
    base = {
        ...base,
        ...allow_users.exportMethods(),
        ...allow_companys.exportMethods(),
        ...allow_pools.exportMethods(),
        ...allow_sessions.exportMethods(),
        ...swallow_actions.exportMethods(),
    }
}

if (main_component === 'swallow') {
    base = {
        ...base,
        ...swallow_landing.exportMethods(),
        ...swallow_quotes.exportMethods(),
        ...swallow_projects.exportMethods(),
        ...swallow_versions.exportMethods(),
        ...swallow_actions.exportMethods(),
        ...swallow_signed.exportMethods(),
        ...swallow_users.exportMethods(),
        ...swallow_process.exportMethods(),
        ...swallow_tests.exportMethods(),
    }
}

export default base;

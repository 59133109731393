import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';

import {
    inputSchema,
} from '@swa_llow/pricing_engine';

class InputEdit extends Component {
    render() {
        const { 
            step,
            inputs,
            is_approved,
        } = this.props;

        return <FormatEdit
            schema={inputSchema}
            set_error={this.props.set_error}
            updateStep={this.props.updateStep}
            step={step}
            inputs={inputs}
            header={'Define Initial Properties'}
            description={'You need to add at least one property that makes up your model. (e.g. age, gender, postcode).'}
            tag={'Property'}
            is_approved={is_approved}
        />;
    }
}

export default InputEdit;
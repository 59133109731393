import React from 'react';
import Table from '../Layout/Table';
import moment from 'moment';

import { Link } from 'react-router-dom';

class VersionsTable extends Table {
    renderLatestStatus (status = '') {
        const cn = status === 'draft' ? 'secondary' : 'tertiary';
        return (<button className={`button ${cn} small`}>{status.toUpperCase()}</button>);
    }

    renderRow(data) {
        const {
            version_reference,
            version,
            status,
            approved_at,
            published_at,
            created_at,
        } = data;

        return (
            <tr key={version_reference}>
                <td><b>{version}</b></td>
                <td>{this.renderLatestStatus(status)}</td>
                <td>{created_at ? moment(created_at).fromNow() : '-'}</td>
                <td>{approved_at ? moment(approved_at).fromNow() : '-'}</td>
                <td>{published_at ? moment(published_at).fromNow() : '-'}</td>
                <td>
                    {status === 'draft' &&
                        <Link className="button small secondary"to={`/version/review/${version_reference}`}>
                            Review Version
                        </Link>
                    }
                    {status !== 'draft' &&
                        <Link className="button small white" to={`/version/review/${version_reference}`}>
                            View Version
                        </Link>
                    }
                </td>
            </tr>
        )
    }

    render() {
        const { items = [] } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '20px' }}>#</th>
                            <th>Status</th>
                            <th style={{ width: '120px' }}>Created</th>
                            <th style={{ width: '120px' }}>Approved</th>
                            <th style={{ width: '120px' }}>Published</th>
                            <th style={{ width: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => {
                            return (this.renderRow(item));
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default VersionsTable;
import React, { Component } from 'react';
import { round } from 'lodash';

import base from '../../config';

class Waiting extends Component {
    render() {
        const { email, auth_count, name } = this.props;
        const timeout = (base.allow.poll_timeout / 1000);

        return (
            <div id="waiting" className="wf">
                <h1>{name ? `Hi ${name},` : 'Hi,'}</h1>
                <h2>Click on link in the sent email</h2>
                <p>Sent to: <b>{email}</b></p>
                <p>Please check your email account and click on the access link. This access email link will expire in <mark>{round(timeout - auth_count, 0)} seconds</mark>.</p>
            </div> 
        );
    }
}
export default Waiting;
import React from 'react';
import ItemsEdit from './Items';
import Table from './Table';
import ExpressionTextarea, { evaluateExpressions } from '../Expression/ExpressionTextarea';
import BooleanSelect from '../Forms/BooleanSelect';
import ExpressionTest from '../Expression/ExpressionTest';

import { isUndefined } from 'lodash';

class ItemsArrayEdit extends ItemsEdit {
    render() {
        const { 
            step,
            inputs,
            header,
            description,
            tag = 'Item',
            is_approved,
        } = this.props;

        const { def } = step;

        const { selected } = this.state;

        let {
            id = '',
            name = '',
            exp = '',
            value = '',
            new_def,
        } = selected || {};

        return [
            <div key={`${id}-edit`} className="exclusions-edit">
                {header && <h4>{header}</h4>}
                {description && <p>{description}</p>}
                {!is_approved &&
                    <div className="table-control-panel">
                        <div className="table-control-panel-left"></div>
                        <div className="table-control-panel-right">
                            <button className="button grey small mr"
                                onClick={this.clearExpression}
                            > Clear {tag}</button>
                            <button className="button secondary small" 
                                onClick={this.updateItems}
                            > {id === '' ? 'Create' : 'Update'} {tag}</button>
                        </div>
                    </div>
                }

                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={'spacing'}>
                                <label>{tag} Name</label>
                                <input onChange={(e) => {
                                    this.updateItem({
                                        name: e.target.value,
                                    })
                                }} value={name} />
                            </fieldset>

                            {step.step ==='excesses' && 
                                <fieldset className={'spacing'}>
                                    <label>Value</label>
                                    <input type="number" step="0.1" onChange={(e) => {
                                        this.updateItem({
                                            value: Number(e.target.value),
                                        })
                                    }} value={value} />
                                </fieldset>
                            }

                            {step.step ==='endorsements' && 
                                <fieldset className={'spacing'}>
                                    <label>Value</label>
                                    <input type="text" onChange={(e) => {
                                        this.updateItem({
                                            value: e.target.value,
                                        })
                                    }} value={value} />
                                </fieldset>
                            }

                            <fieldset className={'spacing'}>
                                <label>Expression</label>
                                <ExpressionTextarea 
                                    inputs={inputs} 
                                    value={exp}
                                    updateExpression={(value) => {
                                        this.updateItem({
                                            exp: value,
                                        });
                                    }}
                                />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Is Default?</label>
                                <BooleanSelect
                                    value={isUndefined(new_def) ? (id === '' ? '' :def.includes(value)) : new_def}
                                    update={(data) => {
                                        this.updateItem({
                                            new_def: data.value,
                                        });
                                    }}
                                />
                            </fieldset>
                        </form>
                    </div>
                </div>
                {exp !== '' &&
                    <ExpressionTest 
                        inputs={inputs}
                        expression={selected}
                    />
                }
            </div> 
            ,<div key={`${id}-table`} className="exclusions-table">
                <Table 
                    def={step.def}
                    placeholder={tag}
                    inputs={inputs}
                    headers={[
                        '',
                        'Name',
                        'Value',
                        'Default'
                    ]}
                    hide_checkboxes={true}
                    componentData={{
                        ...step,
                        items: evaluateExpressions({
                            inputs, 
                            items: step.items,
                        }),
                    }}
                    updateComponent={this.updateItemFromTable}
                    selectItem={this.selectItem}
                    is_approved={is_approved}
                />
            </div>
        ];
    }
}

export default ItemsArrayEdit;
import React, { Component } from 'react';
import { omit } from 'lodash';

import TopNav from '../../components/Layout/TopNav';
import TestDetail from '../../components/Test/TestDetail';
import Tests from '../../components/Test/Tests';

import GlobalLoading from '../../../../components/GlobalLoading';

import { getId } from '../../../../utils/url';
import { unique_id } from '../../utils/unique_id';
import {
    getStep,
    parseFormattedOutput,
} from '../../utils/steps';

import {
    test_runner,
} from '../../utils/runner';

import { putLargeVersion, fetchVersion } from '../../utils/versions';
import { putLocalVersion } from '../../utils/local_db';

class VersionTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: {},
            tests: [],
            selected_test_id: null,
        }
        this.updateTestDetail = this.updateTestDetail.bind(this);
        this.updateTestList = this.updateTestList.bind(this);
        this.runner = this.runner.bind(this);
        this.runTests = this.runTests.bind(this);
        this.runTest = this.runTest.bind(this);
        this.viewTest = this.viewTest.bind(this);
        this.fixTests = this.fixTests.bind(this);
        this.createTest = this.createTest.bind(this);
        this.uploadTests = this.uploadTests.bind(this);
        this.deleteTest = this.deleteTest.bind(this);
        this.refreshVersion = this.refreshVersion.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    async componentDidMount() {
        await this.refreshVersion();
    }

    async refreshVersion() {
        this.setState({ isFetching: true });
        const id = getId(this.props.history);

        await this.props.swallow_tests_get({ id });

        const { selected: tests_json = {} } = this.props.swallow_tests;
        const { tests = [], config: tests_config = {}, saved: tests_saved = [] } = tests_json;

        const version = await fetchVersion({ version_reference: id });

        this.setState({
            version_reference: id,
            tests,
            tests_config,
            tests_saved,
            version,
            isFetching: false,
        });
    }

    async saveVersion({ to_cloud = false} = {}) {
        const { tests, tests_config, tests_saved, version, version_reference } = this.state;
        const project = {
            ...version,
            tests,
        };
        await this.props.swallow_tests_post_some({
            id: version_reference,
            data: {
                tests,
                config: tests_config,
                saved: tests_saved,
            },
        });
        await putLocalVersion({ version_reference, version: project })
        if (to_cloud) await putLargeVersion({ version_reference, version: project });
    }

    createTest() {
        const { tests, version } = this.state;

        // Sets inputs to defaults
        const inputs = getStep('input').output({ step: version.input });
        const id = unique_id();
        const new_test = {
            id,
            name: '',
            key: 'new_test',
            input: parseFormattedOutput(inputs),
            output: {
                result: 0,
                valid: true,
            },
        };
        this.setState({
            tests: [ new_test, ...tests ],
            selected_test_id: id,
        })
        
    }

    async uploadTests(new_tests) {
        this.setState({
            tests: new_tests,
            selected_test_id: null,
        }, async () => {
            await this.saveVersion();
        })
        
    }

    async viewTest (test) {
        if (!test) {
            this.setState({
                selected_test_id: null,
            });
        } else {
            this.setState({
                selected_test_id: test.id,
            });
        }
    }

    async deleteTest (test_id) {
        const { tests } = this.state;
        this.setState({ selected_test_id: null });
        this.setState({
            tests: tests.filter(t => t.id !== test_id),
        }, async() => {
            await this.saveVersion();
        });
    }

    async fixTests () {
        const { tests } = this.state;
        this.setState({
            tests: tests.map(t => {
                return {
                    ...t,
                    output: {
                        result: t.result.result,
                        valid: t.result.valid,
                    }
                }
            })
        }, () => {
            this.saveVersion();
        });
    }

    async updateTestDetail (data) {
        const { tests } = this.state;
        const new_tests = tests.map(t => {
            if (t.id === data.id) {
                return data;
            }
            return t;
        });

        await this.setState({ 
            tests: new_tests,
            selected_test_id: null,
        });
        
        await this.runTest(data);

        const result = await this.saveVersion();
        return result;
    }

    updateTestList ({ data }) {
        this.setState({ 
            tests: data.items
        }, () => {
            this.saveVersion();
        });
    }

    async runner(test, debug) {
        const { tests: all_tests, version } = this.state;

        const response = await test_runner({
            project: version,
            test,
            debug,
        });

        const new_tests = all_tests.map(t => {
            if (t.id === test.id) {
                return response;
            }
            return t;
        });

        this.setState({
            tests: new_tests,
        });

        return response;
    }

    async runTest(run_test = {}, debug = true) {
        const result = await this.runner(run_test, debug);
        return result;
    }

    async runTests(run_tests = [], debug = false) {
        const { tests } = this.state;
        this.setState({ tests : tests.map(t => omit(t, ['result', 'debug'])) });
        for (const test of run_tests) {
            await this.runner(test, debug);
        }
    }

    render() {

        const { 
            tests = [],
            tests_saved = [], 
            tests_config = {},
            selected_test_id,
            version,
            isFetching,
        } = this.state;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} version={version.meta} auth={this.props.auth} />

                {isFetching && <GlobalLoading /> }

                {!isFetching && selected_test_id &&
                    <TestDetail
                        selected_test_id={selected_test_id}
                        tests={tests}
                        viewTest={this.viewTest}
                        deleteTest={this.deleteTest}
                        runTest={this.runTest}
                        project={version}
                        updateTests={this.updateTestDetail}
                        set_error={this.props.set_error}
                    />
                }

                {!isFetching && !selected_test_id &&
                    <Tests 
                        tests={tests}
                        tests_config={tests_config}
                        tests_saved={tests_saved}
                        runTest={this.runTest}
                        runTests={this.runTests}
                        viewTest={this.viewTest}
                        fixTests={this.fixTests}
                        createTest={this.createTest}
                        updateTests={this.updateTestList}
                        uploadTests={this.uploadTests}
                        project={version}
                    />
                }

            </div>
        );
    }
}

export default VersionTests;
import React, { Component } from 'react';

import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import VirtualisedTable from '../Layout/VirtualisedTable';
import { each, keys, get, mean, round, times, min, max } from 'lodash';
import dataTypeSelector from '../../utils/data_type_selector';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class TestsOverview extends Component {
    calculate(tests = []) {

        function aggregate_item ({ data = [], key = '', dps = 2, type }) {
            if (type === 'decimal' || type === 'integer') {
                const result = round(mean(data.map(t => t[key])), dps).toFixed(dps);
                if (isNaN(result)) return Number(0).toFixed(dps);
                return result;
            } else {
                //i.e. boolean
                return data.length;
            }
        }

        function aggregate_chart ({ data = [], key = '', type }) {
            if (type === 'decimal' || type === 'integer') {
                let result_data = [];
                const count = 20;
                const chart_data = data.map(d => d[key]);
                const min_value = min(chart_data);
                const max_value = max(chart_data);
                const range_size = Math.floor((max_value - min_value) / count);
                times(count, i => {
                    const result = chart_data.filter(d => {
                        return d >= (i * range_size) && d < ((i + 1) * range_size);
                    }).length;
                    result_data.push(result);
                });
                return result_data;
            } else {
                return [];
            }
        }

        function aggregate_group ({ data = {}, key = '', dps = 2, type }) {
            return {
                true: aggregate_item({ data: data.true, key, dps, type  }),
                false: aggregate_item({ data: data.false, key, dps, type }),
                total: aggregate_item({ data: data.total, key, dps, type  }),
                chart: aggregate_chart({ data: data.total, key, type})
            }
        }

        const outputs = tests.map(t => t.output);
        const additional_outputs = keys(outputs[0]).filter(o => !(o === 'result' || o === 'valid'));

        const cohorts = {
            true: outputs.filter(t => t.valid),
            false: outputs.filter(t => !t.valid),
            total: outputs,
        };

        let totals = {
            result: aggregate_group({ data: cohorts, key: 'result', dps: 2, type: 'decimal' }),
            validity: {
                true: cohorts.true.length,
                false: cohorts.false.length, 
                total: cohorts.total.length,
            },
            additional_outputs: [],
        }

        if (additional_outputs.length > 0) {
            additional_outputs.forEach(key => {
                const type = dataTypeSelector(outputs[0][key]);
                // TODO display dates and strings 
                if (type === 'integer' || type === 'decimal' || type === 'boolean') {
                    totals.additional_outputs.push({
                        name: key,
                        type,
                        data: aggregate_group({ data: cohorts, key, dps: 2, type }),
                    });
                }
            });
        }

        return totals;
    }

    renderAggregate({ data, name, type }) {
        if (type === 'decimal' || type === 'integer') {
            return (
                <div key={name} className="review-block review-test-section">
                    <p className="test-title">{capitalizeFirstLetter(name)}</p>
                    <h4>{data.total}</h4>
                    <ul>
                        <li>{data.true} <span className="button small tertiary">TRUE</span></li>
                        <li>{data.false} <span className="button small error">FALSE</span></li>
                    </ul>
                    <div className="review-test-chart">
                        <Sparklines 
                            data={data.chart} 
                            limit={data.chart.length}
                            height={20} 
                            margin={0}
                        >
                            <SparklinesReferenceLine 
                                type={'avg'} 
                                style={{ 
                                    stroke: 'grey', 
                                    strokeOpacity: .5, 
                                    strokeDasharray: '1, 1' 
                                }} 
                            />
                            <SparklinesLine style={{ 
                                fill: 'none', 
                                stroke: '#5bb3cf',
                                strokeWidth: '2'
                            }} />
                        </Sparklines>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="review-block review-test-section">
                    <p className="test-title">{capitalizeFirstLetter(name)}</p>
                    <h4>{round((data.true / data.total) * 100, 2)}%</h4>
                    <ul>
                        <li>{data.true} <span className="button small tertiary no-hover">TRUE</span></li>
                        <li>{data.false} <span className="button small error no-hover">FALSE</span></li>
                        <li>{data.total} <span className="button small black no-hover">TOTAL</span></li>
                    </ul>
                </div>
            )
        }
        
    }

    render() {
        const { tests = [] } = this.props;
        const totals = this.calculate(tests);

        const {
            result = {}, 
            validity = {},
            additional_outputs = [],
        } = totals;

        return [
            <div key={'tests-aggregate'} className="review-test-sections">
                {this.renderAggregate({ data: result, name: 'Result', type: 'decimal'})}
                {additional_outputs.map(ao => this.renderAggregate({ data: ao.data, name: ao.name, type: ao.type }))}
                {this.renderAggregate({ data: validity, name: 'Valid', type: 'boolean'})}
            </div>,
            <div key={'tests-table'} className="review-block">
                <VirtualisedTable 
                    items={tests}
                    height={300}
                    selectRow={this.props.selectRow}
                    map_data={(items) => {
                        const rows = [];
                        const item_output = get(items, '[0].output');
                        const columns = [{ key: 'name' }];

                        keys(item_output).forEach(item => {
                            let column = { key: item };
                            if (item === 'valid') {
                                column.render = (item) => {
                                    if (item.valid) {
                                        return (<button className="button small no-hover tertiary">TRUE</button>)
                                    } else {
                                        return (<button className="button small no-hover error">FALSE</button>)
                                    }
                                }
                            }
                            columns.push(column);
                        })
                        columns.push({ 
                            key: 'tags',
                            render: (item) => {
                                return (item.tags || []).map(i => {
                                    return (<button key={i} className="button small no-hover grey mr">{i}</button>);
                                })
                                
                            }
                        });

                        if (items) {
                            each(items, (v, k) => {
                                rows.push({
                                    name: v.name,
                                    ...v.output,
                                    tags: v.tags,
                                });
                            });
                        }
                    
                        return {
                            columns, 
                            rows,
                        };
                    }}
                />
            </div>
        ]
    }
}

export default TestsOverview;
import React, { Component } from 'react';

import TryArea from '../../components/Try/TryArea';

import { fetchVersion } from '../../utils/versions';

import { getId } from '../../../../utils/url';
import { each, get } from 'lodash';

function map_formats(formats = {}) {
    let arr = [];
    each(formats, (v, k) => {
        arr = [{
            key: k,
            ...v,
        }, ...arr]
    });
    return arr;
}

class ProjectTry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: [],
            isFetching: false,
        }
    }

    async componentDidMount() {
        await this.refreshVersion();
    }

    async refreshVersion() {
        this.setState({ isFetching: true });
        const id = getId(this.props.history);
        const result = await this.props.swallow_projects_get({ id });
        const { version_reference } = get(result, 'payload.data', {});
        const version = await fetchVersion({ version_reference });
        const { input } = version;
        this.setState({
            version,
            inputs: map_formats(input.format),
            isFetching: false,
        });
    }

    render() {
        const { 
            inputs,
            version,
            isFetching,
        } = this.state;

        return (
            <div className="swallow_app try-form">
                <div className="approved">
                    { !isFetching &&
                        <TryArea 
                            project={version}
                            inputs={inputs}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default ProjectTry;
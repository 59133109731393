/* eslint no-self-assign:0 */

import React, { Component } from 'react';
import { evaluateCalculations } from './CalculationTextarea';
import { isString, isBoolean, has } from 'lodash';

class CalculationTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: null,
        }
        this.testExpression = this.testExpression.bind(this);
    }

    testExpression () {
        const { inputs, formula, updateDefaultFromCalculation } = this.props;
        if (!formula) return;
        const result = evaluateCalculations({
            inputs,
            formula
        });
        this.setState({
            test: result,
        }, () => {
            if(!result.invalid) {
                if(updateDefaultFromCalculation) {
                    updateDefaultFromCalculation(result.result)
                }
            }
        })
    }

    render() {
        const { 
            test,
        } = this.state;

        let result;
        if (test && has(test, 'result')) {
            result = test.result;
            if (isBoolean(result)) {
                result = result.toString().toUpperCase();
            } else if (!isString(result)) {
                result = result.toString();
            } else{
                result = result;
            }
        }

        return (
            <div className="table-control-panel bottom">
                <div className="table-control-panel-left">
                    {test && !test.invalid &&
                        <div className="expression-debug">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Calculation Query</th>
                                        <th style={{ width: 200 }}>Result Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(test.elements || []).map((el, i) => {
                                        return (
                                            <tr key={`exp-${i}`}>
                                                <td><span className="variable">{el.query}</span></td>
                                                <td>{result}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr key={`exp-evaluated`} className="expression-evaluated">
                                        <td>Evaluated</td>
                                        <td>{ result }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    { test && test.invalid &&
                        <div className="expression-debug invalid">
                            <p>{ test.message }</p>
                        </div>
                    }
                </div>
                <div className="table-control-panel-right">
                    <button className="button secondary small"
                        onClick={this.testExpression}
                    > Test Calculation</button>
                </div>
            </div>
        );
    }
}

export default CalculationTest;
import React, { Component } from 'react';
import Logo from '../Logo';

import FormElementSelector from './FormElementSelector';
import ActualResult from '../Test/ActualResult';

import { test_runner } from '../../utils/runner';

import { isUndefined, find } from 'lodash';

class TryArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_running: false,
            section: 'form',
            quote: {},
            quote_errors: [],
            quote_result: null,
        }
        this.runQuote = this.runQuote.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.clearQuote = this.clearQuote.bind(this);
        this.updateQuote = this.updateQuote.bind(this);
        this.validate = this.validate.bind(this);
    }

    async validate() {
        const { inputs = [] } = this.props;
        let { quote } = this.state;
        quote = quote || {};
        let errors = [];

        inputs.forEach(input => {
            if (input.static) return;
            if(isUndefined(quote[input.key]) || quote[input.key] === ''){
                errors = [ input, ...errors ];
            }
        });
        await this.setState({
            quote_errors: errors,
        });
        return errors.length === 0;
    }

    async runQuote () {
        const { quote } = this.state;
        const { project } = this.props;

        const valid = await this.validate();

        if(valid) {
            this.setState({ is_running: true });
            const result = await test_runner({
                project: project,
                test: {
                    input: quote
                },
                debug: true,
            });
            this.setState({ 
                quote_result: result,
                is_running: false, 
            });
        }
    }

    updateQuote(data) {
        const { quote } = this.state;
        this.setState({
            quote: {
                ...quote,
                ...data,
            }
        });
    }

    clearQuote() {
        this.setState({
            quote: null,
            quote_result: {},
            quote_errors: [],
        });
    }

    setDefaults() {
        const { inputs } = this.props;
        const quote = {};
        inputs.forEach(input => {
            if (input.static) return;
            quote[input.key] = input.def;
        });
        this.setState({
            quote,
        }, () => this.validate());
    }

    render() {
        const { inputs = [] } = this.props;

        let { 
            is_running,
            quote_result,
            quote_errors,
            quote,
            section,
        } = this.state;

        quote = quote || {};

        return (
            <div className="try-area">
                <div className="try-area-left">
                    <Logo />
                </div>
                <div className="try-area-center">
                    <ul className="try-area-nav-buttons">
                        <li onClick={()=> {
                            this.setState({
                                section: 'form',
                            })
                        }} className={`${ section === 'form' ? 'active' : '' }`}>Form</li>
                        <li onClick={()=> {
                            this.setState({
                                section: 'api',
                            }, () => {
                                this.setDefaults();
                            })
                        }} className={`${ section === 'api' ? 'active' : '' }`}>API</li>
                        <li onClick={()=> {
                        }}>Chatbot</li>
                    </ul>
                    <div className="try-area-content">

                        { section === 'form' &&
                            <div className="try-area-form">
                                <div className="try-area-form-buttons">
                                    <button className="button grey small mr" onClick={this.clearQuote}>Clear Quote</button>
                                    <button className="button grey small mr" onClick={this.setDefaults}>Set Defaults</button>
                                    <button className="button secondary small" onClick={this.runQuote}>Run Quote</button>
                                </div>
                                <form>
                                    {inputs.filter(i => !i.static).map(input => {
                                        const { type, key, label } = input;
                                        const error = find(quote_errors, e => e.key === key);
                                        return (
                                            <FormElementSelector 
                                                key={key}
                                                error={error}
                                                quote={quote}
                                                type={type}
                                                form_key={key}
                                                label={label}
                                                updateQuote={this.updateQuote}
                                            />
                                        )
                                    })}
                                </form>
                                <div className="try-area-form-buttons">
                                    <button className="button secondary small" onClick={this.runQuote}>Run Quote</button>
                                </div>
                            </div>
                        }

                        { section === 'api' &&
                            <div className="try-area-api">
                                <fieldset className={'spacing'}>
                                    <label>URL <em>(POST)</em></label>
                                    <input 
                                        disabled={true}
                                        value={'https://swa.llow.io/quotes/098ae4f2-3af8-4740-8760-5d312e46742c?version_reference=6392bd76-6fca-47fc-ba03-d8e12c0c1156'}
                                    />
                                    <button className="secondary button small copy">copy</button>
                                </fieldset>
                                <fieldset className={'spacing'}>
                                    <label>Client ID Key</label>
                                    <input 
                                        disabled={true}
                                        value={'098ae4f2-3af8-4740-8760-5d312e46742c'}
                                    />
                                    <button className="secondary button small copy">copy</button>
                                </fieldset>
                                <fieldset className={'spacing'}>
                                    <label>Client Secret Key</label>
                                    <input 
                                        disabled={true}
                                        value={'098ae4f2-3af8-4740-8760-5d312e46742c'}
                                    />
                                    <button className="secondary button small copy">Copy</button>
                                </fieldset>
                                <div className="code-block">
                                    <label>Example Request</label>
                                    <textarea 
                                        value={JSON.stringify(quote, null, 4)}
                                    />
                                    <button className="button grey small reset" onClick={this.setDefaults}>Set Defaults</button>
                                    <button className="secondary button small copy" onClick={this.runQuote}>Run Quote</button>
                                </div>
                                <div className="code-block">
                                    <label>Example CURL</label>
                                    <textarea value={`curl --request POST --url https://api.bybits.co.uk/auth/users --header 'content-type: application/json' --header 'environment: mock' --header 'client_secret: 11111-22222-33333-44444' --header 'client_id: 11111-22222-33333-44444' --data '${JSON.stringify(quote)}'`} />
                                    <button className="secondary button small copy">Copy</button>
                                </div>
                            </div>
                        }
                        
                        <div className="try-area-chatbot"></div>
                    </div>
                    <p className="powered_by">Powered by <a href="https://swa.llow.io">Swallow</a></p>
                </div>
                <div className="try-area-right">
                    {!is_running && quote_result && 
                        <div className="edit-inputs">
                            <ActualResult
                                quote={quote_result}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            /> 
                        </div>
                    }

                    {!quote_result && !quote_result && 
                        <div className="edit-inputs">
                            <ActualResult
                                quote={{
                                    result: { result: 0.00 },
                                    debug: {},
                                }}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TryArea;
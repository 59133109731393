import React, { Component } from 'react';
import { find } from 'lodash';

import {
    projectSchema,
    outputSchema,
    inputSchema,
    stepTransformSchema,
    stepExcessesSchema,
    stepEndorsementsSchema,
    stepExclusionSchema,
    stepReferSchema,
    stepLabelSchema,
    stepLinksSchema,
    stepExternalSchema,
    stepFactorsSchema,
    evaluateSchema,
    stepCalculationSchema,
} from '@swa_llow/pricing_engine';

import CommonEdit from './Edit/Common';

import Details from './Edit/Shared/Sidebar/Details';
import Inputs from './Edit/Shared/Sidebar/Inputs';
import APISource from './Edit/Shared/Sidebar/APISource';

import { getProgressiveInput } from '../../utils/steps';
import { error_response } from '../../utils/errors';

// --- Define Each Step Editor
// ---
import InputEdit from './Edit/Input/Edit';
import OutputEdit from './Edit/Output/Edit';
import TransformEdit from './Edit/Transform/Edit';
import CalculationEdit from './Edit/Calculation/Edit';
import ExcessesEdit from './Edit/Excesses/Edit';
import ExclusionsEdit from './Edit/Exclusions/Edit';
import RefersEdit from './Edit/Refers/Edit';
import LabelEdit from './Edit/Label/Edit';
import EndorsementsEdit from './Edit/Endorsements/Edit';
import LinksEdit from './Edit/Links/Edit';
import FactorsEdit from './Edit/Factors/Edit';

import { parseSource } from '../../utils/api_source';

const mapSchema = (step) => {
    const map = {
        'output': outputSchema,
        'input': inputSchema,
        'transform': stepTransformSchema,
        'calculation': stepCalculationSchema,
        'excesses' : stepExcessesSchema,
        'endorsements' : stepEndorsementsSchema,
        'exclusion' : stepExclusionSchema,
        'refer' : stepReferSchema,
        'label' : stepLabelSchema,
        'links' : stepLinksSchema,
        'external': stepExternalSchema,
        'factors': stepFactorsSchema,
        'common': projectSchema,
    }
    return map[step] || map['common'];
}

const mapComponent = (step) => {
    const map = {
        'output': OutputEdit,
        'input': InputEdit,
        'transform': TransformEdit,
        'calculation': CalculationEdit,
        'excesses': ExcessesEdit,
        'endorsements' : EndorsementsEdit,
        'exclusion' : ExclusionsEdit,
        'refer' : RefersEdit,
        'label' : LabelEdit,
        'links' : LinksEdit,
        'external': CommonEdit,
        'factors': FactorsEdit,
        'common': CommonEdit,
    }
    return map[step] || map['common'];
}
//---
//---

class EditArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_config: false,
            step: {},
            inputs: {},
            errors: [],
        }
        this.updateInputValue = this.updateInputValue.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.overwriteStep = this.overwriteStep.bind(this);
        this.validate = this.validate.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.showConfig = this.showConfig.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { project, selected_step_id } = this.props;
        let step = find(this.props.steps, s => s.id === selected_step_id);

        // Input and Output are outside steps
        if (selected_step_id === 'input') {
            step = project.input;
            return this.setState({
                step: {
                    ...step,
                    key: 'input',
                    name: 'Input',
                    step: 'input',
                    id: 'input',
                },
                inputs: parseSource(step.source),
            });
        }

        if (selected_step_id === 'output') {
            step = project.output;
            step = {
                ...step,
                key: 'output',
                name: 'Output',
                step: 'output',
                id: 'output',
            };
        }

        const inputs = getProgressiveInput({ step_key:step.key, project });
        this.setState({
            step,
            inputs,
        });
    }

    showConfig() {
        this.setState({
            show_config: !this.state.show_config,
        })
    }

    updateInputValue(input) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                ...input,
            }
        });
    }

    updateStep(step) {

        if (step.id === 'input') {
            this.setState({
                input: step,
            });
        }

        if (step.id === 'output') {
            this.setState({
                output: step,
            });
        }

        const new_step = {
            ...this.state.step,
            ...step
        };

        this.setState({
            step: new_step,
        });
    }

    overwriteStep(step) {
        this.setState({
            step,
        });
    }

    validate(step) {
        this.props.set_error(null);

        const result = evaluateSchema({ 
            project: step,
            schema: mapSchema(step.step), 
        });

        if (!result.valid) {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Step',
            }));
            this.props.set_error(errors[0]);
            return result.valid;
        }

        return true;
    }

    async updateComponent() {
        const { step } = this.state;
        if(!this.validate(step)) return;
        await this.props.updateComponent({
            id: step.id,
            data: step,
            input: step.id === 'input' ? step : null,
            output: step.id === 'output' ? step : null,
        });
    }

    render() {

        const { inputs, step, errors, show_config } = this.state;
        const { meta } = this.props.project;

        const {
            status = '',
        } = meta;

        const EditComponent = mapComponent(step.step);
        const editSchema = mapSchema(step.step);

        const is_approved = (status === 'published' || status === 'approved');

        return (
            <section className="main-content slide-left">

                <div className="design-button-area">
                    <div className="design-button-area-left">
                        
                        <button className="button grey big mr" onClick={async () => {
                            if(this.validate(step)) {
                                this.updateComponent();
                            } else {
                                this.props.set_error(null);
                                this.props.editComponent(null);
                            }
                        }}>&lt; Go Back To Designer</button>

                        <button className="button secondary big" onClick={this.showConfig}>Show Config</button>

                    </div>

                    {!is_approved &&
                        <div className="design-button-area-right">

                            {!(step.id === 'input' || step.id === 'output') &&
                                <button onClick={() => {
                                    this.props.deleteComponent(step.id)
                                }} className="button grey big icon mr">
                                    <span className="cross"></span>Delete Step
                                </button>
                            }

                            <button onClick={() => {
                                this.updateComponent();
                            }} className="button secondary big">
                                Save Step
                            </button>

                        </div>
                    }

                    {is_approved && 
                        <div className="design-button-area-right">
                            
                            <button className="button grey big no-hover">
                                Already Approved
                            </button>
                        </div>
                    }
                    
                </div>

                <div className={`edit-area ${is_approved ? 'approved' : ''}`}>
                    <div className="edit-area-left" style={{flex: 1}}>

                        {!(step.id === 'input' || step.id === 'output') && 
                            <Details 
                                step={step}
                                errors={errors}
                                updateStep={this.updateStep}
                            />
                        }

                        {step.id !== 'input' &&  
                            <div className="edit-inputs">
                                <Inputs 
                                    id={step.id}
                                    inputs={inputs}
                                    updateInputValue={this.updateInputValue}
                                />
                            </div>
                        }

                        {step.id === 'input' && 
                            <APISource
                                updateStep={this.updateStep}
                                updateInputValue={this.updateInputValue}
                                source={step.source}
                            />
                        }

                    </div>

                    <div className="edit-area-center" style={{paddingRight: '0', flex: 2}}>

                        {show_config &&
                            <CommonEdit 
                                schema={editSchema}
                                key={step.id}
                                errors={errors}
                                step={step}
                                inputs={inputs}
                                updateStep={this.updateStep}
                                overwriteStep={this.overwriteStep}
                                set_error={this.props.set_error}
                                is_approved={is_approved}
                            />
                        }

                        <div className="edit-area-canvas">
                            <div className="edit-form">
                                <EditComponent
                                    schema={editSchema}
                                    key={step.id}
                                    errors={errors}
                                    step={step}
                                    inputs={inputs}
                                    updateStep={this.updateStep}
                                    overwriteStep={this.overwriteStep}
                                    set_error={this.props.set_error}
                                    is_approved={is_approved}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="edit-area-right" style={{display: 'none'}}></div>
                </div>
            </section>
        );
    }
}

export default EditArea;
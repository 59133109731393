import React, { Component } from 'react';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import { getId } from '../../../../utils/url';

import moment from 'moment';
import { omit } from 'lodash';

import Tasks from '../../components/Review/Tasks';
import TeamTable from '../../components/Review/TeamTable';
import Comments from '../../components/Review/Comments';
import Block from '../../components/Review/Block';
import Assets from '../../components/Review/Assets';
import NoAccess from '../../components/NoAccess';

import { Link } from 'react-router-dom';

import axios from 'axios';
import config from '../../../../config';

class Version extends Component {
    constructor(props){
        super(props);
        this.state = {
            edit_mode: false,
            viewer_roles: [],
        };
        this.save_process = this.save_process.bind(this);
        this.edit_mode = this.edit_mode.bind(this);
        this.put_signed_url_s3 = this.put_signed_url_s3.bind(this);
        this.get_signed_url_s3 = this.get_signed_url_s3.bind(this);
        this.post_action = this.post_action.bind(this);
        this.put_action = this.put_action.bind(this);
        this.delete_action = this.delete_action.bind(this);
        this.complete_version = this.complete_version.bind(this);
        this.delete_version = this.delete_version.bind(this);
    }

    async UNSAFE_componentWillMount() {
        const id = getId(this.props.history);
        await this.props.swallow_process_get({
            id,
        });

        // Decide on what user can see
        const { selected: process = {} } = this.props.swallow_process;
        const { selected: user = {} } = this.props.auth;

        let viewing_user = (process.team || []).filter(t => {
            return t.user_reference === user.user_reference;
        })[0];
        
        let roles = [];

        if (!viewing_user) {
            if (user.is_admin) {
                roles = ['read', 'write']; 
            }
        } else {
            roles = viewing_user.roles;
        }

        this.setState({ viewer_roles: roles })

        await this.props.swallow_actions_get_all({
            version_reference: id,
        });

        //Set to edit more if in URL
        const edit_mode = new URLSearchParams(window.location.search).get('edit_mode');
        if (edit_mode) this.setState({ edit_mode: true })
    }

    async put_signed_url_s3 ({ data, name, type = 'application/octet-stream; charset=binary', size }) {
        const { token } = this.props.token;
        const { selected } = this.props.swallow_process;
        const { version, assets } = selected;
        const { version_reference } = version;
        const { signed_url } = await axios({
            url: `${config.root}/sw/process/assets/${version_reference}?file=${name}&type=${type}`,
            method: 'put',
            headers: {
                authorization: `Bearer ${token}`,
            },
        }).then(({data}) => data);
    
        await axios({
            url: signed_url,
            method: 'put',
            header: {
                'Content-Type': type,
            },
            data,
        }).then(({data}) => data);

        this.save_process({
            assets: [{
                file: name,
                type,
                size,
                date: new Date().toISOString(),
            }, ...assets]
        })
    }

    async complete_version () {
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference, project_reference } = version;

        await this.props.swallow_actions_post({
            data: {
                content: '',
                version_reference,
                project_reference,
                type: 'completed',
            }
        });

        await this.props.swallow_versions_put({
            id: version_reference,
            data: {
                status: 'approved',
            }
        });

        this.props.history.push(`/project/${project_reference}`)
    }

    async delete_version () {
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference, project_reference } = version;

        await this.props.swallow_versions_delete({
            id: version_reference,
        });

        this.props.history.push(`/project/${project_reference}`)
    }

    async get_signed_url_s3 ({ name, type }) {
        const { token } = this.props.token;
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference } = version;

        const { signed_url } = await axios({
            url: `${config.root}/sw/process/assets/${version_reference}?file=${name}&type=${type}`,
            method: 'get',
            headers: {
                authorization: `Bearer ${token}`,
            },
        }).then(({data}) => data);
    
       return signed_url;
    }

    async post_action({ type, content, reply_to = null }) {
        const { selected } = this.props.swallow_process;
        const { version } = selected;
        const { version_reference, project_reference } = version;
        if (content === '' && type === 'comment') return;
        await this.props.swallow_actions_post({
            data: {
                content,
                version_reference,
                project_reference,
                type,
                mentions: [],
                reply_to,
            }
        })
    }

    async put_action({ action_reference, content, type}) {
        await this.props.swallow_actions_put({
            id: action_reference,
            data: {
                content,
                type,
            }
        })
    }

    async delete_action({ action_reference }) {
        await this.props.swallow_actions_delete({
            id: action_reference,
        })
    }

    show_approval_button({ rules, tasks, user, team, status }) {
        const { number_of_approvals = 1, tasks_completed = false } = rules;
        const pass_approvals = team.filter(a => a.approval).length >= number_of_approvals;
        const done_tasks = tasks.filter(t => t.user_reference).length;
        const pass_tasks = !tasks_completed || done_tasks === tasks.length;
        const pass_user = (this.state.viewer_roles || []).includes('approve') || user.is_admin;
        return pass_tasks && pass_approvals && pass_user && status === 'draft';
    }

    renderLatestStatus ({ status = '', version_number }) {
        const map = {
            draft: 'white',
            approved: 'tertiary',
            published: 'tertiary',
        };
        const cn = map[status] || 'grey';
        return (<button className={`button no-hover ${cn} small`}>{status.toUpperCase()} #{version_number}</button>);
    }

    async save_process (payload, action) {
        const { selected } = this.props.swallow_process;
        const { version_reference } = selected.version;

        if (action) {
            this.post_action(action)
        }

        const data = {
            blocks: [],
            summary: {},
            team: [],
            assets: [],
            tasks: [],
            rules: {},
            ...omit(selected, 'version'),
            ...payload,
        };

        await this.props.swallow_process_post_some({
            id: version_reference,
            data,
        });
    }

    edit_mode() {
        this.setState({
            edit_mode: !this.state.edit_mode,
        })
    }

    render() {
        const { selected:process = {}, isFetching: isFetchingProcess = true } = this.props.swallow_process;
        const { data:actions = [], isFetching: isFetchingActions = true } = this.props.swallow_actions;
        const { selected: user = {}} = this.props.auth;

        const { 
            version = {},
            team = [],
            assets = [],
            tasks = [],
            blocks = [],
            summary = {},
            rules = {},
        } = process;

        const { 
            version_reference, 
            version: version_number,
            status,
            created_at,
            approved_at,
        } = version;

        const {
            content:summary_content = '',
        } = summary;

        const { 
            edit_mode,
            viewer_roles = [],
        } = this.state;

        const show_edit_mode_button = viewer_roles.includes('write');
        
        if (!isFetchingProcess && viewer_roles.length === 0) {
            return (
                <div className="swallow_app slide-left">
                    <TopNav history={this.props.history} version={version} auth={this.props.auth} />
                    <NoAccess team={team} />
                </div>
            )
        }

        return (
            <div className="swallow_app slide-left">
                <TopNav history={this.props.history} version={version} auth={this.props.auth} />

                <div className="main-content-nav">
                    <div className="main-content-nav-left"></div>
                    {status === 'draft' &&
                        <div className="main-content-nav-right">
                            {show_edit_mode_button && !edit_mode && <button className="button big secondary" onClick={this.edit_mode}><i className="fa fa-cog"></i>Switch To Write Mode</button>}
                            {show_edit_mode_button && edit_mode && <button className="button big secondary" onClick={this.edit_mode}><i className="fa fa-eye"></i>Switch To Read Mode</button>}
                        </div>
                    }
                    {status !== 'draft' &&
                        <div className="main-content-nav-right">
                            <button className="button big grey no-hover">Version Approved</button>
                        </div>
                    }

                </div>

                {!(isFetchingProcess && isFetchingActions) &&
                    <section className={`main-content ${edit_mode ? 'edit-mode' : ''}`}>

                        <div className="design-area">
                            <div className="design-area-left">

                                {this.show_approval_button({
                                    rules, 
                                    tasks, 
                                    user, 
                                    team,
                                    status,
                                }) &&
                                    <div className="review-side-section">
                                        <button onClick={this.complete_version} className="button big tertiary">
                                            <i className='fa fa-circle-check'></i>
                                            Complete Approval of Version
                                        </button>
                                    </div>
                                }

                                <div className="review-side-section">
                                    <h4>Created</h4>
                                    <button className="button white small no-hover mr">
                                        {moment(created_at).format('lll')}
                                    </button>
                                    <button className="button white small no-hover">
                                        <i className='fa fa-clock'></i>
                                        {moment(created_at).fromNow()}
                                    </button>
                                </div>

                                <div className="review-side-section">
                                    <h4>Version</h4>

                                    <div style={{width: '100%'}}>
                                        {this.renderLatestStatus({ status, version_number })}
                                    </div>

                                    {status === 'approved' && 
                                        <button className="button small grey no-hover mr" style={{ marginBottom: 20}}>
                                            {moment(approved_at).format('lll')}
                                        </button>
                                    }

                                    {status === 'approved' &&         
                                        <button className="button grey small no-hover" style={{ marginBottom: 20}}>
                                            <i className='fa fa-clock'></i>
                                            {moment(created_at).fromNow()}
                                        </button>
                                    }                     
                                </div>

                                <div className="review-side-section">
                                    <h4>Version Reference</h4>
                                    <button className="button small white no-hover">
                                        {status === 'draft' &&<i className="fa fa-lock-open"></i>}
                                        {status !== 'draft' &&<i className="fa fa-lock"></i>}
                                        {version_reference}
                                    </button>

                                    <button className="button small error mr" style={{ marginTop: 20 }} onClick={this.delete_version}><i className="fa fa-circle-xmark"></i>Delete Version</button>
                   
                                </div>
                            </div>
                            <div className="design-area-center">

                                <div className="review-area-line"></div>

                                <div className="project with-label" style={{ marginTop: 0, marginBottom: edit_mode ? 140 : 0 }}>
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Version Summary
                                    </div>

                                    <div className="help-block">
                                        <i className="fa fa-circle-question"></i>
                                        <p>This is the change log summary. This should document the changes made to this version. Keep this short and to the point.</p>
                                    </div>

                                    <Block 
                                        html={summary_content}
                                        save_process={this.save_process}
                                        edit_mode={edit_mode}
                                        is_summary={true}
                                    />
                                    {edit_mode &&
                                        <div className="add-review-block button big secondary" onClick={() => {
                                            this.save_process({
                                                blocks: [{
                                                    title: 'New Title',
                                                    content: '<p>New Block</p>',
                                                }, ...blocks]
                                            })
                                        }}>
                                            <i className="fa fa-circle-plus"></i> Add Block
                                        </div>
                                    }
                                </div>

                                {blocks.map((b, i) => {
                                    const { title, content } = b;
                                    return (
                                        <div key={`block-${i}`} className="project with-label">
                                            <div className="project-label">
                                                { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                                {title}
                                            </div>

                                            <div className="help-block">
                                                <i className="fa fa-circle-question"></i>
                                                <p>This is an optional block of content. It is not in the change log. It can either be used to provide better context or to give extra instruction.</p>
                                            </div>

                                            <Block 
                                                title={title}
                                                html={content}
                                                save_process={this.save_process}
                                                edit_mode={edit_mode}
                                                is_summary={false}
                                                index={i}
                                                blocks={blocks}
                                            />
                                        </div>
                                    )
                                })}

                                <div className="project tasks with-label">
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Version Team
                                    </div>
                                    {edit_mode &&
                                        <div className="project-buttons">
                                            <Link to={`/version/team/${version_reference}`} className="button small secondary">Edit Team</Link>
                                        </div>
                                    }

                                    <div className="help-block">
                                        <i className="fa fa-circle-question"></i>
                                        <p>Assign team members for this version change. Assign the roles which grants access to read, write, download, approve or publish.</p>
                                    </div>

                                    <div className="review-block">
                                        <TeamTable 
                                            items={team}
                                            user={user}
                                            save_process={this.save_process}
                                            status={status}
                                        />
                                    </div>
                                </div>

                                <div className="project assets with-label">
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Version Assets
                                    </div>

                                    <div className="help-block">
                                        <i className="fa fa-circle-question"></i>
                                        <p>Upload assets associated with the version change. Data, models, documents, presentations etc. There is no size limit.</p>
                                    </div>

                                    <Assets 
                                        items={assets}
                                        put_signed_url_s3={this.put_signed_url_s3}
                                        get_signed_url_s3={this.get_signed_url_s3}
                                        save_process={this.save_process}
                                        edit_mode={edit_mode}
                                    />
                                </div>

                                <div className="project tasks with-label">
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Version Tasks
                                    </div>

                                    <div className="help-block">
                                        <i className="fa fa-circle-question"></i>
                                        <p>A checklist of tasks that need to be completed for this version change. They can be optional or mandatory.</p>
                                    </div>

                                    <Tasks
                                        items={tasks}
                                        user={user}
                                        edit_mode={edit_mode}
                                        save_process={this.save_process}
                                        status={status}
                                        team={team}
                                    />

                                </div>

                                {/* 
                                <div className="project tests with-label">
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Impact Tests
                                    </div>

                                    <div className="project-buttons">
                                        <button className="button small secondary mr">Download Tests</button>
                                        {edit_mode && <button className="button small secondary">Upload Tests</button>}
                                    </div>

                                    {edit_mode &&
                                        <div className="help-block">
                                            <i className="fa fa-circle-question"></i>
                                            <p>Upload impact test analysis .csv to show the effect of the proposed version change.</p>
                                        </div>
                                    }
                                    
                                    <Tests />
                                </div>
                                */}

                                <div className="project with-label comments">
                                    <div className="project-label">
                                        { status !== 'draft' && <i className='fa fa-lock' style={{marginRight: 10}}></i> }
                                        Version Comments
                                    </div>
                                    <Comments 
                                        comments={actions}
                                        team={team}
                                        user={user}
                                        post_action={this.post_action}
                                        put_action={this.put_action}
                                        delete_action={this.delete_action}
                                        status={status}
                                    />
                                </div>

                            </div>
                        </div>
                    </section>
                }

                <Footer history={this.props.history} />

            </div>
        );
    }
}

export default Version;
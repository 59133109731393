import React, { Component } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';

import Avatar from '../Layout/Avatar';
import Wysiwyg from './Wysiwyg';

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reply: null,
            edit: null,
        }
        this.reply = this.reply.bind(this);
        this.edit = this.edit.bind(this);
    }

    reply(index) {
        this.setState({
            edit: null,
            reply: index,
        });
    }

    edit(index) {
        this.setState({
            edit: index,
            reply: null
        });
    }

    render() {
        const { team = [], comments = [], user, status } = this.props;

        // Gets the actions in the right order
        let sorted_comment = [];
        const grouped = groupBy(comments, c => c.reply_to);
        comments.sort(c => moment(c.created_at)).filter(c => !c.reply_to).forEach(c => {
            sorted_comment = [...sorted_comment, c];
            if (grouped[c.action_reference]) {
                grouped[c.action_reference].sort(c => moment(c.created_at)).forEach(c => {
                    sorted_comment = [...sorted_comment, c];
                });
            }
        });

        const can_comment = team.map(t => t.user_reference).includes(user.user_reference);

        return [
            <div key={`comments-feed`} className={`comments-wrapper ${status === 'draft' ? '' : 'hide_buttons'}`}>
                { sorted_comment
                .map((c, i) => {
                    let new_c = { ...c };
                    if (i === this.state.reply) {
                        new_c = {
                            ...new_c,
                            reply : true,
                        }
                    }
                    if (i === this.state.edit) {
                        new_c = {
                            ...new_c,
                            edit : true,
                        }
                    }
                    return new_c;
                })
                .map((c, i) => {

                    const {
                        type,
                        content,
                        created_at: date,
                        user_reference,
                        reply_to,
                        reply, 
                        edit,
                        action_reference,
                    } = c;

                    const users = team.filter(t => t.user_reference === user_reference);

                    const [ user = {} ] = users;

                    const {
                        name = '',
                    } = user;

                    if (type === 'created') {
                        return (
                            <div key={`comment-${i}`} className="comment-wrapper start-comment">
                                <div className="comment-icon-wrapper">
                                    <div className="comment-icon-bar"></div>
                                    <div className="comment-icon">
                                        <i className="fa fa-circle-play"></i>
                                    </div>
                                </div>
                                <div className="comment-content review-block ">
                                    <div className="comment-content-header">
                                    </div>
                                    <p><b>New pricing version created</b></p>
                                    <div className="comment-content-footer">
                                        <Avatar name={name}/>
                                        {moment(date).format('lll')}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (type === 'comment') {

                        if (reply) {
                            return [
                                <div key={`comment-${i}`} className={`comment-wrapper`}>
                                    <div className={`comment-icon-wrapper`}>
                                        <div className="comment-icon-bar"></div>
                                        {!reply_to && <div className="comment-icon">
                                            <i className="fa fa-message"></i>
                                        </div>}
                                        {!reply_to && <div className="comment-age">{moment(date).fromNow()}</div>}
                                    </div>
                                    {reply_to && <div className="comment-reply">
                                        <i className="fa fa-right-long"></i>    
                                    </div>}
                                    <div className="comment-content review-block ">
                                        <div className="comment-content-header">
                                            {!reply_to && <button className="button small secondary mr" onClick={() => this.reply(i)}>Reply</button>}
                                            <button className="button small secondary mr">Edit</button>
                                            <button className="button small error mr" onClick={() => {
                                                this.props.put_action({
                                                    action_reference,
                                                    content: '<em class="delete">Message Deleted</em>',
                                                    type: 'comment',
                                                });
                                            }}>Delete</button>
                                        </div>
                                        <div className="comment-content-html" dangerouslySetInnerHTML={{__html: content }}></div>
                                        <div className="comment-content-footer">
                                            <Avatar name={name}/>
                                            {moment(date).format('lll')}
                                        </div>
                                    </div>
                                </div>,
                                <div className={`comment-wrapper`}>
                                    <div className={`comment-icon-wrapper`}>
                                        <div className="comment-icon-bar"></div>
                                    </div>
                                    <div className="comment-reply"></div>
                                    <div className="comment-content review-block ">
                                        <Wysiwyg 
                                            value={''}
                                            save={(value) => {
                                                this.props.post_action({
                                                    type: 'comment',
                                                    content: value,
                                                    reply_to: action_reference,
                                                });
                                                this.setState({ reply: null });
                                            }}
                                            button_text={'Reply to Comment'}
                                            height={200}
                                        />
                                    </div>
                                </div>
                            ]
                        }

                        if (edit) {
                            return (
                                <div key={`comment-${i}`} className={`comment-wrapper`}>                     
                                    <div className={`comment-icon-wrapper`}>
                                        <div className="comment-icon-bar"></div>
                                        {!reply_to && 
                                            <div className="comment-icon">
                                                <i className="fa fa-message"></i>
                                            </div>
                                        }
                                        {!reply_to && <div className="comment-age">{moment(date).fromNow()}</div>}
                                    </div>
                                    {reply_to && 
                                        <div className="comment-reply">
                                            <i className="fa fa-right-long"></i> 
                                        </div>
                                    }
                                    <div className="comment-content review-block ">
                                        <Wysiwyg 
                                            value={content}
                                            save={(value) => {
                                                this.props.put_action({
                                                    action_reference,
                                                    content: value,
                                                    type: 'comment',
                                                });
                                                this.setState({ edit: null })
                                            }}
                                            button_text={'Edit Comment'}
                                            height={200}
                                        />
                                    </div>
                                </div>
                            )
                        }

                        return (
                            <div key={`comment-${i}`} className={`comment-wrapper`}>                     
                                <div className={`comment-icon-wrapper`}>
                                    <div className="comment-icon-bar"></div>
                                    {!reply_to && 
                                        <div className="comment-icon">
                                            <i className="fa fa-message"></i>
                                        </div>
                                    }
                                    {!reply_to && <div className="comment-age">{moment(date).fromNow()}</div>}
                                </div>
                                {reply_to && 
                                    <div className="comment-reply">
                                        <i className="fa fa-right-long"></i> 
                                    </div>
                                }
                                <div className="comment-content review-block ">
                                    <div className="comment-content-header">
                                        {!reply_to && <button className="button small secondary mr" onClick={() => this.reply(i)}>Reply</button>}
                                        <button className="button small secondary mr" onClick={() => this.edit(i)}>Edit</button>
                                        <button className="button small error mr" onClick={() => {
                                            this.props.put_action({
                                                action_reference,
                                                content: '<em class="delete">Message Deleted</em>',
                                                type: 'comment',
                                            });
                                        }}>Delete</button>
                                    </div>
                                    <div className="comment-content-html" dangerouslySetInnerHTML={{__html: content }}></div>
                                    <div className="comment-content-footer">
                                        <Avatar name={name}/>
                                        {moment(date).format('lll')}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (type === 'approved') {
                        return (
                            <div key={`comment-${i}`} className="comment-wrapper approval-comment">
                                <div className="comment-icon-wrapper">
                                    <div className="comment-icon-bar"></div>
                                    <div className="comment-icon">
                                        <i className="fa fa-circle-check"></i>
                                    </div>
                                    <div className="comment-age">{moment(date).fromNow()}</div>
                                </div>
                                <div className="comment-content review-block ">
                                    <div className="comment-content-header">
                                    </div>
                                    <p><b>Version approved</b></p>
                                    <div className="comment-content-footer">
                                        <Avatar name={name}/>
                                        {moment(date).format('lll')}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (type === 'success') {
                        return (
                            <div key={`comment-${i}`} className="comment-wrapper end-comment">
                                <div className="comment-icon-wrapper">
                                    <div className="comment-icon-bar"></div>
                                    <div className="comment-icon">
                                        <i className="fa fa-rocket"></i>
                                    </div>
                                    <div className="comment-age">{moment(date).fromNow()}</div>
                                </div>
                                <div className="comment-content review-block ">
                                    <div className="comment-content-header">
                                    </div>
                                    <p><b>Success! Version completed</b></p>
                                    <div className="comment-content-footer">
                                        <Avatar name={name}/>
                                        {moment(date).format('lll')}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    return (<div />)
                
                })}
            </div>,
            <div key={'wysiwyg-editor'} className="review-block-editor">
                {can_comment && status === 'draft' &&
                    <Wysiwyg 
                        value={''}
                        save={(value) => {
                            this.props.post_action({
                                type: 'comment',
                                content: value,
                            });
                        }}
                        button_text={'Add Comment'}
                        height={200}
                    />
                }
                {!can_comment && status === 'draft' &&
                    <div className="empty-table" style={{margin: '30px 0 30px'}}>
                        <i className="fa fa-warning"></i>
                        Need to be team member to comment
                    </div>
                }
            </div>
        ]
    }
}

export default Comments;
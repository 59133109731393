import React, { Component } from 'react';
import { each, find, omit } from 'lodash';

import Inputs from '../Design/Edit/Shared/Sidebar/Inputs';
import ActualResult from './ActualResult';

import dataTypeSelector from '../../utils/data_type_selector'; 

import {
    parseFormattedOutput,
} from '../../utils/steps';

import {
    error_response
} from '../../utils/errors';

import {
    evaluateSchema,
    testsSchema,
} from '@swa_llow/pricing_engine'

import slugify from '../../utils/slugify';

class TestDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
            test: {
                inputs: {},
                outputs: {},
                mocks: {},
                tags: [],
                name: '',
                description: '',
                key: null,
            },
            is_running: false,
        }
        this.updateIsLogOpen = this.updateIsLogOpen.bind(this);
        this.refreshTest = this.refreshTest.bind(this);
        this.updateTest = this.updateTest.bind(this);
        this.updateInputs = this.updateInputs.bind(this);
        this.updateMocks = this.updateMocks.bind(this);
        this.updateOutputs = this.updateOutputs.bind(this);
        this.validate = this.validate.bind(this);
        this.formatTest = this.formatTest.bind(this);
        this.saveTest = this.saveTest.bind(this);
    }

    UNSAFE_componentWillMount () {
        this.refreshTest();
    }

    formatTest() {
        const { selected_test_id: id } = this.props;
        const { test } = this.state;
        const payload = {
            ...omit(test, ['inputs', 'outputs', 'debug', 'result']),
            id,
            output: parseFormattedOutput(test.outputs),
            input:  parseFormattedOutput(test.inputs),
        };
        return payload;
    }

    validate() {
        const payload = this.formatTest();

        this.props.set_error(null);
        
        const result = evaluateSchema({
            project: payload,
            schema: testsSchema,
        });

        if (!result.valid) {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Test',
            }));
            this.props.set_error(errors[0]);
            return result.valid;
        }

        return payload;
    }

    refreshTest(){
        const { tests, selected_test_id } = this.props;
        const test = find(tests, t => t.id === selected_test_id);

        let new_inputs = {};
        each(test.input || {}, (input, k) => {
            const type = dataTypeSelector(input);
            new_inputs = {
                ...new_inputs,
                [k]: {
                    type,
                    value: input,
                }
            }
        });

        let new_outputs = {};
        each(test.output || {}, (output, k) => {
            const type = dataTypeSelector(output);
            new_outputs = {
                ...new_outputs,
                [k]: {
                    type,
                    value: output,
                }
            }
        });

        let new_mocks = {};
        each(test.mocks || {}, (mock, k) => {
            const type = dataTypeSelector(mock);
            new_mocks = {
                ...new_mocks,
                [k]: {
                    type,
                    value: mock,
                }
            }
        })
        
        this.setState({
            test: {
                id: test.id,
                inputs:new_inputs,
                outputs: new_outputs,
                mocks: new_mocks,
                tags: test.tags,
                name: test.name,
                description: test.description,
                key: test.key,
                debug: test.debug,
                result: test.result,
            }
        });
    }

    updateIsLogOpen() {
        this.setState({
            is_logs_open: !this.state.is_logs_open,
        })
    }

    updateTest(data) {
        this.setState({
            test: {
                ...this.state.test,
                ...data
            }
        }, () => {
            this.validate();
        });
    }

    updateInputs(data) {
        this.setState({
            test: {
                ...this.state.test,
                inputs: {
                    ...this.state.test.inputs,
                    ...data,
                },
            }

        });
    }

    updateMocks(data) {
        this.setState({
            test: {
                ...this.state.test,
                mocks: {
                    ...this.state.test.mocks,
                    ...data,
                },
            }
        });
    }

    updateOutputs(data) {
        this.setState({
            test: {
                ...this.state.test,
                outputs: {
                    ...this.state.test.outputs,
                    ...data,
                },
            }
        });
    }

    async saveTest() {
        const result = this.validate();
        if (result) {
            this.props.updateTests(result);
        }
    }

    render() {
        const {
            is_logs_open = false,
            is_running = false,
            test,
        } = this.state;

        const {
            inputs,
            outputs,
            name,
            id,
            result,
            mocks = [],
            tags = [],
        } = test;

        const { project } = this.props;

        const {
            status = '',
        } = project.meta || {};

        const is_approved = (status === 'published' || status === 'approved');

        const payload = this.formatTest();

        return (
            <section key={id} className={`main-content test-edit-area slide-left ${is_approved ? 'approved' : ''}`}>
                <div className="design-button-area">
                    <div className="design-button-area-left">
                        <button className="button grey big" onClick={() => {
                            this.props.set_error(null);
                            this.props.viewTest(null);
                        }}>&lt; Go Back To Tests</button>
                    </div>
                    
                    <div className="design-button-area-right">
                        {!is_approved && 
                            <button onClick={() => {
                                this.props.deleteTest(id);
                            }} className="button grey big icon mr">
                                <span className="cross"></span>Delete Test
                            </button>
                        }

                        {!is_approved && 
                            <button onClick={() => {
                                this.updateOutputs({
                                    result: {
                                        value: result.result,
                                        type: outputs.result.type,
                                    },
                                    valid:  {
                                        value: result.valid,
                                        type: outputs.valid.type,
                                    }
                                });
                            }} className="button grey big mr">
                                Fix Test
                            </button>
                        }

                        <button onClick={async() => {
                            this.setState({ is_running: true });
                            await this.props.runTest(payload);
                            await this.refreshTest();
                            this.setState({ is_running: false });
                        }} className="button secondary big mr">
                            Run Test
                        </button>

                        {!is_approved && 
                            <button 
                                onClick={this.saveTest}
                                className="button secondary big">
                                Save Test
                            </button>
                        }
                    </div>
                </div>
                <div className={`edit-area`}>
                    <div className="test-area-left" style={{ display: is_logs_open ? 'none' : 'block' }}>
                        <h3>Test Details </h3>
                        <p>You can explain what the test does.</p>
                        
                        <div className="edit-inputs">
                            <form>
                                <fieldset className={'spacing'}>
                                    <label>Test Name</label>
                                    <input onChange={(e) => {
                                        this.updateTest({
                                            name: e.target.value,
                                            key: slugify(e.target.value),
                                        });
                                    }} value={name} />
                                </fieldset>
                            </form>
                        </div>

                        <div style={{height: '30px', width: '100%'}} />

                        <h3>Mocks</h3>
                        <p>The external API mocked inputs. This is used to stop outbound requests.</p>
                        <div className="edit-inputs">
                            <Inputs 
                                inputs={mocks}
                                updateInputValue={this.updateMocks}
                            />
                        </div>

                        <div style={{height: '30px', width: '100%'}} />

                        <h3>Tags</h3>
                        <p>This test is tagged with the following tabs that can be pivoting upon.</p>
                        <div className="edit-inputs">
                            {tags.map(t => {
                                return (
                                    <span className="button no-hover secondary mr small"><i className="fa fa-circle-xmark"></i> {t}</span>
                                )
                            })}
                        </div>

                    </div>

                    <div className="test-area-center" style={{ display: is_logs_open ? 'none' : 'block' }}>

                        <h3>Output</h3>
                        <p>The keys and values for the test output are defined here. Only result and valid parameters can be supplied.</p>
                        <div className="edit-inputs">
                            <Inputs 
                                inputs={outputs}
                                updateInputValue={this.updateOutputs}
                            />
                        </div>

                        <div style={{height: '30px', width: '100%'}} />

                        <h3>Inputs</h3>
                        <p>The inputs match the initial step inputs (i.e. what would be provided at quote).</p>
                        <div className="edit-inputs">
                            <Inputs 
                                inputs={inputs}
                                updateInputValue={this.updateInputs}
                            />
                        </div>

                    </div>

                    <div className="test-area-right">
                        <div className="edit-inputs"> 
                            <ActualResult 
                                quote={test}
                                is_running={is_running}
                                updateIsLogOpen={this.updateIsLogOpen}
                            />
                        </div>
                    </div>
                </div>
                
            </section>
        );
    }
}

export default TestDetail;
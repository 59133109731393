import React, { Component } from 'react';
import { evaluateExpressions } from '../Expression/ExpressionTextarea';
import { unique_id } from '../../../../../utils/unique_id';

import {
    evaluateSchema,
} from '@swa_llow/pricing_engine';

import {
    error_response
} from '../../../../../utils/errors';

import { isUndefined, omit } from 'lodash';

class ItemsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        }
        this.enrichWithDefault = this.enrichWithDefault.bind();
        this.updateItem = this.updateItem.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.validate = this.validate.bind(this);
        this.clearExpression = this.clearExpression.bind(this);
        this.updateItemFromTable = this.updateItemFromTable.bind(this);
    }

    updateItem (data) {
        const { selected } = this.state;

        const new_data = {
            ...selected,
            ...data,
        }

        this.setState({
            selected: new_data
        });
    }

    clearExpression () {
        this.setState({
            selected: null,
            test: null,
        });
    }

    selectItem (selected) {
        this.setState({
            selected,
            test: null,
        })
    }

    enrichWithDefault({
        items,
        step,
    }) {
        let new_items = items || [];
        
        if (['excesses', 'endorsements'].includes(step.step)) {
            let { def = [] } = step;
            new_items.forEach(ni => {
                if (!isUndefined(ni.new_def)) {
                    if (ni.new_def) {
                        def = [ ni.value, ...def ];
                    }
                } else {
                    if (def.includes(ni.value)) {
                        def = [ ni.value, ...def ];
                    }
                }
                return omit(ni, 'new_def');
            });
            return { 
                def,
                items:new_items,  
            }
        }

        if (['label'].includes(step.step)) {
            let def = step.def;
            new_items = new_items.map(ni => {
                if (ni.new_def) def = ni.value;
                return omit(ni, 'new_def');
            });
            return { 
                def,
                items:new_items, 
            }
        }

        // refer or exclusion
        return { items: new_items };
    }

    // This validates just the exclusion item
    validate(data) {
        const { step, inputs, schema } = this.props;

        this.props.set_error(null);

        const result = evaluateSchema({ 
            project: {
                ...step,
                ...data,
        }, schema  });

        if (!result.valid) {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Item',
            }));
            this.props.set_error(errors[0]);
            return result.valid;
        }

        let exp_errors = evaluateExpressions({
            inputs,
            items: data.items,
        }).filter(exp => exp.invalid);

        if (exp_errors.length > 0) {
            this.props.set_error({
                message : `Expression: ${exp_errors[0].message}`
            });
            return false;
        }

        return true;
    }

    async updateItems() {
        
        const { step, updateStep } = this.props;
        const { items = [] } = step;
        const { id = '' } = this.state.selected || {};

        let new_items = items;

        // New Item
        if (id === '') {
            new_items = [...items, {
                ...this.state.selected,
                id: unique_id(),
            }];
        // Update Item
        } else {
            new_items = items.map(item => {
                if (item.id === id) {
                    return this.state.selected;
                }
                return item;
            });
        }

        const payload = this.enrichWithDefault({
            step,
            items: new_items,
        });

        if(!this.validate(payload)) return;
        await updateStep(payload);
        this.clearExpression();
    }

    updateItemFromTable(new_items) {
        this.props.updateStep(new_items);
    }

    render() {
        return(<div></div>)
    }
};
export default ItemsEdit;
import React, { Component } from 'react';
import { chunk, round } from 'lodash';
import { external_runner } from '../../utils/runner';

class TestsExternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            time_left: 0,
            tests_done: [],
        }
        this.tests_runner = this.tests_runner.bind(this);
    }

    async tests_runner () {
        const { tests = [], tests_config = {} } = this.props;

        const { frequency = 1, timeout } = tests_config;
        const tests_todo = chunk(tests, frequency);

        this.setState({
            tests_done: [],
            tests_running: true,
        });

        for (const index in tests_todo) {
            const tests = tests_todo[index];

            const result = await Promise.all(
                tests.map(test => external_runner({
                    config: tests_config,
                    test,
                }))
            );

            const completed_tests = result.map(r => {
                if(!r.code) {
                    return { 
                        code: 200,
                        ...r,
                    }
                }
                return r;
            });

            const time_left = round((this.props.tests.length - this.state.tests_done.length) / (frequency / (timeout / 1000)), 0);
            const time_secs = time_left % 60;
            const time_mins = round(time_left / 60, 0)
            this.setState({
                time_left: `${time_mins} mins ${time_secs} secs`,
                tests_done: [
                    ...completed_tests, 
                    ...this.state.tests_done,
                ],
            });
        }

        this.setState({
            tests_running: false,
        });
    }

    render() {
        const { valid, tests_done, tests_running, time_left } = this.state;
        const { tests, tests_config = {} } = this.props;
        const formatted_config = JSON.stringify(tests_config, null, 4);

        return (
            <div className="review-test-sections">
                <div className="review-block review-test-section">
                    <div className="code-block">
                        <label>External API Config</label>
                        <textarea 
                            onChange={() => {}}
                            value={formatted_config}
                        />
                        {valid && <button className="button tertiary small validity">VALID</button>}
                        {!valid && <button className="button error small validity">INVALID</button>}
                        <button className="secondary button small" onClick={() => this.updateStep()}>Update Config</button>
                    </div>
                </div>
                <div className="review-block review-test-section external-test-runner">
                    
                    {(!tests_running && tests_done.length === 0) &&
                        <div className="external-test-runner-icons">
                            <button className="secondary button small" onClick={this.tests_runner}>
                                <i className="fa fa-circle-play"></i>Run External Tests
                            </button>
                        </div>
                    }

                    {(tests_running || tests_done.length > 0) && 
                        <div className="external-test-runner-icons">
                            {tests_running && <button className="white button small no-hover mr"><i className="fa fa-spinner fa-spin"></i> {time_left}</button>}
                            <button className="tertiary button small no-hover mr"><i className="fa fa-circle-check"></i>{tests_done.filter(t => t.code === 200).length}</button>
                            <button className="error button small no-hover mr"><i className="fa fa-circle-xmark"></i>{tests_done.filter(t => t.code !== 200).length}</button>
                            <button className="grey button small no-hover"><i className="fa fa-clock"></i>{tests.length - tests_done.length}</button>
                        </div>
                    }

                    {(tests_done.length > 0) &&
                        <ul className="tests-ran">
                            {tests_done.map(t => {
                                if (t.code === 200) {
                                    return (
                                        <li className={'pass'}>
                                            <b>{t.code}</b> {t.name} - <i>result: {t.result} valid: {t.valid}</i>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className={'fail'}>
                                            <b>{t.code}</b> {t.name} - {t.message}
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    }

                    {(!tests_running && tests_done.length > 0) && 
                        <div>
                            <button className="tertiary button small mr">
                                <i className="fa fa-angles-down"></i>Download Tests Results
                            </button>

                            <button className="tertiary button small mr">
                                <i className="fa fa-circle-play"></i>Run Tests Again
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TestsExternal;
import React, { Component } from 'react';

const templates = [{
    id: 'insurance',
    name: 'Insurance',
    owner: 'Swallow'
},{
    id: 'mortgages',
    name: 'Mortgages',
    owner: 'Swallow'
},{
    id: 'loans',
    name: 'Loans',
    owner: 'Swallow'
},{
    id: 'leasing',
    name: 'Leasing',
    owner: 'Swallow'
},{
    id: 'credit',
    name: 'Credit',
    owner: 'Swallow'
},{
    id: 'financing',
    name: 'Financing',
    owner: 'Swallow'
},{
    id: 'utilities',
    name: 'Utilities',
    owner: 'Swallow'
},{
    id: 'blank',
    name: 'Black',
    owner: 'Swallow'
}];

class ProjectCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            description: null,
            template: null,
        }
    }
    
    render () {
        const { name, description, template } = this.state;
        return (
            <div className="project" style={{ marginTop: '30px' }}>
                <div className="project-create-buttons">
                    <div className="project-create-buttons-left"></div>
                    <div className="project-create-buttons-right">
                        <button onClick={this.props.openProject} className="button big grey icon mr"><span className="cross"></span> Close</button>
                        <button onClick={() => {
                            this.props.createProject({
                                name,
                                description,
                            });
                        }}className="button big">Create Pricing Project</button>
                    </div>
                </div>
                <div className="project-create">
                    <div className="project-create-left">
                        <div className="project-box">
                            <h4>Pricing Project Details</h4>
                            <p>You can change the name or description at any time.</p>
                            <fieldset className={'spacing'}>
                                <label>Pricing Name</label>
                                <input 
                                    onChange={(e) => {
                                        this.setState({
                                            name: e.target.value,
                                        })
                                    }}
                                    value={name}
                                />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Pricing Description</label>
                                <textarea 
                                    onChange={(e) => {
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }}
                                    value={description}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className="project-create-right">
                        <div className="project-box">
                            <h4>Select Template</h4>
                            <div className="project-templates">
                                {templates.map(t => {
                                    const active = t.id === template;
                                    return (
                                        <div 
                                            key={t.id} 
                                            className={`project-template ${active ? 'active' : ''}`} 
                                            onClick={() => {
                                                this.setState({
                                                    template: t.id
                                                })
                                            }}
                                        >
                                            <h6>{t.name}</h6>
                                            <i className="fa fa-file-invoice"></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectCreate;
import React from 'react';
import Table from '../Layout/Table';

import moment from 'moment';

class DataTable extends Table {
    renderRow(data) {
        const {
            name,
            end_at,
            rows,
            version,
        } = data;

        const {
            selectedRows = [] 
        } = this.state;

        const {
            hide_checkboxes = false,
        } = this.props;

        const is_selected = selectedRows.includes(name);

        return (
            <tr key={name}>
                {!hide_checkboxes && <td>
                    <input checked={is_selected} type="checkbox" onChange={(e) => {
                        this.selectRow(name);
                    }}/>
                </td>}
                <td className="table-name">{ name }</td>
                <td>
                    {version}
                </td>
                <td>
                    {moment(end_at).format('DD MMM YY')}
                </td>
                <td>
                    {rows}
                </td>
                <td>
                    <button className="button small icon mr">
                        <span className="download"></span> Download CSV
                    </button>
                    <button className="button small icon">
                        <span className="download"></span> Download JSON
                    </button>
                </td>
            </tr>
        )
    }
}

export default DataTable;
import Dexie from 'dexie';
import moment from 'moment';

export const db = new Dexie('swallow_local_db');
db.version(1).stores({ versions: 'version_reference' });

export async function getAllLocalVersion() {
    try {
        const result = await db.versions.toArray();;
        return result;
    } catch(e) {
        console.log(e);
    }
}

export async function getLocalVersion({ version_reference }) {
    try {
        const result = await db.versions.get({
            version_reference,
        });
        return result;
    } catch(e) {
        console.log(e);
    }
}

export async function putLocalVersion({ version_reference, version, created_at  }) {
    try {
        const result = await db.versions.put({
            ...version,
            version_reference,
            created_at: created_at || moment().toISOString(), 
        });
        return result;
    } catch(e) {
        console.log(e);
    }
    
}

export async function getFreeSpace({ version_reference, version, created_at  }) {
    navigator.webkitPersistentStorage.queryUsageAndQuota (
        function(usedBytes, grantedBytes) {
            console.log('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
        },
        function(e) { console.log('Error', e);  }
    );
}
import React, { Component } from 'react';
import { steps } from '../../../utils/steps';
import slugify from '../../../utils/slugify';

function map_icon(step) {
    const map = {
        label: 'fa-tags',
        transform: 'fa-diagram-project',
        calculation: 'fa-calculator',
        exclusion: 'fa-circle-stop',
        excesses: 'fa-money-bills',
        refer: 'fa-user-nurse',
        endorsements: 'fa-ticket',
        external: 'fa-trowel-bricks',
        links: 'fa-database',
        factors: 'fa-scale-unbalanced',
    };
    return map[step] || 'fa-plus';
}

class StepEmpty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step_name: null,
            step_type: null,

        }
    }

    render() {
        const {
            id,
            updateComponent,
            editComponent,
            deleteComponent,
        } = this.props;

        const {
            step_name,
            step_type,
        } = this.state;

        if (step_type) {
            return (
                <div key={id} className="empty-module">
                    <h4>&nbsp;</h4>
                    <div className="component-delete">
                        <i onClick={() => deleteComponent(id)} className="fa fa-circle-xmark"></i>
                    </div>
                    <form>
                        <fieldset>
                            <input onKeyUp={(e) => {
                                this.setState({
                                    step_name: e.target.value,
                                })
                            }} type="text" />
                            <label>Enter Step Name</label>
                        </fieldset>
                        <button onClick={(e) => {
                            e.preventDefault();
                            const key = slugify(step_name);
                            updateComponent({
                                id,
                                data: {
                                    step: step_type,
                                    name: step_name,
                                    key,
                                    id,
                                },
                                empty: true,
                            });
                            editComponent(id);
                        }} className="button secondary small">+ Add</button>
                    </form>
                </div>
            );
        } else {
            return (
                <div key={id} className="empty-module">
                    <h4>Select Step</h4>
                    <div className="component-delete">
                        <i onClick={() => deleteComponent(id)} className="fa fa-circle-xmark"></i>
                    </div>
                    <ul>
                        {steps.filter(s => {
                            return !(s.step === 'input' || s.step === 'output');
                        }).map(t => {
                            let step_name = t.step;
                            if (t.step === 'links') step_name = 'Data Linking';
                            if (t.step === 'factors') step_name = 'Risk Factors';
                            if (t.step === 'external') step_name = 'APIs';
                            return (
                                <li key={`${t.id}-${t.step}`} onClick={() => {
                                    this.setState({
                                        step_type: t.step,
                                    });
                                }}>
                                    <div className="module-type-item">
                                        <div className="module-type-tab">
                                        </div>
                                        <div className="module-type-icon">
                                            <i className={`fa ${map_icon(t.step)}`}></i>
                                        </div>
                                        <div className="module-type-label">
                                            <h4>{step_name}</h4>
                                            <p>{t.description}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
    }
}

export default StepEmpty;
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import dataTypeSelector from '../../../../utils/data_type_selector';
import csv from 'csvtojson';
import { each, omit } from 'lodash';

class WithoutData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        }
        this.processCSV = this.processCSV.bind(this);
    }

    // TODO 
    // Validate CSV on way in
    // slug all column headers
    // Flag that input is not been previously set for key column but allow though
    async processCSV([file]) {
        const { step } = this.props;
        this.setState({ uploading: true });

        const result = await new Promise((resolve, reject) => {
            const results = [];
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const result = reader.result ? reader.result.toString() : '';
                csv()
                    .fromString(result)
                    .subscribe((json)=> {
                        results.push(json);
                    },
                    (e) => { reject(e) }, 
                    () => {
                        const [ first ] = results;
                        
                        let format = {}
                        each(first, (v, k) => {
                            format[k] = {
                                label: k,
                                type: dataTypeSelector(v),
                                def: v,
                            };
                        });
    
                        let links = {};
                        let exp;
                        results.forEach((r, i) => {
                            if (!exp) exp = '({{' + Object.keys(r)[0] + '}})';
                            const key = Object.values(r)[0];
                            links[key] = omit(r, key);
                        });
    
                        resolve({
                            exp,
                            format,
                            links,
                        })
                    });
            }
            reader.readAsText(file);
        }).catch(e => console.log(e));

        this.props.updateStep({
            ...step,
            ...result,
        });
    }

    //TODO make basic template for download
    async downloadEmptyCSV() {
    }

    render() {

        const { uploading } = this.state;

        return [
            <div key={`empty-buttons`} className="upload-buttons">
                <button className="button small grey no-hover mr">rows : 0</button>
                <button className="button small grey no-hover mr">columns : 0</button>
                <button className="button small secondary mr" onClick={this.downloadEmptyCSV}><i className="fa fa-angles-down"></i>Download Example CSV</button>
                { uploading && <i className="fa fa-spinner fa-spin"></i> }
            </div>,
            <div key={`empty-dropzone`} ref={'table'} className="upload-box">
                <Dropzone onDrop={acceptedFiles => this.processCSV(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => [
                        <div {...getRootProps()}>
                            <i className="fa fa-cloud-arrow-up"></i>
                            <p>Drop CSV of data to link. The first column should be the input property which you are matching on.</p>
                        </div>,
                        <input {...getInputProps()} />
                    ]}
                </Dropzone>
                {uploading && <div className="progress-bar"></div>}
            </div>
        ]

    }
}

export default WithoutData;
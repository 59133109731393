import React, { Component } from 'react';

import TestsOverview from './TestsOverview';
import TestsExternal from './TestsExternal';

import Dropzone from 'react-dropzone';
import csv from 'csvtojson';
import { omit, flatten, uniq } from 'lodash';

import { upload_csv, process_tests, download_tests } from '../../utils/csv';

class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_upload: this.props.tests.length === 0,
            uploading: false,
            new_tests: [],
            search_term: '',
            tag_term: '',
        }
        this.processCSV = this.processCSV.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.openUpload = this.openUpload.bind(this);
        this.filterTests = this.filterTests.bind(this);
    }

    openUpload() {
        this.setState({
            open_upload: !this.state.open_upload,
            new_tests: [],
        })
    }

    async downloadCSV() {
        const { tests } = this.props;
        download_tests({
            rows: tests,
            name: 'tests.csv',
        });
    }

    async processCSV([file]) {
        this.setState({ uploading: true });
        const result = await new Promise((resolve, reject) => {
            let results = [];
            let map = {};
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const result = reader.result ? reader.result.toString() : '';
                csv()
                    .fromString(result)
                    .subscribe((json)=> {
                        const obj = upload_csv({
                            json,
                            map,
                            needle: 'expected::result',
                        });
                        if (obj) results.push(omit(obj, 'tests'));
                    },
                    (e) => { reject(e) }, 
                    () => {
                        const mapped_results = process_tests({
                            results, 
                        });
                        resolve(mapped_results);
                    });
            }
            reader.readAsText(file);
        }).catch(e => console.log(e));
        this.setState({ uploading: false, open_upload: false, new_tests:result });
        await this.props.uploadTests(result);
    }

    filterTests(tests = []) {
        const { search_term, tag_term } = this.state;
        let filtered_tests = tests;

        if (search_term !== '' && search_term.length > 3) {
            const lc_search_term = search_term.toLowerCase();
            filtered_tests = filtered_tests.filter(test => {
                return test.key.includes(lc_search_term);
            });
        }

        if (tag_term !== '') {
            filtered_tests = filtered_tests.filter(test => (test.tags || []).includes(tag_term));
        }

        return filtered_tests;
    }

    render() {
        const { tests = [], project = {}, tests_saved = [], tests_config = {} } = this.props;
        const { uploading, new_tests, open_upload, search_term, tag_term } = this.state;

        const filtered_tests = this.filterTests(tests);
        const tags = uniq(flatten(tests.map(t => t.tags)));

        const {
            status = '',
        } = project.meta || {};

        const is_approved = (status === 'published' || status === 'approved');

        return (
            <section className="main-content slide-left">
                
                <div className="design-button-area">
                    <div className="design-button-area-left">

                        <button onClick={this.downloadCSV} className="button grey big mr">
                            <i className="fa fa-angles-down"></i> Download All Tests
                        </button>

                        <button onClick={this.openUpload} className="button grey big mr">
                            <i className="fa fa-angles-up"></i> Upload All Tests
                        </button>

                        {new_tests.length > 0 &&
                            <button onClick={this.props.saveVersion} className="button secondary big">
                                Save New Tests
                            </button>
                        }

                    </div>

                    {!is_approved && 
                        <div className="design-button-area-right">

                            <button onClick={() => {
                                this.props.fixTests();
                            }} className="button secondary big mr">
                                Fix All Tests
                            </button>

                            <button onClick={() => {
                                this.props.createTest();
                            }} className="button secondary big mr">
                                Create Test
                            </button>
                            
                            <button onClick={() => {
                                this.props.runTests(tests);
                            }} className="button secondary big icon">
                                <span className="play"></span>
                                Run All Tests
                            </button>

                        </div>
                    }

                    {is_approved && 
                        <div className="design-button-area-right">
                            <button className="button grey big no-hover">
                                Already Approved
                            </button>
                        </div>
                    }

                </div>

                <div className={`test-area ${is_approved ? 'approved' : ''}`}>

                    <div className="test-area-left">

                        {open_upload && <div className="project">
                            <div key={`empty-dropzone`} ref={'table'} className="upload-box" style={{ marginBottom: 10, height: 140, paddingTop: 50 }}>
                                <Dropzone onDrop={acceptedFiles => this.processCSV(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => [
                                        <div key={'empty-dropzone-child'} {...getRootProps()}>
                                            <i className="fa fa-cloud-arrow-up"></i>
                                            <p>Drag and Drop CSV of tests. The CSV schema should match the downloadable template <b><a href="/" id="docs">here</a></b></p>
                                        </div>,
                                        <input key={'empty-dropzone-input'} {...getInputProps()} />
                                    ]}
                                </Dropzone>
                                {uploading && <div className="progress-bar"></div>}
                            </div>
                        </div>
                        }

                        {tests.length > 0 && <div className="project">
                            <div className="search-block">

                                <div className="search-block-item">
                                    <fieldset className={'spacing'}>
                                        <label>Search Test Name</label>
                                        <input onChange={(e) => {
                                            this.setState({
                                                search_term: e.target.value,
                                            })
                                        }} value={search_term} />
                                    </fieldset>
                                </div>

                                <div className="search-block-item">
                                    <fieldset className={'spacing'}>
                                        <label>Search Tags</label>
                                        <select 
                                            value={tag_term} 
                                            onChange={(e) => {
                                                this.setState({
                                                    tag_term: e.target.value,
                                                });
                                            }
                                        }>
                                            <option value={''}></option>
                                            {tags.map(t => {
                                                return (<option key={t} value={t}>{t}</option>);
                                            })}
                                        </select>
                                    </fieldset>
                                </div>

                            </div>
                           
                        </div>
                        }

                        {tests.length > 0 && 
                        <div className="project" style={{ opacity: uploading ? 0.25: 1 }}> 
                            <TestsOverview 
                                tests={filtered_tests} 
                                height={600}
                                selectRow={this.props.viewTest}
                            />
                        </div>
                        }

                        {tests.length > 0 && 
                        <div className="project" style={{ opacity: uploading ? 0.25: 1 }}>                          
                            <TestsExternal 
                                tests={filtered_tests} 
                                tests_config={tests_config}
                                tests_saved={tests_saved}
                            />
                        </div>
                        }

                    </div>

                    <div className="test-area-right">
                    </div>

                </div>

            </section>
        );
    }
}

export default Tests;
import React, { Component } from 'react';
import ItemsEdit from '../Shared/Items/ItemsBooleanDefault';

import {
    stepReferSchema,
} from '@swa_llow/pricing_engine';

class RefersEdit extends Component {
    render() {
        const { 
            step,
            inputs,
            is_approved,
        } = this.props;

        return <ItemsEdit
            schema={stepReferSchema}
            set_error={this.props.set_error}
            updateStep={this.props.updateStep}
            step={step}
            inputs={inputs}
            header={'Refers list'}
            description={'This section allows you to create a list of quote referral reasons (e.g. refer for an ID check before valid).'}
            tag={'Refer'}
            is_approved={is_approved}
        />;
    }
}

export default RefersEdit;
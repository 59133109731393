import React, { Component } from 'react';

import TopNav from '../../components/Layout/TopNav';
import DesignArea from '../../components/Design/DesignArea';
import EditArea from '../../components/Design/EditArea';

import { unique_id } from '../../utils/unique_id';
import { getId, getQuery } from '../../../../utils/url';
import { error_response } from '../../utils/errors';
import { evaluateSchema, projectSchema } from '@swa_llow/pricing_engine';
import { putLargeVersion, fetchVersion } from '../../utils/versions';
import { putLocalVersion } from '../../utils/local_db';
import GlobalLoading from '../../../../components/GlobalLoading';

import { get } from 'lodash';

class VersionDesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            output: {},
            steps: [],
            version: {},
            version_reference: null,
            selected_step_id: null,
            isFetching: false,
        }
        this.addComponent = this.addComponent.bind(this);
        this.editComponent = this.editComponent.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.deleteComponent = this.deleteComponent.bind(this);
        this.refreshVersion = this.refreshVersion.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
    }

    async componentDidMount() {
        await this.refreshVersion();
    }

    async refreshVersion() {
        this.setState({ isFetching: true });
        const id = getId(this.props.history);
        // Setting ?no_cache in url resets local storage of the version
        const no_cache = getQuery(this.props.history, 'no_cache') !== '';
        const version = await fetchVersion({ version_reference: id, no_cache });
        const { project_reference } = get(version, 'meta', {});
        await this.props.swallow_users_get_all({ project_reference });
        const { steps, input, output } = version;
        this.setState({
            steps, 
            input,
            output,
            version_reference: id,
            version,
            isFetching: false,
        });
    }

    async saveVersion({ to_cloud = false} = {}) {
        const { 
            steps,
            input,
            output,
            version,
            version_reference,
        } = this.state;

        const { status } = version.meta

        const project = {
            ...version,
            steps,
            input,
            output,
        };

        // Can't update
        if (status === 'published') return;

        const result = evaluateSchema({
            project,
            schema: projectSchema,
        });

        if (result.valid) {
            await putLocalVersion({ version_reference, version: project });
            this.setState({
                version: project,
            })
            if (to_cloud) {
                //TODO auto save every 5 mins
                await putLargeVersion({ version_reference, version: project });
            }
        } else {
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: `Project: Issue in ${e.step}`,
            }));
            this.props.set_error(errors[0]);
        }
    }

    addComponent(index = 0) {
        const { steps } = this.state;
        const new_steps = [
            ...steps.slice(0, index),
            {
                step: null,
                name: null,
                key: null,
                id: unique_id(),
            },
            ...steps.slice(index)
        ];
        this.setState({ steps: new_steps });
    }

    editComponent(id) {
        if (!id) return this.setState({
            selected_step_id: null,
        });
        this.setState({
            selected_step_id: id,
        });
    }

    updateComponent({ id, data, input, output, empty = false }) {
        const { steps } = this.state;

        if (input) {
            return this.setState({
                input,
                selected_step_id: null,
            }, () => {
                this.saveVersion();
            });
        }

        if (output) {
            return this.setState({
                output,
                selected_step_id: null,
            }, () => {
                this.saveVersion();
            });
        }

        const new_steps = steps.map(step => {
            if (step.id === id) {
                return data;
            }
            return step;
        });

        this.setState({
            steps: new_steps,
            selected_step_id: null,
        }, () => {
            // Don't save if an empty step to stop validation fails
            if (!empty) this.saveVersion();
        });
    }

    deleteComponent (step_id) {
        const { steps } = this.state;
        this.setState({
            selected_step_id: null ,
            steps: steps.filter(s => s.id !== step_id),
        }, async() => {
            this.saveVersion();
        });
    }

    render() {
        const { 
            selected_step_id,
            version_reference,
            steps = [],
            input,
            output,
            version,
            isFetching,
        } = this.state;

        const { data: users = [] } = this.props.swallow_users;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} version={version.meta} auth={this.props.auth} />

                { isFetching && <GlobalLoading /> }

                { !isFetching && !selected_step_id &&
                    <DesignArea
                        version_reference={version_reference}
                        history={this.props.history}
                        project={version}
                        steps={steps}
                        users={users}
                        input={input}
                        output={output}
                        selected_step_id={selected_step_id}
                        addComponent={this.addComponent}
                        updateComponent={this.updateComponent}
                        editComponent={this.editComponent}
                        deleteComponent={this.deleteComponent}
                        saveVersion={this.saveVersion}
                        set_error={this.props.set_error}
                    />
                }

                { !isFetching && selected_step_id &&
                    <EditArea 
                        project={version}
                        steps={steps}
                        selected_step_id={selected_step_id}
                        updateComponent={this.updateComponent}
                        editComponent={this.editComponent}
                        deleteComponent={this.deleteComponent}
                        set_error={this.props.set_error}
                    /> 
                }

            </div>
        );
    }
}

export default VersionDesign;
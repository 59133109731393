import React, { Component } from 'react';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_valid: null,
            value: this.props.value || '',
        }
        this.update = this.update.bind(this);
    }

    update (value) {
        this.setState({
            value,
            is_valid: this.props.validation ? this.props.validation(value) : null,
        });
    }

    render () {
        const {
            name,
            label,
            submit,
            disabled = false,
            type = 'text',
            placeholder = '',
        } = this.props;
    
        const { is_valid, value } = this.state;
    
        return (
            <fieldset>
                <label>{label}</label>
                <input
                    disabled={disabled}
                    ref={name}
                    name={name}
                    type={type}
                    autoComplete="off"
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (this.props.update) value = this.props.update({ [name]: value });
                        this.update(value);
                    }}
                    onBlur={() => submit({
                        [name]: value,
                    })}
                />
                {is_valid === true && <div className="wf-valid"></div>}
                {is_valid === false && <div className="wf-invalid"></div>}
            </fieldset>
        )
    }
}

export default Input;

import React, { Component } from 'react';
import config from '../../../config';
import Logo from '../components/Logo';

import Login from '../../../auth/Login';

import build_image from '../pages/assets/app/Build.jpg';
import review_image from '../pages/assets/app/Review.jpg';
import test_image from '../pages/assets/app/Test.jpg';

class LoginWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide : 1,
        };
        this.update_slide = this.update_slide.bind(this);
    }

    update_slide(is_asc = true) {
        const { slide } = this.state;
        let new_slide;
        if (is_asc) {
            if (slide === 3) {
                new_slide = 1;
            } else {
                new_slide = slide + 1;
            }
        } else {
            if (slide === 1) {
                new_slide = 3;
            } else {
                new_slide = slide - 1;
            }
        }
        this.setState({
            slide: new_slide,
        })
    }

    render() {
        const { pool_reference, url } = config;
        const { slide } = this.state;
        return (
            <div className="login-canvas">
                <div className="left-login">
                    <div className="login-container">
                        <Logo size={'small'} />
                        <Login 
                            history={this.props.history} 
                            pool_reference={pool_reference}
                            success_url={`${url}/login`}
                        />
                    </div>
                </div>
                <div className="right-login">
                    <div className="login-screen">
                        <div className="login-image-shadow"></div>
                        <div className="login-image-shadow"></div>
                        <div className="login-image">
                            { slide === 1 && <img alt="login logo" src={build_image} /> }
                            { slide === 2 && <img alt="login logo" src={review_image} /> }
                            { slide === 3 && <img alt="login logo" src={test_image} /> }
                        </div>
                        <div className="login-image-caption">
                            {slide === 1 &&
                                <div>
                                    <h2>Build and deploy models with ease</h2>
                                    <p>Design on a drag and drop canvas and publish at the click of a button</p>
                                </div>
                            }
                            {slide === 2 &&
                                <div>
                                    <h2>Collaboration & auditability made easy</h2>
                                    <p>A streamlined pricing process for increased efficiency</p>
                                </div>
                            }
                            {slide === 3 &&
                                <div>
                                    <h2>Test your pricing before, during and after publishing</h2>
                                    <p>Improved confidence and reliability through streamlined testing</p>
                                </div>
                            }
                            <div className="login-arrows">
                                <ul>
                                    <li className="login-left">
                                        <i className="fa fa-circle-arrow-left" onClick={() => this.update_slide(false)}></i>
                                    </li>

                                    <li><span className={`login-circle ${slide === 1 ? 'active' : ''} `}></span></li>
                                    <li><span className={`login-circle ${slide === 2 ? 'active' : ''} `}></span></li>
                                    <li><span className={`login-circle ${slide === 3 ? 'active' : ''} `}></span></li>

                                    <li className="login-right">
                                        <i className="fa fa-circle-arrow-right"  onClick={() => this.update_slide(true)}></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginWrapper;
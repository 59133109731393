import React, { Component } from 'react';
import logo from '../services/swallow/pages/assets/app/white-swallow.png';

class GlobalLoading extends Component {
    render() {
        return (
            <div className="loading">
                <div className="pulse-logo">
                    <img alt="swallow logo" src={logo} />
                </div>
            </div>
        )
    }
}

export default GlobalLoading;
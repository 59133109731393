import React, { Component } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import { max } from 'lodash';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class VirtualiseTestTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderCell = this.renderCell.bind(this);
        this.setTableDimensions = this.setTableDimensions.bind(this);
    }

    componentDidMount() {
        this.setTableDimensions({ items: this.props.items });
    }

    async shouldComponentUpdate(nextProps) {
        if (nextProps.items.length !== this.props.items.length) {
            await this.setTableDimensions({
                items: nextProps.items,
            });
        }
        return true;
    }

    setTableDimensions({ items = [] }) {
        const { map_data = (() => {}), height } = this.props;
        const { columns, rows } = map_data(items);
        const table = this.refs.table;
        const new_height = height || window.innerHeight - 360;
        const width = table.offsetWidth - 22;
        
        return this.setState({ 
            columns,
            rows,
            items,
            height: new_height,
            width
        });
    }

    renderCell({ columnIndex, rowIndex, style }) {
        const { rows = [], columns = [] } = this.state;
        const { selectRow, items } = this.props;
        const column = columns[columnIndex] || {};
        const { key, render } = column;
        const item = rows[rowIndex];
        const cell = item[key];
        return (
            <div style={style} className={`cell ${rowIndex % 2 ? 'alt' : ''}`}>
                {selectRow && columnIndex === 0 && 
                    <button 
                        className="button small secondary mr" 
                        onClick={() => selectRow(items[rowIndex])}
                    >
                        View
                    </button>
                }
                {render && render(item)}
                {!render && cell.toString()}
            </div>
        )
    }

    render() {
        const { 
            columns = [],
            rows = [], 
            height = 0, 
            width = 0,
        } = this.state;

        const columnWidth = max([(columns.length ? width / columns.length : 120), 120]);
        const maxWidth = max([width, columnWidth * (columns.length || 1) ]);

        const tableStyle = width ? {
            width,
            overflowX: 'scroll'
        } : {};

        return [
            <div key={`full-buttons`} className="upload-buttons">
                <button className="button small grey no-hover mr">Count : {rows.length}</button>
            </div>,
            <div ref={'table'} className={`virtualise-table`} style={tableStyle}>

                <div className="virtualise-table-header" style={{width: maxWidth}}>
                    {columns.map((c) => {
                        const { key } = c;
                        return (
                            <div key={key} style={{width: columnWidth, position: 'relative'}} >
                                <span>{capitalizeFirstLetter(key)}</span>
                            </div>
                        )
                    })}
                </div>

                <div>
                    {rows.length > 0 &&
                        <Grid
                            columnCount={columns.length}
                            columnWidth={columnWidth}
                            height={height - 20}
                            rowCount={rows.length}
                            rowHeight={35}
                            width={maxWidth + 20}
                        >
                            {this.renderCell}
                        </Grid>
                    }
                </div>

            </div>
        ];
    }
}

export default VirtualiseTestTable;
import React from 'react';
import ItemsEdit from './Items';

import Table from './Table';
import ExpressionTextarea, { evaluateExpressions } from '../Expression/ExpressionTextarea';
import ExpressionTest from '../Expression/ExpressionTest';

class ItemsBooleanEdit extends ItemsEdit {

    render() {
        const { 
            step,
            inputs,
            header,
            description,
            tag = 'Item',
            is_approved,
        } = this.props;

        const { selected } = this.state;

        let {
            id = '',
            name = '',
            exp = '',
        } = selected || {};

        return [
            <div key={`${id}-edit`} className="exclusions-edit">
                {header && <h4>{header}</h4>}
                {description && <p>{description}</p>}
                
                {!is_approved &&
                    <div className="table-control-panel">
                        <div className="table-control-panel-left"></div>
                        <div className="table-control-panel-right">
                            <button className="button grey small mr"
                                onClick={this.clearExpression}
                            > Clear {tag}</button>
                            <button className="button secondary small" 
                                onClick={this.updateItems}
                            > {id === '' ? 'Create' : 'Update'} {tag}</button>
                        </div>
                    </div>
                }

                <div className="edit-columns">
                    <div className="edit-column">
                        <form>
                            <fieldset className={'spacing'}>
                                <label>{tag} Name</label>
                                <input onChange={(e) => {
                                    this.updateItem({
                                        name: e.target.value,
                                    })
                                }} value={name} />
                            </fieldset>
                            <fieldset className={'spacing'}>
                                <label>Expression</label>
                                <ExpressionTextarea 
                                    inputs={inputs} 
                                    value={exp}
                                    updateExpression={(value) => {
                                        this.updateItem({
                                            exp: value,
                                            def: false,
                                        });
                                    }}
                                />
                            </fieldset>
                        </form>
                    </div>
                </div>
                {exp !== '' &&
                    <ExpressionTest 
                        inputs={inputs}
                        expression={selected}
                    />
                }
            </div> 
            ,<div key={`${id}-table`} className="exclusions-table">
                <Table 
                    placeholder={tag}
                    inputs={inputs}
                    headers={[
                        '',
                        'Name',
                    ]}
                    hide_checkboxes={true}
                    componentData={{
                        ...step,
                        items: evaluateExpressions({
                            inputs, 
                            items: step.items,
                        }),
                    }}
                    updateComponent={this.updateItemFromTable}
                    selectItem={this.selectItem}
                    is_approved={is_approved}
                />
            </div>
        ];
    }
}

export default ItemsBooleanEdit;
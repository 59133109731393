import React, { Component } from 'react';
import BooleanSelect from '../Design/Edit/Shared/Forms/BooleanSelect';
import { isUndefined } from 'lodash';

class FormElementSelector extends Component {
    render() {
        const{
            form_key: key,
            type,
            label,
            updateQuote,
            quote,
            error,
        } = this.props;

        const value = isUndefined(quote[key])? '' : quote[key];

        return (
            <fieldset key={key} className={error? 'error spacing' : 'spacing'}>
                <label>{label}</label>
                {error && <p className="tiny-error-message">REQUIRED</p>}

                {type === 'string' &&
                    <input 
                        onChange={(e) => {
                            updateQuote({
                                [key]: e.target.value,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'integer' || type === 'decimal')&&
                    <input 
                        type="number"
                        onChange={(e) => {
                            let { value } = e.target;
                            value = (value !== '' ? Number(value) : '');
                            updateQuote({
                                [key]: value,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'date')&&
                    <input 
                        type="date"
                        className="date"
                        onChange={(e) => {
                            let { value } = e.target;
                            updateQuote({
                                [key]: value,
                            })
                        }} 
                        value={value}
                    />
                }

                {(type === 'boolean') &&
                    <BooleanSelect 
                        update={({ value }) => {
                            updateQuote({
                                [key]: value,
                            })
                        }}
                        value={value}
                    />
                }

            </fieldset>
        );
    }
}

export default FormElementSelector;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DownloadBox extends Component {
    render() {
        const { version_reference, project_reference } = this.props;

        if (project_reference) {
            return (
                <div className="download-box">
                    <div className="download-item" onClick={() => window.open(`/project/try/${project_reference}`, '_blank')}>
                        <i className="fa fa-desktop"></i>
                        <div className="download-name"><b>Try</b> Published Project</div>
                    </div>
                </div>
            )
        }

        return (
            <div className="download-box">
                <div className="download-item">
                    <i className="fa fa-table"></i>
                    <Link to={`${process.env.PUBLIC_URL}/assets/swallow/assets/demoProject.xlsx`} className="download-name" target="_blank" download><b>Download</b> to Excel</Link>
                </div>
                <div className="download-item" onClick={() => window.open(`/version/try/${version_reference}`, '_blank')}>
                    <i className="fa fa-desktop"></i>
                    <div className="download-name"><b>Try</b> Version</div>
                </div>
            </div>
        )
    }
}

export default DownloadBox;
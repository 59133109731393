import React, { Component } from 'react';
import FormatEdit from '../Shared/Format/Format';

import {
    stepTransformSchema,
} from '@swa_llow/pricing_engine';

class TransformEdit extends Component {
    render() {
        const { 
            step,
            inputs,
            is_approved,
        } = this.props;

        return <FormatEdit
            schema={stepTransformSchema}
            set_error={this.props.set_error}
            updateStep={this.props.updateStep}
            step={step}
            inputs={inputs}
            header={'Property Transform'}
            description={'This section enables you to transform properties from the previous steps or to define new properties.'}
            tag={'Property'}
            is_approved={is_approved}
        />;
    }
}

export default TransformEdit;
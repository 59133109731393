import React, { Component } from 'react';

import { FixedSizeGrid as Grid } from 'react-window';
import { saveAs } from 'file-saver';

import BooleanSelect from '../Shared/Forms/BooleanSelect';

import downloadWorker from 'workerize-loader!../../../../workers/data_links_download.worker' // eslint-disable-line import/no-webpack-loader-syntax

import { each, max, omit } from 'lodash';

function map_data (data) {

    const { dimensions, hashes, def } = data;
    const rows = [];
    const columns = [];

    // columns - {key: 'abi_code', label: 'abi_code', type: 'integer', def: '10000101'}

    if(dimensions) {
        each(dimensions, (v, k) => {
            columns.push({
                key: k,
                label: k,
                type: 'string', // TODO pull from inputs
                def: 0,
            });
        });
    }

    columns.push({
        key: 'weight',
        label: 'weight',
        type: 'decimal',
        def: def['weight'],
    });

    columns.push({
        key: 'exclude',
        label: 'exclude',
        type: 'boolean',
        def: def['excess']
    });

    columns.push({
        key: 'excess',
        label: 'excess',
        type: 'decimal',
        def: def['excess'],
    });

    columns.push({
        key: 'endorsement',
        label: 'endorsement',
        type: 'string',
        def: def['endorsement'],
    });

    columns.push({
        key: 'refer',
        label: 'refer',
        type: 'boolean',
        def: def['refer'],
    });

    if (hashes) {
        each(hashes, (v, k) => {
            let result = [k];
            if (k.includes('::')) result = k.split('');
            const factors = {};
            result.forEach((r, i) => {
                const key = columns[i].key;
                factors[key] = r;
            });

            rows.push({
                ...factors,
                weight: v.weight,
                exclude: v.exclude.toString().toUpperCase(),
                excess: v.excess,
                endorsement: v.endorsement,
                refer: v.exclude.toString().toUpperCase(),
            });
        });
    }

    return {
        columns, 
        rows,
    };
}

function clean_exp(exp = '') {
    let new_exp = exp;
    new_exp = new_exp.replace('(', '');
    new_exp = new_exp.replace('{{', '');
    new_exp = new_exp.replace('}}', '');
    new_exp = new_exp.replace(')', '');
    return new_exp;
}

class Links extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {},
            data: [],
            downloading: false,
            working: false,
        }
        this.columnType = this.columnType.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.keyColumn = this.keyColumn.bind(this);
        this.setTableDimensions = this.setTableDimensions.bind(this);
        this.updateStep = this.updateStep.bind(this);
    }

    componentDidMount() {
        const { step } = this.props;
        this.setState({
            step,
        }, () => {
            this.setTableDimensions();
        })
    }

    setTableDimensions() {
        const { step } = this.state;
        const { columns, rows } = map_data(step);
        const table = this.refs.table;
        const height = window.innerHeight - 360;
        const width = table.offsetWidth;
        this.setState({ 
            columns,
            rows,
            height,
            width
        });
    }

    updateStep(step) {
        this.setState({
            step,
        }, () => {
            this.props.updateStep(step);
            this.setTableDimensions();
        }) 
    }

    deleteData() {
        const { step = {} } = this.state;
        this.updateStep({
            ...step,
            exp: '()',
            format: {},
            links: {},
        });
    }

    async deleteColumn(key) {
        this.setState({ working: true });
        const { step = {} } = this.props;
        const { format, links } = step;

        let new_links = {};
        each(links, (v, k) => {
            new_links[k] = omit(v, key);
        });

        const new_step = {
            ...step,
            format: omit(format, key),
            links: new_links,
        };

        this.updateStep(new_step);
        this.setState({ working: false })
    }

    async keyColumn(key) {
        this.setState({ working: true });
        const { step = {} } = this.props;
        const { format, links, exp } = step;

        let new_links = {};
        each(links, (v, k) => {
            new_links[k] = {
                [key]: v[key],
                ...omit(v, key),
            };
        });

        const new_step = {
            ...step,
            exp: `({{${key}}})`,
            format: {
                [clean_exp(exp)] : {
                    type: 'string',
                    key: clean_exp(exp),
                    def: '', 
                },
                ...omit(format, key)
            },
            links: new_links,
        };

        this.updateStep(new_step);
        this.setState({ working: false });
    }

    async downloadCSV() {
        const { rows = [], columns = [], step = {} } = this.state;
        this.setState({ downloading: true });
        const download = new downloadWorker();
        const content = await download.downloadData({ rows, columns, step });
        saveAs(content, `${step.key}.csv`);
        this.setState({ downloading: false });
    }

    renderCell({ columnIndex, rowIndex, style }) {
        const { rows = [], columns = [] } = this.state;
        const column = columns[columnIndex] || {};
        const cell = rows[rowIndex][column.key];
        return (
            <div style={style} className={`cell ${rowIndex % 2 ? 'alt' : ''}`}>
                {cell}
            </div>
        )
    }

    columnType({
        key,
        type,
        def,
    }) {
        const { step = {}} = this.state;
        const { format = {}} = step;
        let new_format = {[key]: format[key] || {}};

        if (type) {
            new_format[key] = {
                ...new_format[key],
                label: key,
                type,
            }
        }

        if (def) {
            new_format[key] = {
                label: key,
                ...new_format[key],
                def,
            }
        }

        this.updateStep({
            ...step,
            format: {
                ...(step.format || {}),
                ...new_format,
            }
        });
    }

    render() {
        const { 
            columns = [],
            rows = [], 
            height = 0, 
            width = 0,
            downloading = false,
        } = this.state;

        const columnWidth = max([(columns.length ? width / columns.length : 200), 200]);
        const maxWidth = max([width, columnWidth * (columns.length || 1) ]);

        const tableStyle = width ? {
            width,
            overflowX: 'scroll'
        } : {};

        return [
            <div key={`full-buttons`} className="upload-buttons">
                <button className="button small grey no-hover mr">rows : {rows.length}</button>
                <button className="button small grey no-hover mr">columns : {columns.length}</button>
                <button className="button small secondary mr" onClick={this.downloadCSV}><i className="fa fa-angles-down"></i>Download CSV</button>
                <button className="button small grey mr" onClick={this.deleteData}><i className="fa fa-close"></i>Upload Data</button>
                { downloading && <i className="fa fa-spinner fa-spin"></i> }
            </div>,
            <div key={`full-dropzone`} ref={'table'} className={`virtualise-table ${downloading ? 'downloading' : ''}`} style={tableStyle}>
                <div className="virtualise-table-header" style={{width: maxWidth}}>
                    {!downloading && columns.map((c, i) => {
                        const { type, def, key } = c;
                        return (
                            <div key={key} style={{width: columnWidth, position: 'relative'}} >
                                <span>{key}</span>
                                <select onChange={(e) => {
                                    this.columnType({
                                        key,
                                        type: e.target.value,
                                    });
                                }} value={type}>
                                    <option value={''}></option>
                                    <option value={'boolean'}>Boolean</option>
                                    <option value={'decimal'}>Decimal</option>
                                    <option value={'integer'}>Integer</option>
                                    <option value={'string'}>String</option>
                                    <option value={'date'}>Date</option>
                                </select>
                                {(type === 'integer' || type === 'decimal') && <input type="number" value={def} onChange={(e) => {
                                    this.columnType({
                                        key,
                                        def: Number(e.target.value),
                                    });
                                }}></input>}
                                {type === 'string' && <input type="text" value={def} onChange={(e) => {
                                    this.columnType({
                                        key,
                                        def: e.target.value,
                                    });
                                }}></input>}
                                {type === 'date' && <input type="date" value={def} onChange={(e) => {
                                    this.columnType({
                                        key,
                                        def: e.target.value,
                                    });
                                }}></input>}
                                {type === 'boolean' && <BooleanSelect value={def} update={(value) => {
                                    this.columnType({
                                        key,
                                        def: value,
                                    });
                                }} />}
                            </div>
                        )
                    })}
                </div>
                <div>
                    {!downloading && rows.length > 0 &&
                        <Grid
                            columnCount={columns.length}
                            columnWidth={columnWidth}
                            height={height - 20}
                            rowCount={rows.length}
                            rowHeight={35}
                            width={maxWidth + 20}
                        >
                            {this.renderCell}
                        </Grid>
                    }
                    {downloading && <i class="fa fa-cloud-arrow-down"></i>}
                    {downloading && <div className="progress-bar"></div>}
                </div>
            </div>
        ];
    }
}

export default Links;
import React, { Component } from 'react';
import { each, get, isEmpty, isString } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swallow_login from '../services/swallow/components/Login';
import general_login from '../auth/Login';
import GlobalErrors from '../components/GlobalErrors';
import GlobalLoading from '../components/GlobalLoading';
import GlobalSuccess from '../components/GlobalSuccess';

import actions from '../actions';

import { sign_out, get_auth } from '../auth/index';

// TODO 
// this should be abstracted out in to a config
// ---
const main_component = process.env.REACT_APP_MAIN;
if (!main_component) throw console.error('No main_component defined in config.js');

let Login;
if (main_component === 'swallow') {
    Login = swallow_login;
}else {
    Login = general_login;
}
// ---

export default (Page, opts = { 
    private: true, 
    show_nav: true, 
    show_footer: false,
}) => {

    class Main extends Component {
        constructor(props) {
            super(props);
            this.state = {
                global_isFetching: false,
                global_errors: [],
                global_success: false,
                isFetchingToken: true,
            }
            this.signOut = this.signOut.bind(this);
            this.setAuthToken = this.setAuthToken.bind(this);
            this.clearGlobal = this.clearGlobal.bind(this);
        }

        async UNSAFE_componentWillMount() {
            if(opts.private) {
                await this.setAuthToken();
            }
        }

        componentWillUnmount() {
            clearTimeout(this.timer);
        }

        // TODO
        // Expensive but worth it for success, error global states
        // This saves us being granular with every component

        componentDidUpdate(prevProps) {
            let prev_success = [];
            let prev_errors = [];
            let current_errors = [];
            let current_success = [];
            let network_error = false;

            each(prevProps, (v, k) => {
                if (k.split('_').length === 2) {
                    if (v.error) {
                        if (isString(v.error.message) && (v.error.message).includes('Network')) network_error = true;
                        prev_errors = [v.error, ...prev_errors];
                    }
                    if (v.updated) {
                        prev_success = [ v.updated, ...prev_success ];
                    }
                }
            });

            each(this.props, (v, k) => {
                if (k.split('_').length === 2) {
                    if (v.error) {
                        current_errors.push(v.error);
                    }
                    if (v.updated) {
                        current_success = [ v.updated, ...current_success ];
                    }
                }
            });

            const has_errors_changed = prev_errors.length !== current_errors.length;
            const has_success_changed = current_success > prev_success;

            if(network_error) return;

            if (has_errors_changed || has_success_changed) {
                this.setState({
                    global_errors: current_errors,
                    global_success: has_success_changed
                }, () => {
                    if (this.messages) clearTimeout(this.timer);
                    this.clearGlobal();
                });
            }
        }

        clearGlobal() {
            const { global_success, global_errors = [] } = this.state;
            if (global_success || global_errors.length !== 0) {
                this.messages = setTimeout(() => {
                    this.setState({
                        global_success: false,
                        global_errors: [],
                    })
                }, 5000)
            }
        }

        async setAuthToken() {
            try {
                const auth_data = await get_auth();
                this.setState({ isFetchingToken: false });
                if (!isEmpty(auth_data)) {
                    this.props.set_token({
                        ...auth_data,
                        token: auth_data.access_token,
                    });
                    try {
                        const { selected: user } = this.props.auth;
                        if (!user) {
                            await this.props.auth_get({ id: auth_data.user_reference });
                        }
                    } catch(e) {
                        // this.props.set_error({ message: e.message });
                    }
                }
            } catch (e) {
                // this.props.set_error({ message: e.message });
            }
        }

        signOut() {
            sign_out();
            this.props.auth_reset({});
        }

        render() {
            const { auth = {} } = this.props;
            const { global_errors, global_isFetching, global_success } = this.state;

            if (opts.private) {
                if (get(auth, 'selected.user_reference', null) === null) {
                    return (<Login history={this.props.history} />);
                }
            }

            return (
                <div className="global">

                    {!global_isFetching && 
                        <Page { ...{...this.props, global_success} } />
                    }

                    {global_isFetching && 
                        <GlobalLoading /> 
                    }

                    {!global_isFetching && global_errors.length !== 0 && 
                        <GlobalErrors global_errors={global_errors} /> 
                    }

                    {!global_isFetching && global_success && 
                        <GlobalSuccess /> 
                    }

                </div>
            );
        }
    }

    function mapStateToProps(state) {
        return state;
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            ...actions,
        }, dispatch);
    }

    return connect(mapStateToProps, mapDispatchToProps)(Main);
};
import React, { Component } from 'react';
import Table from '../../../Layout/Table';
import Calculation from '../Shared/Calculation/Calculation';

import WithoutData from './WithoutData';
import WithData from './WithData';
import { isEmpty, keys, each } from 'lodash';

class FactorsTable extends Table {

    renderRow(data, i) {
        const {
            name,
            dimension_count,
            factor_count,
        } = data;

        const { select_factor } = this.props;

        return (
            <tr key={`factors-${i}`}>
                <td>
                    {name}
                </td>
                <td><div className="button no-hover small grey">{factor_count}</div></td>
                <td><div className="button no-hover small tertiary">{dimension_count}D</div></td>
                <td className="table-buttons">
                    <button onClick={() => select_factor(name)} className="button small secondary">
                        Edit
                    </button>
                </td>
            </tr>
        )
    }

    render() {
        const { items = [] } = this.props;
        return(
            <div className="exclusions-table">
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>Factor Name</th>
                                <th style={{ width: '100px' }}>Rows</th>
                                <th style={{ width: '80px' }}>Dimensions</th>
                                <th style={{ width: '80px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, i) => {
                                return (this.renderRow(item, i));
                            })}
                            {(items || []).length === 0 &&
                                <tr>
                                    <td colSpan={4}>
                                        <div className="empty-table">
                                            <i className="fa fa-warning"></i>
                                            No Factors
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

// TODO table of factors
// TODO common config
// TODO transition to table for detail

class Factors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_factor: null,
        }
        this.to_items = this.to_items.bind(this);
        this.select_factor = this.select_factor.bind(this);
        this.update_step = this.update_step.bind(this);
    }

    to_items({ factors }) {
        let items = [];
        each(factors, (v, k) => {
            items = [
                ...items,
                {
                    name: k,
                    dimension_count: keys(v.dimensions).length,
                    factor_count: keys(v.hashes).length,
                }
            ]
        });
        return items;
    }

    select_factor(name) {
        this.setState({
            selected_factor :name,
        });
    }

    update_step(data) {
        const { step } = this.props;
        if (data.formula) {
            this.props.updateStep({
                ...step,
                result: data,
            })
        }
    }

    render() {
        const { 
            step = {}, 
            inputs,
            is_approved,
        } = this.props;

        const { selected_factor = '' }  = this.state;

        const { factors = {} } = step;
        const factor = factors[selected_factor];

        const items = this.to_items({ factors });

        if (this.state.selected_factor) {
            if (isEmpty(items)) {
                return (
                    <WithoutData
                        key={'without-data'}
                        step={factor}
                        updateStep={this.props.update_step}
                        is_approved={is_approved}
                    />
                )
            } else {
                return (
                    <WithData
                        key={'with-data'}
                        step={factor}
                        updateStep={this.props.update_step}
                        is_approved={is_approved}
                    />
                )
            }    
        } else {
            return (
                <div>
                    <div className="exclusions-edit">
                        <Calculation 
                            step={step.result}
                            inputs={inputs}
                            updateStep={this.update_step}
                        />
                    </div>
                    <FactorsTable 
                        items={items}
                        select_factor={this.select_factor}
                    />
                </div>
            )
        }
    }
}

export default Factors;
import React, { Component } from 'react';
import { isUndefined } from 'lodash';

class BooleanSelect extends Component {
    render() {
        let { value, is_disabled = false } = this.props;
        value = isUndefined(value) ? '' : (value === '' ? '' : (value ? 'true' : 'false'));
        return (
            <select 
                value={value} 
                disabled={is_disabled}
                onChange={(e) => {
                    if (e.target.value === '') return;
                    this.props.update({
                        value: e.target.value === 'true' ? true : false,
                    });
                }
            }>
                <option value={''}></option>
                <option value={'true'}>TRUE</option>
                <option value={'false'}>FALSE</option>
            </select>
        );
    }
}

export default BooleanSelect;
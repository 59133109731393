import React, { Component } from 'react';
import { WFInput } from '../../components/wireframes/modules';

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.update = this.update.bind(this);
    }

    update(data) {
        console.log(data)
        this.setState(data);
    }

    render() {
        const { email } = this.state;
        const { isFetching, name } = this.props;

        if(isFetching) {
            return (
                <div className="wf wf-br">
    
                    <h2>We are sending your account link to your email address</h2>
                    <p>Give us a moment...</p>
    
                    <div className="wf-loader">
                        <div className="loading-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
    
                </div>
            );
        } else {
            return (
                <div id="start" className="wf">
    
                    <h1>{name ? `Hi ${name},` : 'Hi,'}</h1>
                    <h2>Request your account login link</h2>

                    <form style={{position: 'relative' }}>
                        <WFInput
                            name={'email'}
                            label={''}
                            submit={(value) => { this.update(value) }}
                            placeholder={'Email Address'}
                            validation={(value) => {
                                if (!value.includes('@')) return false;
                                if (!value.includes('.')) return false;
                                return value.length < 100;
                            }}
                        />
        
                        <button type="submit" onClick={(e) => {
                            e.preventDefault();
                            this.props.create_auth_session({
                                email,
                                type: 'START'
                            });
                        }}>
                            Send Link
                        </button>
                    </form>

                    <p>Don't have an account? <a href="https://formless.ai/c/XSldN6YUtEgx">Join the waiting list for priority access</a></p>

                    {/* <p>Don't have an account? <a onClick={(e) => {
                        e.preventDefault();
                        this.props.set_auth_status('REGISTER');
                    }} href="/">Register here</a></p>
                    */}

                </div>
            );
        }
        
    }
}
export default Start;
import React, { Component } from 'react';

class GlobalErrors extends Component {
    constructor (props) {
        super(props);
        this.state = {
            close: false,
        }
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        console.error(this.props.global_errors)
    }

    close() {
        this.setState({
            close: true,
        });
    }

    render() {
        const { close } = this.state;
        return (                        
            <div className="global-success global-error" style={{ display: close ? 'none' : 'block'}}>
                <i className='fa fa-circle-xmark'></i>
            </div>
        );
    }
}

export default GlobalErrors;
import React from 'react';
import Table from '../Layout/Table';
import Avatar from '../Layout/Avatar';
import moment from 'moment';

class TeamTable extends Table {
    constructor(props) {
        super(props);
        this.approval = this.approval.bind(this);
    }

    renderLatestStatus (status = '') {
        const cn = status === 'draft' ? 'grey' : 'tertiary';
        return (<button className={`button ${cn} no-hover small`}>{status.toUpperCase()}</button>);
    }

    renderRow(data, i, status) {
        const {
            user_reference,
            name,
            company,
            roles = [],
            email,
            approval,
        } = data;

        const { user = {} } = this.props;
        
        function map_color(role) {
            const map = {
                'editor': 'secondary',
                'read': 'grey',
                'approver': 'tertiary',
            };

            return map[role] || 'grey';
        }

        return (
            <tr key={user_reference}>
                <td>
                    <Avatar 
                        name={name}
                    />
                </td>
                <td>{company}</td>
                <td>{roles.map(r => (<span key={user_reference + r} className={`button no-hover ${map_color(r)} caps role`}>{r}</span>))}</td>
                <td>{email}</td>
                <td className="table-buttons">
                    {roles.includes('approve') &&
                        <div onChange={() => {
                            this.approval(i);
                        }} className={`toggle ${user_reference !== user.user_reference || status !== 'draft' ? 'disabled' : ''}`}>
                            <input type="checkbox" defaultChecked={approval ? true : false } disabled={user_reference !== user.user_reference} id={`team-${i}`} />
                            <label htmlFor={`team-${i}`}></label>
                        </div>
                    }
                </td>
            </tr>
        )
    }

    approval(i) {
        let action = null;
        const team = this.props.items.map((t, index) => {
            if (i === index) {
                if (t.approval) {
                    return {
                        ...t,
                        approval: null,
                    }
                } else {
                    action = {
                        type: 'approved',
                        content: '',
                    }
                    return {
                        ...t,
                        approval: moment().toISOString(), 
                    }
                }
            }
            return t;
        });

        this.setState({
            team,
        }, () => {
            this.props.save_process({
                team,
            }, action);
        });
    }

    render() {
        const { items = [], status } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '200px' }}>Name</th>
                            <th style={{ width: '160px' }}>Company</th>
                            <th>Role</th>
                            <th style={{ width: '160px' }}>Email</th>
                            <th style={{ width: '60px' }}>Approval</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (this.renderRow(item, i, status));
                        })}

                        {(items || []).length === 0 &&
                            <tr>
                                <td colSpan={5}>
                                    <div className="empty-table">
                                        <i className="fa fa-warning"></i>
                                        No Team Members
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TeamTable;
/* eslint react/jsx-no-target-blank:0 */

import React, { Component } from 'react';
import config from '../../../config'

class Home extends Component {
    componentWillMount() {
        // Forces through to swallow.app 
        // TODO once old https://swa.llow.io isn't used for back links then remove
        const { landing } = config;
        window.location.href = landing;
    }

    render() {

        return (
            <div id="swallow" className="new-splash-container">
                <div className="new-splash-logo"></div>
            </div>
        );
    }
}

export default Home;
import React, { Component } from 'react';

import { sign_out } from '../../../../auth/index';

import { Link } from 'react-router-dom';

import Logo from '../Logo';
import Avatar from './Avatar';
import { isEmpty } from 'lodash';

class TopNav extends Component {
    render() {
        const { history, auth = {} } = this.props;
        const { pathname } = history.location;
        const { selected:user = {} } = auth;

        const { name, version: version_number, version_reference, project_reference } = this.props.version || this.props.project || {};

        function is_active(name) {
            if (name === 'home' && pathname === '/home') return 'active';
            if (name === 'project' && pathname.includes('/project')) return 'active';
            if (name === 'build' && pathname.includes('/version/build')) return 'active';
            if (name === 'review' && pathname.includes('/version/review')) return 'active';
            if (name === 'data' && pathname.includes('/project/data')) return 'active';
            if (name === 'tests' && pathname.includes('/version/tests')) return 'active';
            if (name === 'settings' && pathname.includes('/project/settings')) return 'active';
            return '';
        }

        const products = [{
            name: 'Review',
            unlock: user.review_app ? true : false,
            icon: 'fa fa-clipboard-list'
        }, {
            name: 'Tests',
            unlock: user.tests_app ? true : false,
            icon: 'fa fa-vial-circle-check'
        }, {
            name: 'Build',
            unlock: user.build_app ? true : false,
            icon: 'fa fa-pen-ruler'
        }, {
            name: 'Data',
            unlock: user.data_app ? true : false,
            icon: 'fa fa-tachograph-digital'
        }];

        return (
            <div className="top-nav">

                <div className="top-tier">
                    <div className="logo-nav">
                        <Logo size={'v-small'} />
                    </div>
                    <ul className="links-nav">

                        <li className={is_active('projects')}>
                            <Link to={'/home'}>
                                <i className="fa fa-diagram-project"></i>
                                Projects
                            </Link>
                        </li>
                        <li>
                            <Link to={`/project/${project_reference}`}>
                                {name && <i className="fa fa-chevron-right"></i>}
                                {name}
                            </Link>
                        </li>
                        <li>
                            {this.props.version && 
                                <Link to={`/version/review/${version_reference}`} className="version">
                                    {this.props.version && <i className="fa fa-chevron-right"></i>}
                                    #{version_number}
                                </Link>
                            }
                        </li>

                    </ul>

                    <div className="settings-nav">
                        <i className="fa fa-cog"></i>
                    </div>
                    
                    <div className="notification-nav">
                        <i className="fa fa-bell"></i> 
                        <span className="notify-count">2</span>
                    </div>

                    <div onClick={() => {
                        sign_out();
                    }} className="avatar-nav">
                        <Avatar name={user.name || (user.custom || {}).name} lite={true} />
                    </div>
                </div>

                {!isEmpty(this.props.version) &&
                    <div className="version-tier">
                        <ul className="links-nav">
                            {products.map((p, i) => {
                                const { name = '', unlock = false, icon = '' } = p;
                                return (
                                    <li key={version_reference + i} className={`${is_active(name.toLowerCase())} ${unlock ? '' : 'unlock'}`}>
                                        <Link to={
                                            unlock ? 
                                            `/version/${name.toLowerCase()}/${version_reference}` : 
                                            `/version/review/${version_reference}`
                                        }>
                                            <i className={`fa ${icon}`}></i>
                                            {name}
                                        </Link>
                                        {!unlock &&
                                            <span className="nav-unlock">
                                                <i className="fa fa-lock"></i>
                                                UNLOCK
                                            </span>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                }

            </div >
        );
    }
}

export default TopNav;
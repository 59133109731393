import React, { Component } from 'react';
import Table from '../components/Layout/Table';
import Avatar from '../components/Layout/Avatar';

class TeamTable extends Table {
    renderLatestStatus (status = '') {
        const cn = status === 'draft' ? 'grey' : 'tertiary';
        return (<button className={`button ${cn} no-hover small`}>{status.toUpperCase()}</button>);
    }

    renderRow(data, i, status) {
        const {
            user_reference,
            name,
            company,
            roles = [],
            email,
        } = data;

        return (
            <tr key={user_reference}>
                <td>
                    <Avatar 
                        name={name}
                    />
                </td>
                <td>{company}</td>
                <td>{roles.map(r => (<span key={user_reference + r} className={`button no-hover grey caps role`}>{r}</span>))}</td>
                <td>{email}</td>
            </tr>
        )
    }

    render() {
        const { items = [] } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '200px' }}>Name</th>
                            <th style={{ width: '160px' }}>Company</th>
                            <th>Role</th>
                            <th style={{ width: '160px' }}>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (this.renderRow(item, i));
                        })}

                        {(items || []).length === 0 &&
                            <tr>
                                <td colSpan={5}>
                                    <div className="empty-table">
                                        <i className="fa fa-warning"></i>
                                        No Team Members
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

class NoAccess extends Component {
    render() {
        return (
            <section className="main-content">
                <div className="no-access-area">
                    <h3><i className="fa fa-warning"></i> You do not have access to this Pricing Project</h3>
                    <p>Request access from an existing team member</p>
                </div>
                <div className="review-block">
                    <TeamTable 
                        items={this.props.team}
                    />
                </div>
            </section>
        );
    }
}

export default NoAccess;
import React, { Component } from 'react';
import ItemsEdit from '../Shared/Items/ItemsNonBooleanDefault';

import {
    stepEndorsementsSchema,
} from '@swa_llow/pricing_engine';

class EndorsementsEdit extends Component {
    render() {
        const { 
            step,
            inputs,
            is_approved,
        } = this.props;

        return <ItemsEdit
            schema={stepEndorsementsSchema}
            set_error={this.props.set_error}
            updateStep={this.props.updateStep}
            step={step}
            inputs={inputs}
            header={'Endorsements'}
            description={'This section allows you to create quote endorsements (e.g. a car must be parked in a garage to be valid).'}
            tag={'Endorsement'}
            is_approved={is_approved}
        />;
    }
}

export default EndorsementsEdit;
import React, { Component } from 'react';

import VersionsTable from '../Project/VersionsTable';

class ChangeLog extends Component {
    render () {
        const { version } = this.props;
        const { version_reference, summary } = version;
        return (
            <div key={version_reference} className="project mini project-log">
                <div className="project-box">
                    <VersionsTable items={[version]} history={this.props.history} />
                </div>
                <div className="project-box">
                    <div className="change-log">
                        {/*
                        <div>
                            <button className={`button ${status === 'draft' ? 'secondary' : 'grey'} no-hover mr`}>16 Comments</button> 
                            <button className={`button ${status === 'draft' ? 'secondary' : 'grey'} no-hover mr`}>3 of 4 Tasks</button>
                            <button className={`button ${status === 'draft' ? 'secondary' : 'grey'} no-hover mr`}>6 Assets</button>
                            <button className={`button ${status === 'draft' ? 'secondary' : 'grey'} no-hover`}>5 Team</button>
                        </div>
                        */}
                        <h3>Summary</h3>
                        <div dangerouslySetInnerHTML={{__html: summary}}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangeLog;
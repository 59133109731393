import React, { Component } from 'react';
import slugify from '../../../../../utils/slugify';

class EditDetails extends Component {
    render() {
        const {
            step,
            errors = [],
            updateStep,
        } = this.props;

        const {
            name,
            key,
        } = step;

        function has_error (key) {
            return errors.map(e => e.key).includes(key);
        }

        const is_disabled = (key === 'input' || key === 'output');

        return (
            <div>
                <div className="edit-inputs">
                    <form>
                        <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                            <label>Step Name</label>
                            <input onChange={(e) => {
                                updateStep({
                                    name: e.target.value,
                                })
                            }} disabled={is_disabled} value={name} />
                        </fieldset>
                        {/*
                        <fieldset className={'spacing'}>
                            <label>Step Description</label>
                            <textarea 
                                value={description}
                                onChange={(e) => {
                                    updateStep({
                                        description: e.target.value,
                                    })
                                }}
                            />
                        </fieldset>
                        */}
                    </form>
                </div>

                {step.step !== 'links' &&
                <div className="edit-inputs">
                    <form>
                        <fieldset className={has_error('name') ? 'error spacing' : 'spacing'}>
                            <label>Step Output Key</label>
                            <input onChange={(e) => {
                                updateStep({
                                    key: slugify(e.target.value),
                                })
                            }} disabled={is_disabled} value={key} />
                        </fieldset>

                        {/*
                            <fieldset className={'spacing'}>
                                <label>Output Default Value</label>
                                <BooleanSelect 
                                    value={def}
                                    update={(value) => {
                                        updateStep({
                                            def: value,
                                        })
                                    }}
                                />
                            </fieldset>
                        */}
                                
                        {/*!isUndefined(retain) &&
                            <fieldset className={'spacing'}>
                                <label>Retain Output</label>
                                <BooleanSelect 
                                    value={retain}
                                    update={(value) => {
                                        updateStep({
                                            BooleanSelect: value,
                                        })
                                    }}
                                />
                            </fieldset>
                        */}

                    </form>
                </div>
                }
            </div>
        );
    }
}

export default EditDetails;
import React, { Component } from 'react';

//TODO - this will need to be a map of either files or urls to videos
import video from '../../pages/assets/landing/hero-720.mp4';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_open: false,
        }
        this.open = this.open.bind(this);
    }

    open() {
        this.setState({
            is_open: !this.state.is_open,
        });
    }

    render() {
        const { is_open } = this.state;
        const { 
            title = 'Using Designer', 
            description = 'Version designer is were you design the steps in flow of your quote.', 
            number = 11
        } = this.props;
        return (
            <div>
                <button onClick={this.open} className="button video big">
                    <i className="fa fa-circle-play"></i>Explainer Video (No. {number})
                </button>
                {is_open && 
                    <div className="video-container">
                        <div onClick={this.open} className="video-close"><i className='fa fa-circle-xmark'></i></div>
                        <h4>{title} (No. {number})</h4>
                        <p>{description}</p>
                        <div className="video-embed" dangerouslySetInnerHTML={{
                            __html: `<video id="video" controls playsinline><source src=${video} type="video/mp4" />Your browser does not support the video tag.</video>`,
                        }}/>
                        <div className='video-buttons'>
                            <button className="button small mr">More Help?</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Video;
import React, { Component } from 'react';

import TopNav from '../../components/Layout/TopNav';
import Footer from '../../components/Layout/Footer';

import ProjectMiniContainer from '../../components/Project/ProjectMiniContainer';
import ProjectCreate from '../../components/Project/ProjectCreate';
import { baseProject } from '@swa_llow/pricing_engine';

import { get } from 'lodash';

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_create_open: false,
        }
        this.openProject = this.openProject.bind(this);
        this.createProject = this.createProject.bind(this);
        this.createVersion = this.createVersion.bind(this);
    }

    async UNSAFE_componentWillMount() {
        await this.props.swallow_projects_get_all();
    }

    openProject() {
        const { is_create_open } = this.state;
        this.setState({
            is_create_open: !is_create_open,
        })
    }

    async createVersion({ project_reference }) {
        const result = await this.props.swallow_versions_post({
            data: {
                project_reference
            }
        });
        const version_reference = get(result, 'payload.data.version_reference');
        this.props.history.push(`/version/design/${version_reference}`);
    }

    async createProject(data) {
        const { swallow_projects_post } = this.props;
        const result = await swallow_projects_post({ 
            data: {
                ...baseProject,
                meta: {
                    ...baseProject.meta,
                    name: data.name,
                    description: data.description,
                }
            }
        });
        await this.props.swallow_projects_get_all();

        const version_reference = get(result, 'payload.data.version_reference');
        this.props.history.push(`/version/review/${version_reference}?edit_mode=true`)
    }

    render() {
        const { is_create_open } = this.state;
        const { data = [], isFetching } = this.props.swallow_projects;

        return (
            <div className="swallow_app">
                <TopNav history={this.props.history} auth={this.props.auth} />

                <section className="main-content">

                    { !is_create_open &&
                        <div className="main-content-nav">
                            <div className="main-content-nav-left"></div>
                            <div className="main-content-nav-right">
                                <button onClick={this.openProject} className="button big"><i className="fa fa-passport"></i> Create New Project</button>
                            </div>
                        </div>
                    }

                    { is_create_open && <ProjectCreate 
                        openProject={this.openProject} 
                        createProject={this.createProject}
                    /> }

                    <div style={{ opacity: is_create_open ? 0.5 : 1 }}>
                        {!isFetching && data.length > 0 && 
                            data.map(d => {
                                return(<ProjectMiniContainer 
                                    key={d.project_reference} 
                                    history={this.props.history} 
                                    project={d} 
                                    createVersion={this.createVersion}
                                />)
                            })
                        }

                    </div>

                </section>

                <Footer history={this.props.history} />
            </div>
        );
    }
}

export default Home;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLangSource as get_lang } from '../lang';
import actions from '../actions';
import { capitalize, last } from 'lodash';

import base from '../config';

import { get_session, post_session, post_company, get_name } from '.';

import Register from './components/Register';
import Start from './components/Start';
import Waiting from './components/Waiting';
import Success from './components/Success';
import Failure from './components/Failure';
import Timeout from './components/Timeout';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'en',
            name: '',
            email: null,
            auth_is_fetching: false,
            create_is_fetching: false,
            auth_status: 'START',
            auth_error: '',
            auth_count: 0,
            auth_session: '' ,
        }
        this.create_auth_session = this.create_auth_session.bind(this);
        this.create_account = this.create_account.bind(this);
        this.poll_auth_session = this.poll_auth_session.bind(this);
        this.set_auth_status = this.set_auth_status.bind(this); 
        this.set_auth_error = this.set_auth_error.bind(this);
    }

    async componentDidMount() {
        const status = new URLSearchParams(window.location.search).get('status');
        const email = new URLSearchParams(window.location.search).get('email');
        const name = await get_name() || '';
        this.setState({ name })
        if (status) {
            const new_status = status.toUpperCase();
            
            if (['FAILURE', 'TIMEOUT', 'START', 'SUCCESS', 'WAITING', 'REGISTER'].includes(new_status)) {
                this.setState({ auth_status: new_status });
            }

            // Force login from email
            if (email) {
                //login?email=cjr%2Btest%40llow.io&status=START
                await this.create_auth_session({
                    email,
                    type: 'START',
                })
            }
            
            // For session redirect auto
            if(new_status.includes('SESSION')) {
                const session = last(status.split('::'));
                try {
                    await get_session(session);
                    this.props.history.push('/projects');
                } catch(e) {
                    console.log(e)
                }
            } 
        }
    }

    async create_account(data) {
        try {
            this.setState({ create_is_fetching: true, auth_error: '' });
            await post_company(data);
            window.location.href = `/login?email=${encodeURIComponent(data.email)}&status=START`;
        } catch(e) {
            const { message = ''} = e;
            this.set_auth_error(message);
            this.setState({ create_is_fetching: false });
        }
    }

    async create_auth_session(data) {
        this.setState({ auth_is_fetching: true, auth_error: '' });
        try {
            this.setState({ auth_is_fetching: true, email: data.email });
            // If supplied from URL
            const { pool_reference, success_url } = this.props;
            const result = await post_session(data, pool_reference, success_url);
            if (result.session) {
                this.set_auth_status('WAITING');
                this.setState({
                    auth_session: result.session,
                }, () => {
                    this.poll_auth_session();
                })
                
            } else {
                this.set_auth_error('No session was set');
            }
        } catch(e) {
            const { message = ''} = e;
            this.set_auth_error(message);
        }
    }

    async poll_auth_session () {
        let count = 0;
        const { auth_session } = this.state;
        const interval = setInterval(async() => {
            let result;
            if (count > base.allow.poll_timeout) {
                clearInterval(interval);
                this.set_auth_status('TIMEOUT');
            }
            try {
                result = await get_session(auth_session);
            } catch(e) {
                clearInterval(interval);
                const { message = ''} = e;
                this.set_auth_error(message);
            }
            if (result.access_token) {
                clearInterval(interval);
                this.set_auth_status('SUCCESS');
            }
            count = count + 2500;
            this.setState({ auth_count: (count / 1000) });
        }, 2500)
    }

    set_auth_status(status = '') {
        this.setState({
            auth_status: status,
            auth_is_fetching: false,
        })
    }

    set_auth_error(message = '') {
        this.setState({
            auth_error: capitalize(message),
            auth_is_fetching: false,
        })
    }

    render() {
        const {
            auth_error,
            auth_status,
            auth_is_fetching,
            create_is_fetching,
            auth_count,
            email,
            name,
        } = this.state;

        const { history, success_url } = this.props;

        const lang = get_lang(this.state.lang);
        
        return (
            <div className="wf-container">
                <div className="wf-row">
                    <div className="wf-col-3"></div>
                    <div className="wf-col-6">
                        <div className="wf wf-br">

                            {auth_error !== '' &&
                                <div className="wf wf-bg wf-alert">
                                    <p><i className="fa fa-warning"></i> {auth_error}</p>
                                </div>
                            }

                            {auth_status === 'REGISTER' &&
                                <Register 
                                    lang={lang}
                                    create_account={this.create_account}
                                    isFetching={create_is_fetching}
                                    history={history}
                                    set_auth_status={this.set_auth_status}
                                    set_auth_error={this.set_auth_error}
                                />
                            }

                            {auth_status === 'START' &&
                                <Start 
                                    lang={lang}
                                    create_auth_session={this.create_auth_session}
                                    set_auth_error={this.set_auth_error}
                                    set_auth_status={this.set_auth_status}
                                    isFetching={auth_is_fetching}
                                    history={history}
                                    name={name}
                                />
                            }

                            {auth_status === 'WAITING' &&
                                <Waiting 
                                    lang={lang}
                                    email={email}
                                    auth_count={auth_count}
                                    history={history}
                                    name={name}
                                />
                            }

                            {auth_status === 'SUCCESS' &&
                                <Success 
                                    email={email}
                                    lang={lang}
                                    history={history}
                                    success_url={success_url}
                                    name={name}
                                />
                            }

                            {auth_status === 'FAILURE' &&
                                <Failure 
                                    lang={lang}
                                    email={email}
                                    set_auth_error={this.set_auth_error}
                                    set_auth_status={this.set_auth_status}
                                    history={history}
                                    name={name}
                                />
                            }

                            {auth_status === 'TIMEOUT' &&
                                <Timeout 
                                    lang={lang}
                                    email={email}
                                    set_auth_error={this.set_auth_error}
                                    set_auth_status={this.set_auth_status}
                                    name={name}
                                />
                            }
                        </div>                    
                    </div>
                    <div className="wf-col-3"></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...actions,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, { Component } from 'react';
import { isEmpty } from 'lodash';

class StepButtons extends Component {
    render() {
        const {
            id,
            hide_outputs,
            hideOutputs = (() => {}),
            outputs = [],
            errors = [],
            timer = {},
            logs = {},
        } = this.props;

        return [
            <div key={`${id}-output`} className="button secondary small mr"  onClick={hideOutputs} style={{display: !hide_outputs ? 'none' : 'inline-block'}}>
                {outputs.length} Output{outputs.length > 1 ? 's': ''}
            </div>,
            <div
                key={`${id}-logs`} 
                style={{display: isEmpty(logs) || errors.length !== 0 ? 'none' : 'inline-block'}} 
                className="button secondary small mr" 
                onClick={this.props.openLog}
            >
                <i className="fa fa-regular fa-file-lines"></i>
                Step Logs
            </div>,
            <div 
                key={`${id}-errors`}
                style={{display: errors.length === 0 ? 'none' : 'inline-block'}} 
                className="button error small mr"
                onClick={this.props.openLog}
            > 
                <i className="fa fa-regular fa-file-lines"></i>
                {errors.length} Errors
            </div>,
            <div
            key={`${id}-time`}
                style={{display: isEmpty(timer) ? 'none' : 'inline-block'}}  
                className="button grey small no-hover">
                <i className="fa fa-clock"></i>
                {timer.elapsed} ms
            </div>
        ]
    }
}

export default StepButtons;
import React, { Component } from 'react';
import Table from '../Layout/Table';
import Dropzone from 'react-dropzone';
import slugify from '../../utils/slugify';
import download from '../../utils/download';
import { last } from 'lodash';

class AssetsTable extends Table {
    renderLatestStatus (status = '') {
        const cn = status === 'draft' ? 'grey' : 'tertiary';
        return (<button className={`button ${cn} no-hover small`}>{status.toUpperCase()}</button>);
    }

    renderRow(data, i) {
        const {
            file,
            type,
            size,
        } = data;

        const { edit_mode, get_signed_url_s3, delete_asset } = this.props;

        return (
            <tr key={`assets-${i}`}>
                <td>
                    <i className="fa fa-circle-down"></i>
                </td>
                <td>
                    <a onClick={async (e) => {
                        e.preventDefault();
                        const url = await get_signed_url_s3({
                            name: file,
                            type,
                        });
                        download({ file, url });
                    }} className="button small secondary" href="/">{file}</a>
                </td>
                <td>{Math.round(size / 1000, 0)}</td>
                <td className="table-buttons">
                    { edit_mode &&
                        <button onClick={() => delete_asset(i)} className="button small error">
                            Delete
                        </button>
                    }
                </td>
            </tr>
        )
    }

    render() {
        const { items = [], edit_mode } = this.props;
        return(
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '20px' }}></th>
                            <th>Asset Name</th>
                            <th style={{ width: '80px' }}>Size (KB)</th>
                            <th style={{ width: edit_mode  ? '140px' : '0px' }}> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (this.renderRow(item, i));
                        })}
                        {(items || []).length === 0 &&
                            <tr>
                                <td colSpan={4}>
                                    <div className="empty-table">
                                        <i className="fa fa-warning"></i>
                                        No Assets
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        }
        this.upload_asset = this.upload_asset.bind(this);
        this.delete_asset = this.delete_asset.bind(this);
    }

    async upload_asset([file]) {
        this.setState({ uploading: true });
        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async() => {
                const result = reader.result;
                let file_el = file.path.split('.');
                const file_type = last(file_el);
                file_el.pop();
                const name = slugify(file_el.join('_')) + '.' + file_type;
                const payload = {
                    data: result, 
                    name,
                    type: file.type,
                    size: file.size,
                }
                await this.props.put_signed_url_s3(payload);
                resolve(result)
            }
            reader.readAsArrayBuffer(file);
        }).catch(e => console.log(e));
        this.setState({ uploading: false });
    }

    delete_asset(index) {
        const { items } = this.props;
        this.props.save_process({
            assets: items.filter((item, i) => i !== index),
        });
    }

    render() {
        const { items = [], edit_mode, get_signed_url_s3 } = this.props;
        const { uploading } = this.state;
        if (edit_mode) {
            return [
                <div key={'assets-edit-table'} className="review-block review-block-left">
                    <AssetsTable 
                        items={items}
                        get_signed_url_s3={get_signed_url_s3}
                        delete_asset={this.delete_asset}
                        edit_mode={edit_mode}
                    />
                </div>,
                <div key={'assets-edit-upload'} className="review-block review-block-right">
                    <div key={`empty-dropzone`} ref={'table'} className="upload-box">
                        <Dropzone onDrop={acceptedFiles => this.upload_asset(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => [
                                <div key={'empty-dropzone-child'} {...getRootProps()}>
                                    <i className="fa fa-cloud-arrow-up"></i>
                                    <p>Drag and Drop assets to upload.</p>
                                </div>,
                                <input key={'empty-dropzone-input'} {...getInputProps()} />
                            ]}
                        </Dropzone>
                        {uploading && <div className="progress-bar"></div>}
                    </div>
                </div>
            ]
        } else {
            return [
                <div key={`assets`} className="review-block review-block-right">
                    <AssetsTable 
                        items={items}
                        get_signed_url_s3={get_signed_url_s3}
                        edit_mode={edit_mode}
                    />
                </div>
            ]
        }
    }
}

export default Assets;
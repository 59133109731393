import React, { Component } from 'react';

class Failure extends Component {
    render() {
        const { email } = this.props;
        return (
            <div id="failure" className="wf">
                <h1>Oh Dear!</h1>
                <h2>Something went wrong</h2>
                <p>Sent to: {email}</p>
                <p>Sorry, you failed to be authorised to via the access link within the email.</p>
                <button onClick={() => {
                    this.props.set_auth_status('START');
                }}>
                    Try again
                </button>
            </div> 
        );
    }
}
export default Failure;
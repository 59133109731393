import React, { Component } from 'react';
import { each, isString, has, isBoolean } from 'lodash';
import moment from 'moment';

function map_result(result) {
    let arr = [];
    each(result, (v, k) => {
        if(k === 'result') return;
        if(k === 'valid') return;
        arr.push({
            key: k,
            value: v,
        })
    });
    return arr;
}

class ActualResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logs_open: false,
            open_excesses: true,
            open_exclusions: true,
            open_refers: true,
            open_endorsements: true,
            open_additional: true,
        }
        this.renderSubResults = this.renderSubResults.bind(this);
        this.renderAdditionalOutputs = this.renderAdditionalOutputs.bind(this);
    }

    renderAdditionalOutputs ({ data = [] }) {
        const state = this.state.open_additional;
        return (
            <ul className={`run-result-items additional-outputs ${state ? 'open' : ''} ${data.length ? '' : 'zero'}`} onClick={() => {
                this.setState({
                    open_additional: !state,
                })
            }}>
                <li className="run-result-item-header">
                    {state && <i className="fa fa-circle-chevron-down"></i>}
                    {!state && <i className="fa fa-circle-chevron-up"></i>}
                    Additional Outputs 
                    <span>{ data.length }</span>
                </li>
                {data.map(d => {
                    return (
                        <li key={d.key}>
                            {d.key} : {d.value}
                        </li>
                    );
                })}
            </ul>
        )
    }

    renderSubResults ({ data = [], type }) {
        const key = type.toLowerCase();
        const state = this.state[`open_${key}`];
        const new_data = data.map(d => {

            let result = d.result;

            if (isBoolean(result)) {
                result = result.toString().toUpperCase();
            } else if (!isString(result)) {
                result = result.toString();
            } else if (moment(result).isValid()) {
                result = moment(result).format('DD/MM/YYYY');
            } else {}

            return {
                step: d.step,
                result,
                name: d.name,
                value: d.value,
            }
        })
        return (
            <ul className={`run-result-items ${state ? 'open' : ''} ${data.length ? '' : 'zero'}`} onClick={() => {
                this.setState({
                    [`open_${key}`]: !state,
                })
            }}>
                <li className="run-result-item-header">
                    {state && <i className="fa fa-circle-chevron-down"></i>}
                    {!state && <i className="fa fa-circle-chevron-up"></i>}
                    {type} 
                    <span>{ data.length }</span>
                </li>

                {(key === 'endorsements' || key === 'excesses') && new_data.map(d => {
                    return (
                        <li key={`${key}-${d.name}-${d.step}`}>
                            {d.value}
                            <em>in {d.step}</em>
                            <span>{isString(d.result) ? d.result : d.result.toString()}</span>
                        </li>
                    );
                })}

                {(key === 'refers' || key === 'exclusions') && new_data.map(d => {
                    return (
                        <li key={`${key}-${d.name}-${d.step}`}>
                            {d.name}
                            <em>in {d.step}</em>
                            <span>{d.result}</span>
                        </li>
                    );
                })}
            </ul>
        )
    }

    render() {
        const {
            is_running,
            quote,
        } = this.props;

        if(is_running) {
            return (
                <div className="run-area">
                    <div className="run-loading-wrapper">
                        <div className="loading-ring secondary">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            )
        }

        const {
            is_logs_open = false,
        } = this.state;

        const {
            result = {},
            debug = {},
        } = quote;

        const {
            exclusions = [],
            excesses = [],
            refers = [],
            endorsements = [],
        } = debug;

        return(
            <div className="run-area">
                <div>
                    <div className="run-result">
                        <h6>CALCULATED RESULT</h6>
                        <h4>{result.result}</h4>
                        {has(result, 'valid') && result.valid && <div className="run-valid">VALID</div>}
                        {has(result, 'valid') && !result.valid && <div className="run-invalid">INVALID</div>}
                    </div>

                    {!is_logs_open && this.renderAdditionalOutputs({
                        data: map_result(result),
                        type: 'Additional Outputs',
                    })}
                    
                    {!is_logs_open && this.renderSubResults({
                        data: exclusions,
                        type: 'Exclusions',
                    })}

                    {!is_logs_open && this.renderSubResults({
                        data: excesses,
                        type: 'Excesses',
                    })}

                    {!is_logs_open && this.renderSubResults({
                        data: endorsements,
                        type: 'Endorsements',
                    })}

                    {!is_logs_open && this.renderSubResults({
                        data: refers,
                        type: 'Refers',
                    })}

                    <div className="run-area-footer">
                        <button
                            onClick={() => {
                                this.setState({
                                    is_logs_open: !is_logs_open,
                                });
                                if (this.props.updateIsLogOpen) this.props.updateIsLogOpen();
                            }} 
                            className="button small secondary mr logs">{is_logs_open ? `Close Logs` : `Show Logs`}
                        </button> 
                    </div>

                    {is_logs_open && 
                        <div className="show-logs-area">
                            <pre>
                                <code>
                                    {JSON.stringify(quote, null, 4)}
                                </code>
                            </pre>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default ActualResult;
import React, { Component } from 'react';
import { isUndefined, omit, find } from 'lodash';
import {
    error_response
} from '../../../utils/errors';

import {
    evaluateSchema,
    testProject,
} from '@swa_llow/pricing_engine';

class CommonEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            original_step: null,
            step: null,
            formatted_step: null,
            editing: false,
        }
        this.validateStep = this.validateStep.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.editStep = this.editStep.bind(this);
        this.reHydrate = this.reHydrate.bind(this);
    }

    async UNSAFE_componentWillMount(){
        const { step = {} } = this.props;

        let clean_step;

        if(!await this.validateStep(JSON.stringify(step), false)) {
            const base = find(testProject.steps, s => s.step === step.step);
            clean_step = {
                ...clean_step,
                ...base,
            };
        }

        clean_step = omit(step, ['id', 'step']);

        await this.setState({
            original_step: step,
            step: JSON.stringify(clean_step),
            formatted_step: JSON.stringify(clean_step, null, 4),
        });
    }

    reHydrate(step) {
        const { original_step } = this.state;
        let new_step = {
            ...JSON.parse(step),
            id: original_step.id,
            step: original_step.step,
        }
        return new_step;
    }

    async validateStep(step, show_error = true) {
        const { schema } = this.props;

        // For Validate JSON button
        if (!step) step = this.state.step;

        let new_step;
        try {
            new_step = this.reHydrate(step);
        } catch(e) {
            if (show_error) await this.props.set_error({ message: e.message });
            if (show_error) await this.setState({ valid: false});
            return false;
        }
        
        const result = evaluateSchema({
            project: new_step,
            schema,
        });

        if (!result.valid) {
            if (show_error) await this.setState({ valid: false });
            const errors = (result.errors || []).map(e => error_response({
                error: e,
                label: 'Step',
            }));
            if (show_error) this.props.set_error(errors[0]);
            return result.valid;
        }

        await this.setState({ valid: true});
        return result.valid;
    }

    editStep (step) {
        this.setState({ step });
    }

    async updateStep () {
        const valid = await this.validateStep();
        if(valid) {
            const { step } = this.state;
            await this.setState({
                formatted_step : JSON.stringify(JSON.parse(step), null, 4),
                editing: false,
            })
            const new_step = this.reHydrate(step);
            await this.props.overwriteStep(new_step); 
        }
    }   

    render() {
        const { 
            step,
            formatted_step,
            editing,
            valid,
        } = this.state;

        if (editing) {
            return (
                <div>
                    <div className="code-block">
                        <label>Step Config (Edit)</label>
                        <textarea 
                            onChange={async(e) => {
                                await this.editStep(e.target.value);
                            }}
                            value={step.toString()}
                        />
                        {!isUndefined(valid) && valid && <button className="button tertiary small validity">VALID</button>}
                        {!isUndefined(valid) && !valid && <button className="button error small validity">INVALID</button>}
                        <button className="secondary button small" onClick={() => this.updateStep()}>Update Step</button>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="code-block">
                    <label>Step Config</label>
                    <textarea 
                        onFocus={() => this.setState({ editing: true })}
                        value={formatted_step}
                    />
                    {!isUndefined(valid) && valid && <button className="button tertiary small validity">VALID</button>}
                    {!isUndefined(valid) && !valid && <button className="button error small validity">INVALID</button>}
                    <button style={{ right: '82px' }}className="secondary button small" onClick={() => navigator.clipboard.writeText(this.state.formatted_step)}>Copy to Clipboard</button>
                    <button className="secondary button small" onClick={() => this.setState({ editing: true })}>Edit Step</button>
                </div>
            </div>
        );
    }
}

export default CommonEdit;
/* eslint no-self-assign:0 */

import React from 'react';
import Table from '../../../../Layout/Table';

import moment from 'moment';
import { isBoolean, isString } from 'lodash';

class FormatTable extends Table {
    constructor(props) {
        super(props);
        this.deleteItems = this.deleteItems.bind(this);
    }

    deleteItems ([ key ]) {
        let { updateComponent, componentData, items = componentData.items } = this.props;
        const new_items = items.filter(t => key !== t.key);
        updateComponent({
            items: new_items,
        });
    }

    renderRow(data) {
        let {
            def,
            key,
            type,
            label,
            static: format_static,
        } = data;

        const { is_approved = false } = this.props;

        if (isBoolean(def)) {
            def = def.toString().toUpperCase();
        } else if (!isString(def)) {
            def = def.toString();
        } else if (moment(def).isValid()) {
            def = moment(def).format('DD/MM/YYYY');
        } else {}

        return (
            <tr key={key}>
                <td className="table-name">{ label }<br/><span>{ key }</span></td>
                <td className="table-type">{ type }</td>
                <td className="table-static">{format_static && <i className='fa fa-circle-check'></i>}</td>
                <td className="table-default">{ def }</td>
                <td style={{textAlign: 'right'}}>
                    {!is_approved &&
                        <button className="button small grey icon" onClick={() => {
                            this.deleteItems([ key ]);
                        }}>
                            <span className="cross"></span> Delete
                        </button>
                    }

                    <button className="button small secondary" onClick={() => {
                        this.setState({
                            highlightRow: key,
                        })
                        this.props.selectItem(data);
                    }}>
                        { is_approved ? 'View' : 'Edit' }
                    </button>
                </td>
            </tr>
        )
    }
}

export default FormatTable;
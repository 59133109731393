import { isUndefined } from 'lodash';
import React, { Component } from 'react';
import BooleanSelect from '../Forms/BooleanSelect';
import { parseSource } from '../../../../../utils/api_source';

class APISource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_source: false,
            source_type: null,
            source_example: null,
            source_valid: undefined,
        }
        this.updateSource = this.updateSource.bind(this);
        this.validateSource = this.validateSource.bind(this);
        this.updateStep = this.updateStep.bind(this);
    }

    UNSAFE_componentWillMount(){
        const { source = {} } = this.props;
        if (source.type && source.example) {
            this.setState({
                source_type: source.type,
                source_example: source.example,
                is_source: true,
            }, () => this.validateSource());
        }
    }

    validateSource() {
        const { source_example, source_type } = this.state;
        
        const example = parseSource({
            type: source_type,
            example: source_example,
        });

        if (example) {
            this.setState({
                source_example: JSON.stringify(example, null, 4),
                source_valid: true,
            });
        } else {
            this.setState({
                source_example,
                source_valid: false,
            });
        }
    }

    updateSource(data) {
        this.setState(data, () => {
            this.validateSource();
        });
    }

    updateStep () {
        const { source_example, source_type } = this.state;

        const example = parseSource({
            type: source_type,
            example: source_example,
        });

        if (example) {
            this.props.updateInputValue(example);

            this.props.updateStep({
                source: {
                    type: source_type,
                    example: source_example,
                },
            });
        }
    }   

    render() {
        const { 
            is_source,
            source_example,
            source_type,
            source_valid,
        } = this.state;

        return (
            <div className={`edit-inputs`}>
                <fieldset className={'spacing'}>
                    <label>Does the project have an API Source?</label>
                    <BooleanSelect 
                        value={is_source}
                        update={({ value }) => {
                            this.updateSource({
                                is_source: value,
                            })
                        }}
                    />
                </fieldset>

                {is_source &&
                
                    <div className="api-source">
                        <fieldset className={'spacing'}>
                            <label>What content type is the API?</label>
                            <select 
                                value={source_type} 
                                onChange={(e) => {
                                    this.updateSource({
                                        source_type: e.target.value,
                                    })
                                }}>
                                <option value={'json'}>JSON</option>
                                <option value={'xml'}>XML</option>
                                <option value={'csv'}>CSV</option>
                            </select>
                        </fieldset>
                        {source_type === 'json' &&
                            <div className="code-block">
                                <label>Example Request</label>
                                <textarea 
                                    onBlur={this.updateStep}
                                    onChange={((e) => {
                                        this.updateSource({
                                            source_example: e.target.value,
                                        })
                                    })}
                                    value={source_example}
                                />
                                {!isUndefined(source_valid) && source_valid && <button className="button tertiary small validity">VALID</button>}
                                {!isUndefined(source_valid) && !source_valid && <button className="button error small validity">INVALID</button>}
                                <button className="secondary button small" onClick={this.validateSource}>Validate JSON</button>
                            </div>
                        }
                    </div>

                }
                
            </div>
        );
    }
}

export default APISource;